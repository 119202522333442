/**
 * Paragraph - Block CTAs.
 */

// Load more button.
.btn.block-cta-load-more {
  max-width: 200px;
  margin: 0 auto 30px;
  justify-content: center;

  i {
    margin-left: 10px;
  }
}

// General layout & default theme styles.
.block-cta-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  // Default is 3 columns.
  .block-cta-wrapper {
    flex: 0 0 30%;
    width: 100%;
    margin: 0 15px 30px;

    @media (max-width: $screen-sm-max) {
      flex: 0 0 45%;
    }
    @media (max-width: $screen-xs-max) {
      flex: 0 0 100%;
      margin: 0 0 20px;
    }

    // Change default if only 1 block present.
    &.block-cta-wrapper--1 {
      flex: 0 0 100%;
      margin: 0 0 20px;
    }

    // Change default if only 2 blocks present.
    &.block-cta-wrapper--2 {
      flex: 0 0 45%;

      @media (max-width: $screen-xs-max) {
        flex: 0 0 100%;
        margin: 0 0 20px;
      }
    }
  }

  .block-cta {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 30px;
    border: 1px solid $gray-lighter;
    background-color: #fff;
    box-shadow: none;
    transition: box-shadow 0.2s ease-in-out, color 0.2s ease-in-out, border 0.2s ease-in-out;

    .block-cta__icon-wrapper {
      flex: 0 0 70px;
    }

    .block-cta__content {
      width: 100%;
    }

    &:not(.block-cta--no-link):hover,
    &:not(.block-cta--no-link):focus {
      border: 1px solid $acro-orange;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
    }
  }

  // Icon.
  .block-cta__icon {

    img {
      position: relative;
      top: -2px;
      max-width: 35px;
    }

    .block-cta__icon-background {
      position: relative;
      display: inline-block;
      height: 50px;
      width: 50px;
      border: 2px solid $acro-orange;
      border-radius: 50%;
      text-align: center;
      line-height: 46px;
      font-size: 30px;
      color: $acro-gray;
    }
  }

  // Title.
  .block-cta__title {
    margin: 0 0 10px 0;
    padding: 0;
    font: 500 20px/1.2em $font-family-sans-serif;
  }

  // Message.
  .block-cta__message {
    color: $text-color;
    font-size: 14px;

    p:last-of-type {
      margin-bottom: 10px;
    }
  }

  // Link.
  .block-cta__link-text {
    position: relative;
    padding-left: 18px;
    font-weight: 500;

    &:before {
      @extend %fa-icon;
      @extend .fas;
      content: fa-content($fa-var-chevron-right);
      position: absolute;
      top: 2px;
      left: 0;
    }
  }
}


// Alternate theme.
.block-cta-group--alternate {
  .block-cta {
    display: block;
    text-align: center;
  }

  // Icon.
  .block-cta__icon {
    margin-bottom: 20px;

    img {
      top: 0;
      max-width: 50px;
    }

    .block-cta__icon-background {
      height: 50px;
      width: 50px;
      border: none;
      border-radius: 0;
      line-height: 50px;
      font-size: 50px;
      color: $acro-orange;
    }
  }

  // Title.
  .block-cta__title {
    font: 400 22px/1.2em $font-family-sans-serif;
    color: $acro-gray-dark;
  }

  // Message.
  .block-cta__message {
    @media (min-width: $screen-md) {
      font-size: 16px;
    }
  }

  // Link.
  .block-cta__link-text {
    display: inline-block;
    padding-left: 0;

    &:before {
      display: none;
    }

    &:after {
      right: -5px;
      @extend %fa-icon;
      @extend .fas;
      content: fa-content($fa-var-chevron-right);
    }
  }
}


// Alternate 2 theme.
.block-cta-group--alternate-2 {

  // Layout.
  .block-cta-wrapper {
    flex: 0 0 46%;

    @media (max-width: $screen-sm-max) {
      flex: 0 0 100%;
    }
  }

  .block-cta {
    padding: 0;

    .block-cta__icon-wrapper {
      flex: 0 0 90px;
      height: 100%;
      padding: 30px;
      border: 2px solid #fff;
      background-color: $acro-gray-dark;
      text-align: center;

      @media (max-width: $screen-sm-max) {
        padding: 10px;
      }
    }

    .block-cta__content {
      padding: 30px;
    }
  }

  // Icon.
  .block-cta__icon {
    position: relative;
    margin-bottom: 0;

    img {
      top: -4px;
      max-width: 70px;

      @media (max-width: $screen-sm-max) {
        top: -2px;
        max-width: 35px;
      }
    }

    .block-cta__icon-background {
      height: 88px;
      width: 88px;
      line-height: 84px;
      font-size: 45px;
      color: #fff;

      @media (max-width: $screen-sm-max) {
        height: 50px;
        width: 50px;
        line-height: 46px;
        font-size: 25px;
      }
    }
  }

  // Link.
  .block-cta__link-text {
    padding-left: 0;

    &:before {
      display: none;
    }

    &:after {
      top: 1px;
      padding-left: 4px;
      @extend %fa-icon;
      @extend .fas;
      content: fa-content($fa-var-chevron-right);
    }
  }
}
