/*------------------------------
*	 Base Drupal Styles
------------------------------*/

html body.admin-menu { margin-top: 28px !important; }

html .form-item,
html .form-actions {
  margin: 26px 0;
  padding: 0;
}

div.messages {
  margin: 6px 0 20px 0;
  /* High left padding to make room for icon. */
  padding: 10px 10px 10px 42px;
}

.element-invisible { display: none; }

div.panels-display ul li { background: none !important; padding: 0 !important; }

p.search-info { display: none; }

.calendar-calendar .month-view .full td.multi-day div.monthview, .calendar-calendar .week-view .full td.multi-day div.weekview, .calendar-calendar .day-view .full td.multi-day div.dayview { background-color: #333; }
.calendar-calendar .month-view .full td.multi-day .inner .monthview .continues, .calendar-calendar .month-view .full td.multi-day .inner .monthview .cutoff, .calendar-calendar .week-view .full td.multi-day .inner .weekview .continues, .calendar-calendar .week-view .full td.multi-day .inner .weekview .cutoff { background-color: #333; }
.view .date-nav-wrapper .date-prev a, .view .date-nav-wrapper .date-next a { color: $gray; }
  .view .date-nav-wrapper .date-prev a:hover, .view .date-nav-wrapper .date-next a:hover { color: #09f; }
.view .date-nav-wrapper .date-heading { text-align: left; }

#site-map h2.title { display: none; }

#search-form .form-submit {
  margin: 0;
  display: inline-block;
  *display: inline;
  zoom: 1;
}
  #search-form {
    margin: 0 0 30px 0;
    width: 100%;
    display: block;
    float: left;
  }
      #search-form #edit-basic { width: 100%; display: block; float: left; }
      #search-form .form-type-textfield { float: left; margin: 0 10px 5px 0; }
      .search-advanced { display: block; float: left; }

/* -- Admin Tabs -- */
div.tabs {
  margin: 0;
  padding: 0;
}
  div.tabs ul.primary {
    border: 0;
    padding: 0;
    margin: 0;
  }
    div.tabs ul.primary li {
      display: block;
      float: left;
      margin: 0;
      padding: 0 0 10px 0;
    }
      div.tabs ul.primary li a {
        position: relative;
        margin: 0 5px 0px 0;
        padding: 6px 10px 4px 10px;
        display: block;
        float: left;
        border: 0;
        background: #e9e9e9;
        font-size: 0.9em;
        color: #999;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        behavior: url(/pie/PIE.htc);
      }
        div.tabs ul.primary li a:hover {
          color: #fff;
          background: $acro-orange;
          -webkit-transition: background 0.15s linear;
          -moz-transition: background 0.15s linear;
          -ms-transition: background 0.15s linear;
          -o-transition: background 0.15s linear;
          transition: background 0.15s linear;
        }
        div.tabs ul.primary li a.active {
          color: #fff;
          background: #000;
        }
