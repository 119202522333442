/**
 * Paragraph - Image strap.
 */

// General layout.
.image-strap-wrapper {
  position: relative;
  // Remove default bootstrap margin.
  margin-left: 0;
  margin-right: 0;

  .image-strap {
    display: block;

    @media (min-width: $screen-md) {
      display: flex;
    }
  }

  // Background image properties.
  .image-strap__image {
    min-height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;

    @media (min-width: $screen-sm) {
      min-height: 325px;
    }

    @media (min-width: $screen-md) {
      min-height: 400px;
    }
  }

  // Heading Message.
  .image-strap__image--heading-message {
    background-image: none !important;
    min-height: auto;
    text-align: center;
    background: $acro-salt;

    @media (min-width: $screen-md) {
      text-align: left;
    }

    &.image-strap__image--heading-message--dark {
      background-color: $acro-anchor;

      .image-strap__heading-message {
        color: $c-white !important;

        h1,
        h2,
        h3,
        h4,
        p {
          color: $c-white !important;
        }
      }
    }

    &.image-strap__image--left {
      text-align: center;

      @media (min-width: $screen-md) {
        text-align: right;
      }

      .image-strap__heading-message {
        margin: 0 0 0 auto;
      }
    }

    .image-strap__heading-message {
      margin: 0 auto 0 0;
      padding: 30px 0;
      max-width: none;
      color: $acro-diesel !important;

      @media (min-width: $screen-md) {
        padding: 30px 20px;
        max-width: 600px;
      }

      h1,
      h2,
      h3,
      h4,
      p {
        color: $acro-diesel !important;

        &:first-child {
          margin-top: 0;
          padding-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
    }
  }

  // Content area properties.
  .image-strap__content-wrapper {
    background-position: center;
    @include bg-color-options;
  }

  .image-strap__content-wrapper--left {
    text-align: right;
  }

  .image-strap__content {
    display: inline-block;
    width: 100%;
    padding: 40px 0;
    text-align: left;

    @media (min-width: $screen-md) {
      padding: 40px 30px;
    }

    @media (min-width: $screen-lg) {
      max-width: 700px;
    }
  }

  // 33/66 Layout.
  .image-strap__content-wrapper--33-66 .image-strap__content {
    @media (min-width: $screen-lg) {
      max-width: 910px;
    }
  }
}

// Dark theme.
.image-strap__content--dark {
  h1, h2, h4, h6 {
    color: $c-white;
  }

  p {
    color: $c-white;
  }

  ul, ol {
    color: $c-white;

    li:before {
      color: $acro-orange !important;
    }
  }

  blockquote {
    color: $c-white;
  }
}

// Unique class - Hero.
.page-section.hero {
  .image-strap__content {
    @media (min-width: $screen-md) {
      padding: 40px 30px;
    }
    @media (min-width: $screen-lg) {
      padding: 60px 0 80px 50px;
    }

    h1 {
      @media (min-width: $screen-md) {
        font-size: 46px;
      }
      @media (min-width: $screen-lg) {
        max-width: 600px;
        font-size: 65px;
      }
    }

    h2, h3, h4 {
      font-family: $font-family-sans-serif;
      font-weight: 300;
      text-shadow: 0.02em 0.02em 2px rgba(0, 0, 0, 0.5)
    }
  }
}
