/**
 * Slick Slider default theme overrides.
 */

// Layout (vertically center content).
.slick-track {
  display: flex;

  .slick-slide {
    display: flex;
    height: auto;

    img {
      display: inline-block;
    }
  }
}

// Previous/next buttons.
.slick-prev, .slick-next {
  text-indent: -999999px;
  height: 60px;
  width: 30px;
  z-index: 999;
  transition: all .2s ease-in-out;

  &.slick-disabled {
    opacity: 0.3;
  }
}

.slick-prev {
  left: 25px;
  background: url(../gfx/arrow-left_dark.svg) left center no-repeat;
  opacity: 0.3;

  &:hover,
  &:focus {
    left: 20px;
    background: url(../gfx/arrow-left_dark.svg) left center no-repeat;
    opacity: 0.8;
  }
}

.slick-next {
  right: 25px;
  background: url(../gfx/arrow-right_dark.svg) right center no-repeat;
  opacity: 0.3;

  &:hover,
  &:focus {
    right: 20px;
    background: url(../gfx/arrow-right_dark.svg) right center no-repeat;
    opacity: 0.8;
  }
}

// Nav dots.
.slick-dotted.slick-slider {
  margin-bottom: 0;
}

ul.slick-dots {
  bottom: 10px;
  margin: 0;

  li {
    background: none;

    button:before {
      color: $acro-gray-dark;
      font-size: 10px;
      transition: all .2s ease-in-out;
    }

    &.slick-active button:before {
      opacity: .8;
      color: $acro-orange;
    }
  }
}
