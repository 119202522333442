/**
 * Paragraph - Text sections.
 */

// Add a little extra padding below text paragraphs (Basic content and Text sections).
.paragraphs-item-basic-content,
.text-section {
  padding-bottom: 15px;
}

// Change bootstrap row to flex for medium screens and up.
@media screen and (min-width: $screen-md) {
  .text-section > .content > .row {
    display: flex;
  }
}

// Padding for when a background colour is selected.
.text-section-main-content-padding,
.text-section-secondary-content-padding {
  padding: 15px;

  // Include background colours.
  @include bg-color-options;
}

// Increase default padding between Bootstrap columns.
@media screen and (min-width: $screen-md) {
  // Main section with LEFT secondary section.
  .text-section-main--with-left {
    padding-left: 30px;

    &.text-section-main--with-vertical-line {
      border-left: 1px solid $acro-orange;
    }
  }

  .text-section-secondary--left {
    padding-right: 30px;
  }

  // Main section with RIGHT secondary section.
  .text-section-main--with-right {
    padding-right: 30px;

    &.text-section-main--with-vertical-line {
      border-right: 1px solid $acro-orange;
    }
  }

  .text-section-secondary--right {
    padding-left: 30px;
  }
}
