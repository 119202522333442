/*------------------------------
*	 Careers subsite.
------------------------------*/

// Remove active styling of specific menu items since they go to the homepage.
#pri-nav-cont ul.menu {
  > li#li-view-all-careers,
  > li#li-job-perks {
    a.active {
      color: $acro-gray;

      &:hover,
      &:focus {
        color: $acro-orange;
      }
    }
  }
}
#pri-nav-cont-mobile .menu-name-main-menu ul.menu {
  > li#li-view-all-careers,
  > li#li-job-perks {
    a.active {
      color: $acro-gray-dark;

      &:before {
        opacity: 0.25;
      }

      &:hover,
      &:focus {
        color: $acro-orange;

        &:before {
          opacity: 0.9;
        }
      }
    }
  }
}

// Application Forms.
.domain--careers-acromedia-com,
.domain--careers-acrocommerce-com {
  // Icons.
  div.form-item {
    position: relative;

    &:before {
      @extend %fa-icon;
      @extend .fab;
      position: absolute;
      top: 8px;
      left: 15px;
      font-size: 24px;
      content: '';
      color: $gray-light;
    }
  }

  div.form-item[class$="github-account"] {
    &:before {
      content: fa-content($fa-var-github);
    }
    input {  padding-left: 42px;  }
  }

  div.form-item[class$="drupalorg-profile"] {
    &:before {
      content: fa-content($fa-var-drupal);
    }
    input {  padding-left: 42px;  }
  }

  div.form-item[class$="bitbucket-account"] {
    &:before {
      content: fa-content($fa-var-bitbucket);
    }
    input {  padding-left: 42px;  }
  }

  div.form-item[class$="linkedin-profile"] {
    &:before {
      content: fa-content($fa-var-linkedin);
    }
    input {  padding-left: 42px;  }
  }

  div.form-item[class$="personal-website"] {
    &:before {
      @extend .far;
      content: fa-content($fa-var-globe);
    }
    input {  padding-left: 42px;  }
  }
}

// BambooHR job listings.
#BambooHR {
  font-family: $font-family-sans-serif;
  color: $acro-gray;

  // Hide the BambooHR logo.
  #BambooHR-ATS + div {
    display: none;
  }

  .BambooHR-ATS-board {
    > h2 {
      display: none;
    }

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      background: none;
      margin: 0;
      padding: 0;
    }

    // Job posting links.
    a {
      position: relative;
      display: block;
      padding: 18px 0 18px 35px;
      font-weight: 300;
      line-height: 1.2;
      color: $acro-gray;

      @media (min-width: $screen-sm) {
        padding: 22px 0 27px 45px;
        font-size: 20px !important;
      }

      &:before {
        @extend .fal;
        content: fa-content($fa-var-file-user);
        position: absolute;
        top: 14px;
        left: 1px;
        font-size: 28px;
        color: $acro-orange;

        @media (min-width: $screen-sm) {
          top: 19px;
          font-size: 32px;
        }
      }

      &:hover, &:focus {
        color: $link-color;
        text-decoration: none;
        outline: none;
      }

      &:focus {
        outline: -webkit-focus-ring-color auto 5px;
      }
    }
  }

  .BambooHR-ATS-Department-Item {
    margin-bottom: 45px !important;
  }

  .BambooHR-ATS-Department-Header {
    margin-bottom: 12px;
    color: $acro-orange;
    font-size: 18px;
  }

  .BambooHR-ATS-Jobs-List {
    padding: 0 !important;
    margin-bottom: 30px !important;
    border: none !important;
  }

  .BambooHR-ATS-Jobs-Item {
    border-bottom: 1px solid $acro-puff;

    .BambooHR-ATS-Location {
      display: none;
    }
  }
}
