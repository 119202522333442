/**
 * Page Paragraph - unique page classes applied to Page Paragraph content type.
 */

// Update header when 'use minimal header' boolean is checked.
.page-use-minimal-nav {

  // Remove primary nav except for contact.
  #pri-nav-cont li,
  #mobile-nav-toggle {
    display: none !important;
    visibility: hidden !important;
  }
  // ...and never hide contact.
  #pri-nav-cont li#li-contact {
    display: inline-block !important;
    visibility: visible !important;
  }

  // Adjust any image beside Acro logo when header is narrow.
  .site-header-wrapper--narrow {
    .region-header-beside-logo img {
      max-height: 25px;
    }
  }
  @media (max-width: $screen-sm-max) {
    .region-header-beside-logo img {
      max-height: 25px;
    }
  }
}

// Update base font size (testing a larger base font).
.font-size-17 {
  font-size: 17px;

  // Adjust ul before elements cause the font size change messes with them.
  .content .field-type-text-with-summary .field-item > ul > li:before,
  .content .content-tabs__pane > ul > li:before {
    top: 3px;
  }

  ul.ul--checkmark-circle > li:before {
    top: -2px !important;
  }
}
