/**
 * Page - Resources.
 *
 * Note: Resources view styling in views/resources-view.scss.
 * Note: Resources facet block styling in blocks/resources-facets.scss.
 */

// Layout.
.page-resources {

  // Page title.
  h1#page-title {
    float: left;
    display: inline-block;
    margin: 0;
    color: $acro-gray-dark;

    @media (max-width: 1600px) {
      margin: 10px 0 0;
      font-size: 45px;
    }

    @media (max-width: $screen-xs-max) {
      width: 100%;
      float: none;
      margin: 0 0 20px;
    }
  }

  // Modify default text section paragraph styles.
  .text-section {
    padding-bottom: 0;

    > .content > .row {
      display: flex;

      @media (max-width: $screen-sm-max) {
        display: block;
      }
    }

    // Side column.
    .text-section-secondary {
      padding: 75px 5%;

      @media (max-width: $screen-sm-max) {
        display: none; // Reveal with jQuery in page-resources.js.
        padding: 0 15px;
      }
      @media (min-width: $screen-md) {
        display: block !important; // Always display for larger screens.
      }

      // Facet lists.
      @media screen and (min-width: $screen-sm) and (max-width: $screen-sm-max) {
        .field-items {
          display: flex;

          .field-item {
            flex: 0 0 22%;
            margin-right: 2%;
          }
        }
      }
      @media screen and (min-width: $screen-xs) and (max-width: $screen-xs-max) {
        .field-items {
          display: flex;
          flex-wrap: wrap;

          .field-item {
            flex: 0 0 48%;
            margin-right: 2%;
          }
        }
      }
    }

    // Main content.
    .text-section-main {
      padding: 20px 5% 40px;
      background-color: $gray-lightest;

      @media (max-width: $screen-xs-max) {
        padding: 20px 15px;
      }
    }
  }

  // Mobile filter toggle button.
  @media (min-width: $screen-md) {
    .button-ctas {
      display: none;
    }
  }
}
