/**
 * General menu styles.
 *
 * NOTE: Some specific menu styling can also be found in header.scss and footer.scss.
 */

/* -- Secondary Nav -- */

// Hide initially for the page-paragraph template only.
.node-type-page-paragraphs .secondary-nav-content {
  display: none;
  padding: 40px 15px;

  .region-secondary-navigation ul.menu {
    padding: 0;
  }
}

#sec-nav-cont {
  display: block;
  @media (max-width: $screen-md-max) {
    display: none;
  }
}

.region-secondary-navigation ul.menu {
  margin: 0;
  padding: 20px 6px 0 6px;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
  list-style: none;
  font: 600 24px/28px $font-family-sans-serif;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: $screen-md-max) {
    text-align: left;
    border: none;
    margin-bottom: 2px;
    padding: 6px 0 0;
  }

  li {
    position: relative;
    bottom: -2px;
    margin: 0 -2px;
    padding: 0;
    display: inline-block;
    background: none;

    @media (max-width: $screen-md-max) {
      display: block;
      padding-bottom: 2px;
      float: left;
      width: 48%;

      &:nth-child(odd) {
        float: left;
      }

      &:nth-child(even) {
        float: right;
      }

      @media (max-width: 700px) {
        width: 100%;
        float: left !important;
      }

    }

    a {
      color: $gray-dark;
      display: inline-block;
      margin: 0 15px;
      padding: 10px 0;
      font-size: 15px;
      line-height: 15px;
      border-bottom: 1px solid #e5e5e5;
      text-decoration: none !important;
      transition: border-color .3s ease-in-out;

      @media (max-width: $screen-md-max) {
        width: 100%;
        min-width: 230px;
        margin-left: 0;
        margin-bottom: 1px;
        padding-left: 11px;
      }

      &.active-trail {
        color: $acro-orange;
        border-bottom: 2px solid $acro-orange;

        @media (max-width: $screen-md-max) {
          color: #999;
          margin-bottom: 1px;
          border-bottom: 1px solid #e5e5e5;
        }
      }

      &:hover {
        border-bottom: 2px solid $acro-orange;

        @media (max-width: $screen-md-max) {
          margin-bottom: 0;
        }
      }

      &:focus {
        border-bottom: 2px solid $acro-orange;
        color: $acro-orange;

        @media (max-width: $screen-md-max) {
          margin-bottom: 0;
        }
      }
    }

  }
}

// -- Mobile Sub Nav -- //
#subnav-toggle-div {
  border-bottom: 1px solid #e5e5e5;
  height: 32px;
}

#subnav-title-div {
  display: table;
  padding: 7px 10px 3px 0;
  border-radius: 5px 5px 0 0;
  background: transparent;
}

#mobile-subnav-toggle-icon {
  padding: 0 19px 7px 10px;
  background: url(../gfx/mobile-subnav-menu-toggle.png) 7px 2px no-repeat;
  cursor: pointer;
}

#mobile-subnav-toggle {
  padding: 0 0 4px 0;
  font: 600 16px/20px $font-family-sans-serif;
  text-transform: uppercase;
  border-bottom: 2px solid $acro-orange;
  cursor: pointer;
}

#subnav-toggle-div.active {
  border-bottom: 2px solid $acro-orange;

  div#subnav-title-div {
    background-color: $acro-orange;
  }

  #mobile-subnav-toggle-icon {
    background-image: url(../gfx/mobile-subnav-menu-toggle-active.png);
  }
  #mobile-subnav-toggle {
    color: white;
    border-bottom: none;
  }
}
