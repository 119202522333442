/**
 * HubSpot embedded elements (forms, cookie alert popup, etc.)
 */

/**
 * Form - Default styling.
 */

.hbspt-form {
  pointer-events: all;

  form {
    max-width: 800px;
    margin: 0 auto;
  }

  .legal-consent-container {
    font-size: 13px;

    p {
      margin-bottom: 10px;
    }
  }

  // Text links.
  a {
    color: $commerce-blue;

    &:hover,
    &:focus {
      color: $commerce-blue-dark;
    }
  }

  // Lists.
  ul {
    margin: 0;
    padding-bottom: 0;

    li {
      padding: 3px 0 0;
      background: none;

      span {
        vertical-align: middle;
        font-weight: normal;
      }

      .hs-input {
        min-height: auto;
        width: auto !important;
        vertical-align: middle;
      }
    }
  }

  // Labels.
  label {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 6px;
    font-weight: bold;
    line-height: 1.2em;
  }

  // Required marker.
  // NOTE: Hidden by default. Shown with jQuery under certain conditions. See script-general.js
  .hs-form-required {
    display: none;
    padding-left: 5px;
    color: $brand-danger;
  }

  // Descriptions.
  legend {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 6px;
    font-size: 13px;
    line-height: 1.2em;
  }

  // Fieldsets.
  fieldset {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 10px;
    color: $acro-gray;
  }

  // Ensure dependent fields are visible.
  // This fixes bug when they disappear if initially in a hidden container.
  .hs-dependent-field {
    display: block !important;
  }

  // Padding below each form field.
  .hs-form-field {
    margin-bottom: 20px;
  }

  // Input elements - All.
  .hs-input {
    width: 100% !important;
    max-width: 100% !important;
    min-height: 40px;
    padding: 0 10px;
    line-height: 38px;
    background: #fff;
    border: 1px solid $gray-light;
    text-decoration: none;
    font-weight: 300;
    color: $acro-gray;
    box-sizing: border-box;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #bbb;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      opacity: 1;
      color: #bbb;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: #bbb;
    }
    &:-moz-placeholder { /* Firefox 18- */
      opacity: 1;
      color: #bbb;
    }

    &.error {
      border: 1px solid $brand-danger;
    }

    &:focus {
      border-color: $commerce-blue;
      outline: 0;
      box-shadow: 0 0 6px rgba($commerce-blue, 0.2);
    }
  }

  // Input element - Radio/Checkbox.
  .hs-input[type='radio'] + span,
  .hs-input[type='checkbox'] + span {
    padding-left: 6px;
  }

  // Input element - Text area.
  textarea.hs-input {
    min-height: 100px;
    padding: 10px;
    line-height: 1.2em !important;
  }

  // Input element - Select.
  select.hs-input {
    padding-left: 5px;
    padding-right: 0;

    // Firefox only.
    @-moz-document url-prefix() {
      padding-top: 7px;
      padding-bottom: 8px;
    }
  }

  // Submit button.
  .hs-button {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 10px;
    text-align: center;
    font-size: 18px;
    line-height: 20px;
    background: $commerce-blue;
    color: #fff;
    border: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all 0.15s linear;

    &:focus,
    &:hover {
      background-color: $commerce-blue-dark;
      outline: none;
    }
  }

  // Errors.
  .hs-error-msgs {
    padding-bottom: 0;
    color: $brand-danger;
    font-size: 12px;

    li {
      line-height: 14px;

      label {
        font-weight: normal;
      }
    }
  }

  // Hide error rollup. Errors will still show for individual fields.
  .hs_error_rollup {
    display: none;
  }
}


/**
 * Form - Overrides for the blog subscribe form.
 */

.hbspt-form form[data-form-id='1088fd77-b531-410f-b325-d73b2d64bbb7'] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  @media (min-width: $screen-lg) {
    max-width: 100%;
    justify-content: center;
  }

  // Field layout.
  > div[data-reactid^='.hbspt-forms-']:nth-of-type(1),
  > div[data-reactid^='.hbspt-forms-']:nth-of-type(2),
  > div[data-reactid^='.hbspt-forms-']:nth-of-type(3),
  .hs-submit {
    flex: 0 0 23%;
    height: 100%;
    margin: 0 10px;
  }

  @media (max-width: $screen-md-max) {
    > div[data-reactid^='.hbspt-forms-']:nth-of-type(3),
    .hs-submit {
      flex: 0 0 96%;
    }


    > div[data-reactid^='.hbspt-forms-']:nth-of-type(1),
    > div[data-reactid^='.hbspt-forms-']:nth-of-type(2) {
      flex: 0 0 46%;
    }
  }

  @media (max-width: 600px) {
    > div[data-reactid^='.hbspt-forms-']:nth-of-type(1),
    > div[data-reactid^='.hbspt-forms-']:nth-of-type(2),
    > div[data-reactid^='.hbspt-forms-']:nth-of-type(3),
    .hs-submit {
      flex: 0 0 100%;
    }
  }

  // Labels.
  label {
    font-size: 14px;
    font-weight: normal;
  }

  // Input elements.
  .hs-input {
    @media (min-width: $screen-lg) {
      min-height: 46px;
      line-height: 46px;
      font-size: 18px;
    }
  }

  // Submit button.
  .hs-submit {
    margin-top: 12px;

    @media (max-width: $screen-md-max) {
      margin-top: 0;
      text-align: center;
    }
  }

  .hs-button {
    @media (min-width: $screen-lg) {
      min-height: 48px;
      width: 100%;
      padding: 0 20px;
      line-height: 48px;
      font-size: 18px;
    }
  }

  .legal-consent-container {
    // Revealed via jQuery in script-general.js.
    display: none;
    width: 100%;
    padding-top: 15px;
    text-align: center;
  }
}


/**
 * Form - Default theme style overrides for DARK backgrounds when embedded into paragraph components.
 */

.banner__content,
.strap-cta--dark,
.image-strap__content--dark,
.page-section--dark-theme {
  .hbspt-form {
    label,
    fieldset {
      color: #fff;
    }

    legend {
      color: $acro-orange;
    }
  }
}

/**
 * Form - Default theme style overrides for when embedded into text section secondary paragraph components.
 */
.text-section-secondary {
  .text-section-secondary-content-padding {
    .hbspt-form {
      margin: -15px -15px 20px;
    }
  }

  .hbspt-form {
    background-color: $gray-lightest;
    padding: 15px;
    margin-bottom: 20px;

    form > div:first-of-type > .hs-richtext {
      position: relative;
      background-color: $acro-blue;
      color: #fff;
      padding: 15px 15px 10px;
      margin: -15px -15px 40px;

      &:after {
        position: absolute;
        top: 100%;
        left: calc(50% - 20px);
        height: 0;
        width: 0;
        border: 20px solid rgba(0,0,0,0);
        border-top-color: $hubsport-form-header;
        content: " ";
      }

      p,
      h2,.h2,
      h3,.h3,
      h4,.h4,
      h5,.h5,
      h6,.h6 {
        color: #fff !important;
      }
    }

    input[type='submit'] {
      width: 100%;
      padding: 15px 20px;
      font-weight: 500;
    }
  }
}

/**
 * Cookie alert popup.
 */

div#hs-eu-cookie-confirmation {
  border-top: 1px solid #ccc !important;
  border-bottom: none !important;

  div#hs-eu-cookie-confirmation-inner {
    text-align: center;
    padding: 10px 10px 20px !important;

    p {
      display: inline-block;
      color: #55515c !important;
      text-align: center !important;

      a {
        color: #f95e27 !important;

        &:hover,
        &:focus {
          color: #f95e27 !important;
          text-decoration: underline !important;
        }
      }
    }

    div#hs-en-cookie-confirmation-buttons-area {
      display: inline-block;
      padding-left: 10px;
    }
  }
}
