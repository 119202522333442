/**
 * Paragraph - Tabs.
 *
 * Note:
 * A plugin is used which converts the tabs to accordion for tablet and mobile.
 * The tabs and accordion are styled separately below.
 */

// Default theme - Tabs.
div.tabs {
  margin-bottom: 40px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.nav-tabs {
  position: relative;
  margin: 0;
  padding: 0;
  background: $gray-lightest;
  border: 1px solid $gray-lighter;
  border-bottom: none;

  @media screen and (min-width: $screen-md) {
    display: block !important;
  }

  // Bottom bar.
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: $gray-lighter;
  }

  // Clearfix.
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.nav-tab {
  float: left;
  margin: 0;
  padding: 0;
  background: none;

  > a {
    position: relative;
    display: block;
    margin: -1px 0 0;
    padding: 12px 18px 19px 18px;
    color: $acro-gray-dark;
    font-size: 14px;
    background: $gray-lightest;
    border: 1px solid $gray-lighter;
    border-bottom: 0;
    border-left: none;

    // Bottom bar.
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 5px;
      background: $gray-lighter;
    }

    // Icon.
    i {
      display: inline-block;
      position: relative;
      top: -1px;
      padding-right: 4px;
      font-size: 1em;
      transition: color 0.15s ease-in-out;
    }

    &:hover,
    &:focus {
      background: #fff;

      i {
        color: $acro-orange;
      }
    }
  }

  // Active state.
  &.active {
    > a {
      background: #fff;

      // Bottom bar.
      &:before {
        background: #fff;
      }

      // Icon.
      i {
        color: $acro-orange;
      }
    }
  }
}

.tab-content {
  margin: 0 0 20px 0;
  border: 1px solid $gray-lighter;
  border-top: none;
  background: #fff;
}

.tab-pane {
  padding: 25px 20px 25px 20px;
}

// Default theme - Accordion.
.tabs {
  .panel {
    border: 1px solid $gray-lighter;
    border-radius: 0;

    + .panel {
      margin-top: -1px;
    }
  }

  .panel-heading {
    padding: 0;
    background: none;

    .panel-title {
      font-family: $font-family-sans-serif;

      a {
        display: block;
        padding: 12px 18px;
        background-color: #fff;
        border-bottom: none;
        color: $acro-gray-dark;

        &:before {
          @extend %fa-icon;
          @extend .far;
          content: fa-content($fa-var-angle-right);
          padding-right: 12px;
          padding-left: 3px;
        }

        &.collapsed {
          background-color: $gray-lightest;
          border-bottom: 4px solid $gray-lighter;
          color: $acro-gray-dark;

          &:before {
            content: fa-content($fa-var-angle-down);
            padding-right: 10px;
            padding-left: 0;
          }
        }

        &:hover,
        &:focus {
          color: $acro-gray-dark;
          outline-color: $acro-orange;
        }
      }
    }
  }

  .panel-body {
    padding: 25px 20px 25px 20px;
    border-top: 1px solid $gray-lighter !important;
  }
}


// Alternate theme - Tabs.
.tabs.tabs--alternate {
  margin-bottom: 10px;
  box-shadow: none;

  .nav-tabs {
    background: transparent;
    border: none;
    text-align: center;

    &:before {
      height: 1px;
    }
  }

  .nav-tab {
    float: none;
    display: inline-block;

    // Bullet divider.
    &:after {
      content: '•';
      padding: 0 15px;
      display: inline-block;
      color: $acro-gray;
      font-size: 20px;
    }

    &:last-of-type:after {
      display: none;
    }

    > a {
      display: inline-block;
      padding: 12px 5px 25px;
      background: transparent;
      border: none;
      font-size: 22px;
      font-weight: 300;

      &:before {
        bottom: -3px;
        height: 4px;
        background: transparent;
      }

      &:hover,
      &:focus {
        color: $acro-orange;
      }
    }

    // Active state.
    &.active {
      > a {
        color: $acro-orange;
        background: transparent;

        // Bottom bar.
        &:before {
          background: $acro-orange;
        }
      }
    }
  }

  .tab-content {
    border: none;
    background: none;
  }

  .tab-pane {
    padding: 30px 10px 25px;
  }

  // Alternate theme - Accordion.
  .panel {
    border: none;
    box-shadow: none;
    background-color: transparent;

    + .panel {
      margin-top: 0;
    }
  }

  .panel-heading {
    .panel-title {
      a {
        padding: 20px 5px;
        border-bottom: 4px solid $acro-orange;
        background: none;
        text-align: center;
        font-size: 22px;
        font-weight: 300;
        color: $acro-orange;

        &.collapsed {
          border-bottom: 1px solid $gray-lighter;
          background: none;
          color: $acro-gray-dark;
        }

        &:hover,
        &:focus {
          color: $acro-orange;
        }
      }
    }
  }

  .panel-body {
    border-bottom: 1px solid $gray-lighter;

    @media (max-width:$screen-xs-max) {
      padding: 25px 0;
    }
  }
}


// Alternate 2 theme - Tabs.
.tabs.tabs--alternate-2 {
  padding: 20px 50px;
  box-shadow: none;
  border: 1px solid $acro-orange;
  border-top-width: 5px;
  background: #fff;

  @media (max-width:$screen-xs-max) {
    padding: 20px;
  }

  .nav-tabs {
    background: transparent;
    border: none;

    &:before {
      display: none;
    }
  }

  .nav-tab {
    float: none;
    display: inline-block;

    // Bullet divider.
    &:after {
      position: relative;
      top: 6px;
      content: '•';
      padding: 0 15px;
      display: inline-block;
      color: $acro-orange;
      font-size: 40px;
    }

    &:last-of-type:after {
      display: none;
    }

    > a {
      display: inline-block;
      padding: 15px 5px;
      background: transparent;
      border: none;
      font-family: $font-family-serif;
      font-size: 26px;
      font-weight: 400;
      color: #999;

      &:before {
        height: 4px;
        background: transparent;
      }

      &:hover,
      &:focus {
        color: $acro-gray-dark;
      }
    }

    // Active state.
    &.active {
      > a {
        color: $acro-gray-dark;
        background: transparent;

        // Bottom bar.
        &:before {
          background: $acro-orange;
        }
      }
    }
  }

  .tab-content {
    border: none;
    background: none;
  }

  .tab-pane {
    padding: 30px 0 0;
  }

  // Alternate 2 theme - Accordion.
  .panel {
    border: none;
    box-shadow: none;
    background-color: transparent;

    + .panel {
      margin-top: 0;
    }
  }

  .panel-heading {
    .panel-title {
      a {
        padding: 20px 5px;
        border-bottom: 4px solid $acro-orange;
        background: none;
        font-family: $font-family-serif;
        font-size: 22px;
        font-weight: 500;
        color: $acro-gray-dark;

        &.collapsed {
          border-bottom: 1px solid $gray-lighter;
          background: none;
          color: #999;
        }

        &:hover,
        &:focus {
          color: $acro-gray-dark;
        }
      }
    }
  }

  .panel-body {
    @media (max-width:$screen-xs-max) {
      padding: 25px 0;
    }
  }
}

// Alternate 3 theme - Tabs.
.tabs.tabs--alternate-3 {
  margin-bottom: 10px;
  box-shadow: none;

  .nav-tabs {
    background: transparent;
    border: none;
    text-align: center;

    &:before {
      height: 1px;
    }
  }

  .nav-tab {
    float: none;
    display: inline-block;

    > a {
      display: inline-block;
      padding: 18px 35px;
      margin: 0 3px;
      background: #fff;
      border: 1px solid $gray-lighter;
      border-radius: 10px 10px 0 0;
      color: $acro-orange;
      font-size: 20px;
      font-weight: 400;

      @media (max-width:$screen-md-max) {
        padding: 18px 20px;
      }

      &:before {
        bottom: -1px;
        height: 2px;
        background: transparent;
      }

      &:hover,
      &:focus {
        color: $acro-gray-dark;
      }
    }

    // Active state.
    &.active {
      > a {
        color: $acro-gray-dark;
        background: #fff;

        // Bottom bar.
        &:before {
          background: #fff;
        }
      }
    }
  }

  .tab-content {}

  .tab-pane {
    padding: 30px 25px 15px;
  }

  // Alternate theme - Accordion.
  .panel {
    margin-bottom: 15px;
    border-radius: 10px;
    box-shadow: none;

    + .panel {
      margin-top: 0;
    }
  }

  .panel-heading {
    border-radius: 10px;

    .panel-title {
      a {
        padding: 20px 5px;
        border-radius: 10px;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        color: $acro-orange;

        &.collapsed {
          border-bottom: none;
          background: #fff;
          color: $acro-gray-dark;
        }

        &:hover,
        &:focus {
          color: $acro-orange;
        }
      }
    }
  }

  .panel-body {
    border-bottom: none;
  }
}

// Alternate 4 theme - Tabs.
.tabs.tabs--alternate-4 {
  margin-bottom: 0;
  box-shadow: none;

  .nav-tabs {
    max-width: 1400px;
    margin: 22px auto 5px;
    background: transparent;
    border: none;
    text-align: center;

    @media (min-width: $screen-md) {
      display: flex !important;
      justify-content: space-evenly;
    }

    &:before,
    &:after {
      display: none;
    }
  }

  .nav-tab {
    float: none;
    display: inline-block;

    > a {
      display: inline-block;
      padding: 12px 5px 25px;
      background: transparent;
      border: none;
      font-size: 18px;
      color: $acro-anchor;

      @media (min-width: $screen-lg) {
        font-size: 22px;
      }

      &:before {
        bottom: 0;
        height: 0;
      }

      i {
        color: $acro-smoke;
      }

      &:hover,
      &:focus {
        color: $acro-diesel;

        i {
          color: $acro-orange;
        }
      }
    }

    // Active state.
    &.active {
      > a {
        color: $acro-orange;
        background: transparent;

        i {
          color: $acro-anchor;
        }
      }
    }
  }

  .tab-content {
    border: none;
    background: none;
    margin: 0;
  }

  .tab-pane {
    padding: 0 20px;

    .image-strap-wrapper {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  // Alternate 4 theme - Accordion.
  .panel {
    border: none;
    box-shadow: none;
    background-color: transparent;

    + .panel {
      margin-top: 0;
    }
  }

  .panel-heading {
    .panel-title {
      a {
        padding: 20px 5px;
        background: none;
        text-align: center;
        font-size: 22px;
        color: $acro-orange;

        &::before {
          display: none;
        }

        i {
          color: $acro-anchor;
        }

        &.collapsed {
          border-bottom: 1px solid $gray-lighter;
          background: none;
          color: $acro-anchor;

          i {
            color: $acro-smoke;
          }
        }

        &:hover,
        &:focus {
          color: $acro-orange;

          i {
            color: $acro-anchor;
          }
        }
      }
    }
  }

  .panel-body {
    border-bottom: 1px solid $gray-lighter;
    padding: 25px 0 0;

    @media (max-width:$screen-xs-max) {

    }

    .image-strap-wrapper {
      margin-top: -25px;
    }
  }
}
