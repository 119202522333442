/**
 * Unique components.
 *
 * This is for unique, one-off, on-the-fly components that don't fit within the normal structure.
 *
 * See also js/script-unique-components.scss
 */

// Fade section background image at mobile.
.mobile--fade-bg {
  position: relative;

  > .mobile--fade-bg-overlay {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: .9;
    // Background color added via jQuery in script-unique-component.js.

    @media (min-width: $screen-md) {
      display: none;
    }
  }

  > .content {
    position: relative;
    z-index: 2;
  }
}

// Swap page section to dark theme at mobile.
.mobile--swap-to-dark {
  &.bg-color--diesel--important {
    background-color: $acro-diesel !important;
  }

  > .mobile--fade-bg-overlay {
    &.bg-color--diesel--important {
      background-color: $acro-diesel !important;
    }
  }
}

// Item grid.
.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .grid-item {
    margin-bottom: 20px;
    flex: 0 0 100%;
  }

  // 2 column.
  &.grid--2-col {
    .grid-item {
      @media (min-width: $screen-xs) {
        flex: 0 0 45%;
      }
    }
  }

  // 3 column.
  &.grid--3-col {
    .grid-item {
      @media (min-width: $screen-xs) {
        flex: 0 0 45%;
      }
      @media (min-width: $screen-md) {
        flex: 0 0 30%;
      }
    }
  }

  // 4 column.
  &.grid--4-col {
    .grid-item {
      @media (min-width: $screen-xs) {
        flex: 0 0 45%;
      }
      @media (min-width: $screen-lg) {
        flex: 0 0 22%;
      }
    }
  }
}

// Page section is the last component being sticky to the bottom.
.page-section.page-section--with-baseline {
  overflow: hidden;

  h1 {
    text-shadow: .02em .02em 2px rgba(0,0,0, .5);
  }

  .page-section__content-wrapper > .paragraphs-items > .field > .field-items > .field-item:last-of-type {
    position: relative;
    background-color: rgba(#0678be, .5);
    color: #fff;

    &:before,
    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 9000px;
      background-color: rgba(#0678be, .5);
      content: '';
    }
    &:before {
      right: 100%;
    }
    &:after {
      left: 100%;
    }

    > .paragraphs-item-basic-content,
    > .text-section {
      padding-bottom: 10px;
    }

    .grid {
      padding-top: 28px;
      text-align: center;
      text-transform: uppercase;

      @media (min-width: 1350px) {
        font-size: 1.2em;
      }

      // 3 column overrides.
      &.grid--3-col {
        .grid-item {
          @media (max-width: $screen-md) {
            flex: 0 0 100%;
          }
          @media (min-width: $screen-lg) {
            flex: 1 1 auto;

            &:first-of-type {
              text-align: left;
            }
            &:last-of-type {
              text-align: right;
            }
          }
        }
      }
    }
  }
}

// Rocket Strap CTA.
.rocket-strap-cta {
  display: none; // Reveal w/ A/B Test module.
  position: relative;
  min-height: 225px;
  background: $commerce-blue;
  background: url(../gfx/rocket_cta_strap/grid-10x10-tile.png) center repeat;
  background: url(../gfx/rocket_cta_strap/grid-10x10-tile.png) center repeat, -moz-linear-gradient(left, $acro-green 0%, $commerce-blue 50%, $acro-green 100%);
  background: url(../gfx/rocket_cta_strap/grid-10x10-tile.png) center repeat, -webkit-linear-gradient(left, $acro-green 0%, $commerce-blue 50%, $acro-green 100%);
  background: url(../gfx/rocket_cta_strap/grid-10x10-tile.png) center repeat, linear-gradient(to right, $acro-green 0%, $commerce-blue 50%, $acro-green 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#28ae6d', endColorstr='#28ae6d',GradientType=1 );

  // Additional backgrounds changed via. jQuery.
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
  }

  @media (min-width: $screen-sm) {
    min-height: 250px;

    &.rocket-strap-cta--bg-1:before {
      background: url(../gfx/rocket_cta_strap/rocket-graph-cta-bg-1.png) center bottom no-repeat;
    }
    &.rocket-strap-cta--bg-2:before {
      background: url(../gfx/rocket_cta_strap/rocket-graph-cta-bg-2.png) center bottom no-repeat;
    }
  }

  // Standalone versions with no initial questions.
  &.rocket-strap-cta--standalone {
    display: block;

     &:before {
       display: none;
     }
  }


  h1, h2, h3, h4, h5, h6 {
    font-family: $font-family-sans-serif;
    font-weight: 300;
    color: #fff;
    text-shadow: 0.02em 0.02em 2px rgba(0, 0, 0, 0.5);
  }

  h3 {
    margin-left: auto;
    margin-right: auto;
    font-size: 28px;

    @media (min-width: $screen-sm) {
      max-width: 590px;
      font-size: 30px;
    }
  }

  p {
    text-shadow: 0.02em 0.02em 2px rgba(0, 0, 0, 0.5);
  }

  .paragraphs-item-basic-content {
    padding-bottom: 0;
    font-size: 20px;
    line-height: 1.2;

    // List.
    ul {
      display: flex;
      flex-wrap: wrap;
      padding-top: 10px;
      padding-bottom: 0;

      > li {
        flex: 1 0 100%;
        padding: 0 0 25px 45px;
        font-weight: 500;
        text-shadow: 0.02em 0.02em 2px rgba(0, 0, 0, 0.5);

        &:before {
          top: -3px !important;
          @extend %fa-icon;
          @extend .fas;
          content: fa-content($fa-var-chevron-circle-right);
          color: #fff !important;
          font-size: 30px !important;
        }
      }
    }

    @media (min-width: $screen-sm) {
      font-size: 21px;

      ul {
        > li {
          flex: 1 0 50%;
          padding: 0 20px 25px 55px;

          &:before {
            top: 0 !important;
            font-size: 40px !important;
          }
        }
      }
    }
  }

  // Hide sections. Revealed with jQuery.
  .page-section__content-wrapper > .paragraphs-items-field-paragraph-reference > .field > .field-items {
    > .field-item {
      display: none;
      max-width: 990px;
      margin-left: auto;
      margin-right: auto;

      &:first-of-type {
        display: block;
      }
    }
  }

  // Buttons.
  .rocket-cta__buttons {
    margin-bottom: 0;
  }

  .button-cta-link.btn.btn--rocket-cta {
    display: inline-block;
    background-color: $acro-green;
    border: 2px solid #fff;
    font-weight: 500;
    text-shadow: none;

    &:hover, &:focus {
      color: #fff;
      background-color: $acro-gray-dark;
      border: 2px solid #fff;
    }

    &.btn--rocket-cta--next {
      &:after {
        padding-left: 12px;
        @extend %fa-icon;
        @extend .far;
        content: fa-content($fa-var-angle-right);
      }
    }

    @media (min-width: $screen-sm) {
      font-size: 20px;

      &.btn--rocket-cta--next {
        padding: 12px 50px;
      }
    }
  }
}
