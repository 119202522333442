/**
 * Ideal Architecture Analysis page.
 *
 * This is a unique page with interactive forms.
 */

.ideal-architecture-analysis {
  // Banner.
  .page-section--banner {
    margin-bottom: 0;
    background-position-y: bottom;

    .title-wrapper {
      h1, h2, h3 {
        // Multiple text shadow layers to make it more pronounced.
        text-shadow: 0 0 10px #FFFFFF, 0 0 15px #FFFFFF, 0 0 15px #FFFFFF;
      }

      @media (min-width: $screen-sm) {
        margin-top: 55px;
      }
    }
  }

  // Banner - Instructions section.
  .instructions {
    position: relative;
    margin-top: 30px;
    background: url(/sites/default/files/ideal-arch-background-2.jpg) repeat center center;
    text-align: center;
    color: #fff;

    @media (min-width: $screen-sm) {
      margin-top: 50px;
    }
    @media (min-width: $screen-md) {
      margin-top: 70px;
    }
  }
  .instructions__header {
    display: inline-block;
    position: relative;
    top: -10px;
    margin-top: -10px;
    padding: 6px 15px;
    background-color: $acro-gray-dark;
    font-size: 16px;
    font-weight: 500;

    @media (min-width: $screen-sm) {
      padding: 6px 30px;
      font-size: 20px;
    }
  }
  .instructions__steps {
    padding: 0 15px;

    > div {
      margin-bottom: 15px;

      p {
        margin-bottom: 5px;
      }
    }

    @media (min-width: $screen-md) {
      padding: 0 90px;

      &:first-of-type {
        > div:first-of-type {
          border-right: 1px solid rgba(256, 256, 256, .3);
        }
      }
    }

    .instructions__steps__title {
      font-size: 17px;
      font-family: $font-family-serif;

      @media (min-width: $screen-sm) {
        font-size: 26px;
      }
    }

    .number {
      display: block;
      width: 38px;
      height: 38px;
      border: 2px solid $acro-orange;
      border-radius: 50%;
      background-color: $acro-gray-dark;
      line-height: 34px;
      text-align: center;
      font-family: $font-family-serif;
      font-size: 25px;
      font-weight: 500;
    }
  }
  .instructions__image {
    display: inline-block;
    margin: 0 15px -95px 0;
  }

  // Start button.
  .page-section--start-button {
    min-height: 140px;
    margin-bottom: 0;
    background-size: unset !important;
    background-position-y: top;
    background-repeat: no-repeat;

    @media (min-width: $screen-lg) {
      background-size: 100% 140px !important;
    }

    .button-cta-link.btn {
      font-size: 24px;
      font-weight: 400;
      color: rgba(#fff, .85);
      transition: color .2s ease-in-out;

      i.fa--icon-after {
        position: relative;
        bottom: 0;
        display: block;
        padding: 0;
        font-size: 2.3em;
        transition: padding .2s ease-in-out;
      }

      &:hover, &:focus {
        color: rgba(#fff, 1);

        i.fa--icon-after {
          color: $acro-orange;
          padding-top: 3px;
        }
      }
    }
  }

  // Results.
  .page-section--results {
    display: none; // Initially hide results page section.
    background-position-y: top;
    background-size: contain !important;
    background-repeat: no-repeat;

    // Glow behind the titles.
    .result-a-title,
    .result-b-title,
    .result-c-title {
      // Multiple text shadow layers to make it more pronounced.
      text-shadow: 0 0 10px #FFFFFF, 0 0 15px #FFFFFF, 0 0 15px #FFFFFF;
    }
  }

  // Results - Hide the results loading animation.
  .page-section--form .page-section__content-wrapper > .paragraphs-items > .field > .field-items > .field-item:last-of-type {
    display: none;
  }

  // Results - Tab call-to-action.
  .tab-pane .text-section .row,
  .panel-body .text-section .row {
    padding: 20px 0 0;
    background: url(/sites/default/files/ideal-arch-background-2.jpg) repeat center center;
    color: #fff;

    @media (min-width: $screen-md) {
      padding: 50px 30px 0;
    }

    .cta-title {
      font-size: 30px;
      font-weight: 300;

      @media (min-width: $screen-md) {
        margin-bottom: 0;
        font-size: 42px;
      }
    }

    h5 {
      margin-bottom: 0;
      font-size: 22px;
      font-weight: bold;

      @media (min-width: $screen-md) {
        margin-bottom: -10px;
      }
    }
  }

  // HubSpot form.
  .page-section--form .hbspt-form {
    form {
      max-width: 100%;
    }

    ul {
      padding: 4px 0 0 30px;

      @media (min-width: $screen-sm) {
        padding: 10px 0 0 40px;
      }

      &.hs-error-msgs {
        padding-left: 0;

        @media (min-width: $screen-sm) {
          padding-left: 0;
        }
      }

      li {
        padding: 0 0 2px;
      }
    }

    // Required marker.
    .hs-form-required {
      display: none !important;
    }

    // Form fields.
    .hs-form-field {
      position: relative;
      padding: 20px;
      margin: 0 4px 25px;
      box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2);
      border-radius: $border-radius-small;
      background-color: #fff;
      transition: margin .15s ease, box-shadow .15s ease;

      &:hover, &:focus {
        margin: 0 0 25px;
        box-shadow: 0 6px 10px 0 rgba(0,0,0,0.3);
      }

      > label {
        margin-bottom: 12px;
        font-size: 18px;
        font-weight: normal;
        color: $acro-orange;

        @media (min-width: $screen-sm) {
          font-size: 22px;
        }
      }
    }

    // Checkboxes and radios.
    .hs-form-checkbox,
    .hs-form-radio {
      label {
        @media (min-width: $screen-sm) {
          margin-bottom: 12px;
        }
      }

      span {
        display: block;
        padding-left: 0;
        cursor: pointer;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: -30px;
          height: 22px;
          width: 22px;
          background-color: $gray-lighter;
          border: 2px solid $gray-lighter;
          border-radius: 50%;
          transition: background-color .1s ease-out, border .1s ease-out;
        }

        @media (min-width: $screen-sm) {
          font-size: 18px;

          &:before {
            top: -2px;
            left: -38px;
            height: 26px;
            width: 26px;
          }
        }
      }

      // Hide input so that we can make our own.
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      &:hover input ~ span:before,
      &:focus input ~ span:before {
        background-color: $gray-light;
        border: 2px solid $gray-light;
      }

      input:checked ~ span:before {
        background-color: #fff;
        border: 7px solid $commerce-blue;
      }
    }

    // Email field.
    .hs-input[type="email"] {
      max-width: 400px !important;
    }

    // Submit.
    .hs-submit {
      text-align: center;
      margin-bottom: 40px;

      input[type="submit"] {
        padding: 15px 25px;
        font-size: 22px;
      }
    }
  }
}

// Cookie message.
.ideal-architecture-analysis {
  .page-section--form-cookie-message {
    display: none; // Revealed with jQuery.
    padding: 15px 0;
    background-color: $acro-blue !important;
    color: #fff;

    .paragraphs-item-basic-content {
      padding-bottom: 0;
    }

    h1, h2, h3, h4, h5, h6 {
      margin: 0 0 5px;
      color: #fff;
    }

    p {
      margin-bottom: 5px !important;

      &:last-of-type {
        margin-bottom: 0 !important;
      }
    }

    a[href="#reset-cookie"] {
      position: relative;
      display: block;
      margin: 5px 0 0 0;
      padding: 0;
      color: rgba(#fff, .85);

      &:before {
        padding-right: 5px;
        @extend %fa-icon;
        @extend .fas;
        content: fa-content($fa-var-sync-alt);
      }

      &:hover, &:focus {
        text-decoration: underline;
        color: rgba(#fff, 1);
      }

      @media (min-width: $screen-sm) {
        display: inline-block;
        padding: 0 0 0 15px;
        margin: 0 0 0 10px;
        border-left: 1px solid rgba(#fff, .5);
      }
    }
  }
}
