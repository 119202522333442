#comments ul.links { display: inherit; }

#comment-form .form-text, #comment-form .form-textarea {
  background: #fff;
  color: $gray;
  border: 1px solid $gray-light;
  padding: 5px;
}
#comment-form .form-item input.error, #comment-form .form-item textarea.error, #comment-form .form-item select.error { border: 2px solid red; }

#comment-form span.form-required { display: none !important; }
#comments .filter-wrapper { display: none !important; }
#comments .grippie { display: none !important; }

#comment-body-add-more-wrapper .filter-wrapper { display: none !important; }
#comment-body-add-more-wrapper .grippie { display: none !important; }

#comments {
  clear: both;
  padding: 15px 0 20px 0;
}
#comments h2 {
  font-size: 1.33em;
  margin: 0 0 6px 0;
  padding: 0;
}
#comments h3 {
  font-size: 1.25em;
  margin: 0 0 6px 0;
  padding: 0;
}
.comment .links { margin: 0; padding: 0; }
.comment .links li {
  display: inline;
  margin: 0 10px 0 0;
  padding: 0;
  background: none;
}

.comment-text .content { padding: 0 0 10px 0; }

/* -- Actual Comment Entry -- */

.comment {
  padding: 10px;
  margin: 0 0 10px 0;
  background: #f3f3f3;
  border-bottom: 3px solid #dedede;
}
.comment .submitted { color: #686868; font-size: 0.92em; }
.comment span.new { display: none; }

.comment-author-info { }
.commenter-name { font-weight: bold; }
.comment-time { font-style: italic; }

#comment-controls {
  clear: both;
  padding: 12px 0 0 0;
}

#comment-form #edit-submit {
  float: right;
}
