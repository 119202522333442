/**
 * Paragraph - Icon List.
 */

// Default theme - Icon List.
ul.ul--icon-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  @media (max-width: $screen-xs-max) {
    margin-left: 0;
  }

  > li {
    display: inline-flex;
    flex: 1 0 100%;
    align-items: center;
    min-height: 56px;
    padding: 0;
    margin-bottom: 30px;
    background: none;

    @media (min-width: $screen-xs) {
      display: block;
      flex: 1 0 50%;
      max-width: 270px;
      padding: 0 20px;
      text-align: center;
    }

    @media (min-width: $screen-md) {
      flex: 1 0 25%;
    }

    @media (min-width: $screen-lg) {
      flex: 1 0;
      max-width: 480px;
    }

    @media (min-width: 2000px) {
      padding: 0 40px;
    }

    .icon-list__item__icon-wrapper {
      display: inline-flex;
      flex: 0 0 70px;
      margin-bottom: 15px;
    }

    // Icon.
    .icon-list__item__icon {
      img {
        position: relative;
        top: -4px;
        width: 100%;
        max-width: 35px;
      }

      .icon-list__item__icon-background {
        position: relative;
        display: inline-block;
        height: 56px;
        width: 56px;
        border: 2px solid $acro-orange;
        border-radius: 50%;
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        line-height: 55px;
        color: $acro-gray-dark;
      }
    }

    .icon-list__item__message {
      font-size: 17px;

      p {
        margin-bottom: 0;
      }
    }
  }
}

// Alternate theme - Icon List.
.icon-list.icon-list--alternate {
  ul.ul--icon-list > li {
    display: block;
    text-align: center;
    margin-bottom: 45px;

    .icon-list__item__icon-wrapper {
      margin-bottom: 10px;

      .icon-list__item__icon-background {
        border: 0;
        font-size: 3em;
        color: $acro-gray-mid;
      }
    }

    // Icon.
    .icon-list__item__icon {
      img {
        max-width: 52px;
      }
    }
  }
}

// Old lists prior to the paragraph being created.
// @todo Everything below can be removed once old lists arae transitioned over.

@mixin before-pseudo {
  @extend %fa-icon;
  @extend .fas;
  content: fa-content($fa-var-trophy) !important;
  position: absolute !important;
  display: inline-block;
  vertical-align: middle;
  top: -2px !important;
  left: 0;
  height: 56px;
  width: 56px;
  margin: 0 5px 0 0;
  border-radius: 50%;
  border: 2px solid $acro-orange;
  text-align: center;
  font-size: 28px !important;
  font-weight: 600 !important;
  line-height: 53px !important;
  color: $acro-gray-dark !important;

  @media (min-width: $screen-md) {
    position: relative !important;
    display: block !important;
    margin: 0 auto 5px;
  }
}

ul.ul--icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  @media (max-width: $screen-xs-max) {
    margin-left: 0;
  }

  > li {
    flex: 1 0 100%;
    min-height: 56px;
    padding: 0 0 0 65px;
    margin-bottom: 20px;
    background: none;

    @media (min-width: $screen-md) {
      flex: 1 0 15%;
      max-width: 200px;
      padding: 0 10px;
      margin-bottom: 0;
      text-align: center;
    }

    span {
      @media (max-width: $screen-sm-max) {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
      }
    }
  }
}

// About Us icon list.
ul.ul--icons.ul--about-us {
  > li {
    &:before {
      @include before-pseudo;
    }
    &:nth-of-type(1):before {
      content: fa-content($fa-var-map-marker-check) !important;
    }
    &:nth-of-type(2):before {
      content: "'98" !important;
      font-size: 26px !important;
      font-weight: 600 !important;
      font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    }
    &:nth-of-type(3):before {
      content: fa-content($fa-var-users) !important;
    }
    &:nth-of-type(4):before {
      content: fa-content($fa-var-shopping-cart) !important;
    }
    &:nth-of-type(5):before {
      content: fa-content($fa-var-check) !important;
    }
    &:nth-of-type(6):before {
      content: fa-content($fa-var-briefcase) !important;
    }
  }
}

// Developer Services icon list.
ul.ul--icons.ul--dev-services {
  > li {
    @media (min-width: $screen-md) {
      flex: 1 0 20%;
      max-width: 380px;
      padding: 0 10px;
      margin-bottom: 0;
      text-align: center;
    }

    &:before {
      @include before-pseudo;
    }

    &:nth-of-type(1):before {
      content: fa-content($fa-var-filter) !important;
    }
    &:nth-of-type(2):before {
      content: fa-content($fa-var-chart-network) !important;
    }
    &:nth-of-type(3):before {
      content: fa-content($fa-var-signal-alt) !important;
    }
    &:nth-of-type(4):before {
      content: fa-content($fa-var-user-crown) !important;
    }
  }
}

// Drupal Dev icon list.
ul.ul--icons.ul--drupal-dev {
  > li {
    @media (min-width: $screen-md) {
      flex: 1 0 25%;
      max-width: none;
      padding: 0 10px;
      margin-bottom: 40px;
      text-align: center;
    }
    @media (min-width: 1750px) {
      flex: 1 0 20%;
      max-width: 200px;
      padding: 0 10px;
      margin-bottom: 0;
      text-align: center;
    }

    &:before {
      @include before-pseudo;
    }

    &:nth-of-type(1):before {
      font-weight: 400 !important;
      content: fa-content($fa-var-code) !important;
    }
    &:nth-of-type(2):before {
      @extend .fab;
      content: fa-content($fa-var-drupal) !important;
    }
    &:nth-of-type(3):before {
      content: fa-content($fa-var-users) !important;
    }
    &:nth-of-type(4):before {
      content: fa-content($fa-var-shopping-cart) !important;
    }
    &:nth-of-type(5):before {
      content: fa-content($fa-var-check) !important;
    }
    &:nth-of-type(6):before {
      content: fa-content($fa-var-user-crown) !important;
    }
    &:nth-of-type(7):before {
      content: fa-content($fa-var-coffee) !important;
    }
    &:nth-of-type(8):before {
      content: fa-content($fa-var-graduation-cap) !important;
    }
  }
}

// BigCommerce Dev icon list.
ul.ul--icons.ul--bigcommerce-dev {
  > li {
    @media (min-width: $screen-md) {
      flex: 1 0 25%;
      max-width: none;
      padding: 0 10px;
      margin-bottom: 40px;
      text-align: center;
    }
    @media (min-width: 1750px) {
      flex: 1 0 20%;
      max-width: 200px;
      padding: 0 10px;
      margin-bottom: 0;
      text-align: center;
    }

    &:before {
      @include before-pseudo;
    }

    &:nth-of-type(1):before {
      content: '' !important;
      background: url(/sites/default/files/bigcommerce-logomark-whitebg.png) center center no-repeat;
      background-size: 28px;
    }
    &:nth-of-type(2):before {
      content: '' !important;
      background: url(/sites/default/files/bigcommerce_elite_agency_partner_badge-alt.png) center center no-repeat;
      background-size: 24px;
    }
    &:nth-of-type(3):before {
      content: fa-content($fa-var-chart-line) !important;
    }
    &:nth-of-type(4):before {
      font-weight: 400 !important;
      content: fa-content($fa-var-code) !important;
    }
    &:nth-of-type(5):before {
      content: fa-content($fa-var-shopping-cart) !important;
    }
    &:nth-of-type(6):before {
      content: fa-content($fa-var-check) !important;
    }
    &:nth-of-type(7):before {
      content: fa-content($fa-var-user-crown) !important;
    }
  }
}
