/*------------------------------
*	 Sitemap.
------------------------------*/

.site-map-box-menu {
  float: right;
  width: 33%;
  margin: 0;
}

.site-map-box-menu ul,
#site-map .site-map-box ul {
  margin-left: 0;
  padding-left: 0;
}

#site-map .site-map-box-menu ul li {
  padding-left: 20px;
}

#site-map li a.active {
  color: $acro-orange;
}

#site-map .site-map-box-menu ul li ul {
  margin-top: 5px;
}

#site-map .site-map-box-menu ul li ul li a {
  font-weight: normal;
}
