/*------------------------------
*	 Client Listing View.
------------------------------*/

#client-listing {
  display: block;
  clear: both;
  margin: 0;
  padding: 17px 0 45px 0;

  @media screen and (max-width: 700px) {
    ul li {
      margin: 0 6% 4% 0;
      width: 29.3333%;

      &:nth-child(4n+0) {
        margin-right: 6%;
      }
      &:nth-child(3n+0) {
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 450px) {
    padding-bottom: 24px;

    ul li {
      margin: 0 8% 4% 0;
      width: 46%;

      &:nth-child(4n+0) {
        margin-right: 8%;
      }
      &:nth-child(3n+0) {
        margin-right: 8%;
      }
      &:nth-child(2n+0) {
        margin-right: 0;
      }
    }
  }
}

.client-logo {
  margin-bottom: 25px;
  @media (max-width: 395px) {
    width: 100%;
    padding: 0 16%;
  }

  .logo-has-link {
    overflow: hidden;

    img {
      border: 1px solid white;
      border-bottom: none;
    }

    p {
      visibility: hidden;
      height: 35px;
      max-width: 244px;
      margin-bottom: 0;
      padding: 6px 0 0 0;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      background: white;
    }

    a {
      display: block;

      &:hover, &:focus {

        img {
          border-color: #e5e5e5;
          transition: border-color 0.15s linear;
        }
        p {
          visibility: visible;
          background: $acro-gray-dark;

          #views-study-icon {
            background: url(../gfx/arrow-icon.png) 0 1px no-repeat;
            padding-right: 4px;
            margin: 0 0 0 3%;
          }
          transition: visibility 0.15s linear, background-color 0.15s linear;

          &:hover {
            color: $acro-orange;
            transition: color 0.15s linear, background-color 0.15s linear;
          }
        }
      }
    }

  }
  .logo-no-link {
    p {
      height: 35px;
      max-width: 244px;
      margin-bottom: 0;
    }

    img {
      border: 1px solid transparent;
      border-bottom: none;
    }
  }
}
