/**
 * Digital Commerce Assessment page.
 *
 * This is a unique page with interactive forms.
 *
 * Note: There is a separate Digital Commerce Diagnostic page.
 * Styling for this page is at the bottom of this sheet.
 */

// General layout updates.
.digital-commerce-assessment {
  min-height: 90vh;

  .page-section--gate {
    padding-bottom: 80px;
  }

  .node-webform {
    max-width: 860px;
  }
}

// Hide all sections at the start except for a select few. Reveal rest with jQuery as we go.
.digital-commerce-assessment {
  .page-section {
    display: none;
  }

  .page-section--form-2-intro-banner,
  .page-section--form-2 {
    display: block;
  }
}

// Introduction specific styling.
.digital-commerce-assessment {
  // Tablet and desktop.
  .assessment-intro-banner {
    padding: 20px 0 0;
    color: #fff;

    h1, h2, .h2, h3, h4, h5, h6, p {
      text-shadow: 0 1px 6px #000000;
    }

    h1, .h2 {
      @media (min-width: $screen-md) {
        padding-top: 15px;
      }
      @media (min-width: $screen-lg) {
        padding-top: 30px;
      }
    }

    a[href="#start-assessment"] {
      display: inline-block;
      position: relative;
      padding-bottom: 40px;
      font-size: 19px;
      font-weight: 500;
      color: rgba(#fff, .85);
      text-align: center;
      transition: color .2s ease-in-out;

      &:after {
        @extend %fa-icon;
        @extend .far;
        content: fa-content($fa-var-angle-down);
        position: absolute;
        display: inline-block;
        width: 40px;
        bottom: -10px;
        left: calc(50% - 20px);
        color: $acro-orange;
        font-size: 35px;
        transition: bottom .2s ease-in-out;
      }

      &:hover, &:focus {
        color: rgba(#fff, 1);

        &:after {
          bottom: -13px;
        }
      }
    }

    .assessment-intro-white-box {
      display: none;

      @media (min-width: $screen-md) {
        display: inline-block;
        position: relative;
        width: 100%;
        padding: 30px;
        background-color: #fff;
        box-shadow: 0 6px 35px -8px #000;
        z-index: 2;

        h1, h2, h3, h4, h5, h6 {
          color: $acro-orange;
          text-shadow: none;
          margin-top: 0;
        }

        p {
          color: $text-color;
          text-shadow: none;
        }

        ul {
          color: $text-color;
        }
      }
    }
  }

  // Mobile.
  .assessment-intro-banner--mobile {
    @media (max-width: $screen-xs-max) {
      padding: 20px 0 0;

      h1, h2, h3, h4, h5, h6 {
        margin-top:0 !important;
        color: #fff;
      }
    }
  }
}

// Cookie message.
.digital-commerce-assessment {
  .page-section--form-2-cookie-message {
    padding: 15px 0;
    background-color: $acro-blue !important;
    color: #fff;

    .paragraphs-item-basic-content {
      padding-bottom: 0;
    }

    h1, h2, h3, h4, h5, h6 {
      margin: 0 0 5px;
      color: #fff;
    }

    p {
      margin-bottom: 5px !important;

      &:last-of-type {
        margin-bottom: 0 !important;
      }
    }

    a[href="#reset-cookie"] {
      position: relative;
      display: block;
      margin: 5px 0 0 0;
      padding: 0;
      color: rgba(#fff, .85);

      &:before {
        @extend %fa-icon;
        @extend .fas;
        content: fa-content($fa-var-sync-alt);
        padding-right: 5px;
      }

      &:hover, &:focus {
        text-decoration: underline;
        color: rgba(#fff, 1);
      }

      @media (min-width: $screen-sm) {
        display: inline-block;
        padding: 0 0 0 15px;
        margin: 0 0 0 10px;
        border-left: 1px solid rgba(#fff, .5);
      }
    }
  }
}

// Links and Button defaults.
.digital-commerce-assessment {
  .node-webform a {
    color: $link-color;

    &:hover, &:focus {
      color: $link-hover-color;
    }
  }

  .node-webform .dca-button,
  .node-webform input.webform-submit {
    display: inline-block;
    position: relative;
    padding: 15px 30px;
    margin-top: 20px;
    border: 1px solid $link-color;
    background-color: $link-color;
    outline: none;
    color: #fff;
    font-size: 22px;
    line-height: 1.15em;
    text-transform: none;
    cursor: pointer;
    pointer-events: all;
    opacity: 1;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border 0.15s ease-in-out, opacity 0.15s ease-in-out;

    &:hover, &:focus {
      border: 1px solid $link-hover-color;
      background-color: $link-hover-color;
      color: #fff;
    }

    &.disabled,
    &.disabled:hover,
    &.disabled:focus {
      border: 1px solid #000;
      background-color: #000;
      color: #fff;
      cursor: not-allowed;
      pointer-events: none;
      opacity: .1;
    }
  }

  // Buttons added within webform.
  .node-webform .dca-button {

    // Go Deeper.
    &.dca-button--go-deeper:after {
      @extend %fa-icon;
      @extend .fas;
      content: fa-content($fa-var-arrow-circle-right);
      padding-left: 12px;
    }

    // Next Section.
    &.dca-button--next {
      width: 100%;
      padding: 12px 30px;
      font-size: 18px;
      text-align: center;

      &:after {
        padding-left: 12px;
        @extend %fa-icon;
        @extend .fas;
        content: fa-content($fa-var-arrow-circle-right);
      }
    }
  }
}

/**
 * Form defaults.
 */
.page-section--gate,
.page-section--form-1,
.page-section--form-2,
.page-section--results-last-question .node-webform {
  .field-name-body {
    margin-bottom: 30px;

    @media (min-width: $screen-sm) {
      margin-bottom: 60px;
    }
  }

  .form-actions {
    display: none; // Reveal with jQuery.
    margin: 0;
    text-align: center;
  }

  // Hide 'Go back to form' links.
  .links {
    display: none;
    visibility: hidden;
  }

  // Description.
  .description {
    font-size: 13px;
    font-style: italic;
  }

  .webform-component {
    display: none; // Reveal with jQuery.
  }

  // Form element defaults.
  .webform-component,
  .webform-component-fieldset {
    position: relative;
    width: 100%;
    float: none;
    padding: 0 0 5px;
    margin-bottom: 30px;
    border: 0;
    border-bottom: 1px solid $gray-lighter;
    transition: opacity .2s ease;

    > label {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: normal;
      color: $acro-gray-dark;

      @media (min-width: $screen-sm) {
        font-size: 22px;
      }
    }

    .form-item {
      display: block;
      position: relative;
      padding: 0;
      border: none;
      font-size: 20px;

      &.webform-component-radios {
        border-bottom: 1px solid $gray-lighter;
        padding: 0 0 5px;
      }
    }
  }

  // Radios.
  .webform-component-radios {
    > label {
      @media (min-width: $screen-sm) {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 320px);
      }
    }

    .form-radios {
      @media (min-width: $screen-sm) {
        display: inline-block;
        vertical-align: top;
        width: 310px;
        text-align: right;
      }
    }

    .form-item.form-type-radio {
      display: block;
      margin-top: 0;
      margin-bottom: 15px;
      padding: 0 20px 0 30px;
      user-select: none;

      @media (min-width: $screen-sm) {
        display: inline-block;
        vertical-align: top;
        padding: 0 25px 0 35px;
      }

      &:last-of-type {
        padding-right: 0;
      }

      // Hide the browser's default radio.
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .option {
        padding-left: 0;
        font-size: 18px;
        cursor: pointer;

        &:before {
          content: '';
          position: absolute;
          top: 2px;
          left: 0;
          height: 20px;
          width: 20px;
          background-color: $gray-lighter;
          border: 2px solid $gray-lighter;
          border-radius: 50%;
          transition: background-color .1s ease-out, border .1s ease-out;
        }

        @media (min-width: $screen-sm) {
          padding-left: 4px;
          font-size: 20px;

          &:before {
            top: -2px;
            height: 30px;
            width: 30px;
          }
        }
      }

      &:hover input ~ .option:before,
      &:focus input ~ .option:before {
        background-color: $gray-light;
        border: 2px solid $gray-light;
      }

      input:checked ~ .option:before {
        background-color: #fff;
        border: 7px solid $commerce-blue;
      }
    }
  }

  // Textfields.
  .webform-component-textfield,
  .webform-component-textarea,
  .webform-component-email {
    text-align: left;

    > label {
      margin-bottom: 5px;
    }
  }

  // Markup field.
  .webform-component-markup {
    padding: 0;
    border-bottom: none;
  }

  // Fieldsets.
  .webform-component-fieldset {
    display: none;
    margin-left: 0;
    margin-right: 0;
    border-bottom: none;
    cursor: default;

    // Show first. Rest are reveal with jQuery.
    &:first-of-type {
      display: block;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  // Progress bar.
  .diagnosis-progress {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 2px;
    padding: 0;
    margin: 0;
    background-color: $gray-lighter;

    .diagnosis-progress__indicator {
      position: absolute;
      top: 0;
      left: 0;
      height: 2px;
      background-color: $acro-orange;
      content: '';
    }
  }

  // Confirmation.
  .webform-confirmation {
    display: none; // Reveal with jQuery.
    margin: 0 auto 30px;
  }
}

/**
 * Form class modifiers.
 */
.page-section--gate,
.page-section--form-1,
.page-section--form-2,
.page-section--results-last-question .node-webform {
  // Radios with image to left for larger screens only.
  // This could be used with any form but was built specifically for form 1.
  .webform-component-radios {
    &.with-image {
      @media (min-width: $screen-sm) {
        > label {
          width: calc(100% - 290px);
        }

        .form-radios {
          text-align: left;
        }

        &:before {
          clear: both;
          position: relative;
          float: left;
          display: inline-block;
          vertical-align: middle;
          width: 250px;
          margin-right: 30px;
          content: '';
        }

        //// Form 1 images.
        //&.webform-component--q1 {
        //  min-height: 240px;
        //
        //  &:before {
        //    height: 220px;
        //    background: url(../gfx/digital_commerce_assessment/dca-1.svg) top left no-repeat;
        //    background-size: contain;
        //  }
        //}
        //&.webform-component--q2 {
        //  min-height: 220px;
        //
        //  &:before {
        //    height: 200px;
        //    background: url(../gfx/digital_commerce_assessment/dca-2.svg) top left no-repeat;
        //    background-size: contain;
        //  }
        //}
        //&.webform-component--q3 {
        //  min-height: 225px;
        //
        //  &:before {
        //    height: 205px;
        //    background: url(../gfx/digital_commerce_assessment/dca-3.svg) top left no-repeat;
        //    background-size: contain;
        //  }
        //}
        //&.webform-component--q4 {
        //  min-height: 170px;
        //
        //  &:before {
        //    height: 150px;
        //    background: url(../gfx/digital_commerce_assessment/dca-4.svg) top left no-repeat;
        //    background-size: contain;
        //  }
        //}
        //&.webform-component--q5 {
        //  min-height: 270px;
        //
        //  &:before {
        //    height: 250px;
        //    background: url(../gfx/digital_commerce_assessment/dca-5.svg) top left no-repeat;
        //    background-size: contain;
        //  }
        //}
        //&.webform-component--q6 {
        //  min-height: 185px;
        //
        //  &:before {
        //    height: 165px;
        //    background: url(../gfx/digital_commerce_assessment/dca-6.svg) top left no-repeat;
        //    background-size: contain;
        //  }
        //}
      }
    }

    &.with-image.change-image {
      @media (min-width: $screen-sm) {
        transition: height .02s;

        > label {
          width: calc(100% - 420px);
          transition: width .02s;
        }

        .form-radios {
          text-align: right;
        }

        &:before {
          width: 100px;
          transition: width .2s, height .2s;
        }

        //// Form 1 images.
        //&.webform-component--q1 {
        //  min-height: 110px;
        //  &:before {
        //    height: 90px;
        //  }
        //}
        //&.webform-component--q2 {
        //  min-height: 100px;
        //  &:before {
        //    height: 80px;
        //  }
        //}
        //&.webform-component--q3 {
        //  min-height: 105px;
        //  &:before {
        //    height: 85px;
        //  }
        //}
        //&.webform-component--q4 {
        //  min-height: 85px;
        //  &:before {
        //    height: 65px;
        //  }
        //}
        //&.webform-component--q5 {
        //  min-height: 120px;
        //  &:before {
        //    height: 100px;
        //  }
        //}
        //&.webform-component--q6 {
        //  min-height: 90px;
        //  &:before {
        //    height: 70px;
        //  }
        //}
      }
    }
  }

  .webform-component {
    // Stack radio options.
    &.stack {
      > label {
        width: 100%;
      }

      .form-radios {
        width: 100%;
        text-align: left;
      }

      .form-item.form-type-radio {
        display: block;
        vertical-align: top;
        text-align: left;
      }
    }

    // Disable element.
    &.disabled {
      opacity: .4;

      &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        content: '';
        cursor: not-allowed;
      }
    }
  }
}

/**
 * Overrides for Gate form.
 */
.page-section--gate {
  .webform-component-fieldset {
    .webform-component-textfield,
    .webform-component-email {
      @media (min-width: $screen-sm) {
        display: inline-block !important;
        width: 50%;
        float: left;

        &:nth-of-type(odd) {
          padding: 0 0 0 10px;
        }

        &:nth-of-type(even) {
          padding: 0 10px 0 0;
        }
      }
    }
  }

  .form-actions {
    display: block;
  }
}

/**
 * Overrides for Form 3 (Results contact).
 */
.page-section--results-last-question .node-webform {
  display: none; // Reveal with jQuery.
  max-width: 100%;
  margin: 0 0 45px;
  padding: 15px 15px 10px;
  background-color: rgba($acro-green, .1);
  border-radius: 10px;
  text-align: center;

  @media (min-width: $screen-sm) {
    padding: 25px 30px 15px;
    text-align: center;
  }

  form {
    max-width: 800px;
    margin: 0 auto;
  }

  .webform-component {
    display: none;
    padding-bottom: 5px;
    margin-bottom: 15px;

    > label {
      width: 100%;
      margin-bottom: 10px;
    }

    .form-radios {
      width: 100%;
      margin-top: 5px;
      text-align: left;

      @media (min-width: $screen-sm) {
        text-align: center;
      }

      .form-item.form-type-radio .option:before {
        border: 2px solid $gray-light;
      }
    }
  }

  .webform-component-textfield,
  .webform-component-email {
    @media (min-width: $screen-sm) {
      text-align: center;

      input {
        text-align: center;
      }
    }
  }

  .webform-component--phone,
  .webform-component-email {
    border-bottom: none;
    margin-bottom: 0;
  }

  .description {
    margin: 0 0 10px;
  }

  input.form-text {
    max-width: 400px !important;
    margin-bottom: 15px;
  }

  input.webform-submit {
    margin-top: 0;
  }

  .webform-confirmation {
    display: block;
  }
}

/**
 * Results.
 *
 * Used in webform confirmation for form1 and standalone for form2.
 */
.diagnosis-result {
  display: none; // Reveal with jQuery.
  margin-top: 15px;

}

.diagnosis-result__section-wrapper {
  blockquote {
    color: $text-color;
  }

  &:last-of-type {
    margin-bottom: 20px;
    border-bottom: 1px solid $gray-lighter;
  }
}

.diagnosis-result__info {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  padding: 15px;
  border: 1px solid $gray-lighter;

  @media (min-width: $screen-sm) {
    padding: 25px 30px;
  }
}

.diagnosis-result__yes,
.diagnosis-result__no {
  display: none; // Reveal with jQuery.
}

.diagnosis-result__privacy {
  position: relative;
  max-width: 440px;
  margin: 35px auto 40px;
  padding-left: 45px;
  font-style: italic;
  font-size: 14px;

  &:before {
    @extend %fa-icon;
    @extend .fas;
    content: fa-content($fa-var-lock-alt);
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    font-size: 40px;
    font-style: normal;
    line-height: 40px;
    color: $gray-lighter;
  }
}

.diagnosis-result__title {
  position: relative;
  padding: 0 50px 5px 40px;
  margin-bottom: 0;
  border-bottom: 1px solid $gray-lighter;
  cursor: pointer;

  &:before {
    @extend %fa-icon;
    @extend .fas;
    content: fa-content($fa-var-check);
    position: absolute;
    top: 3px;
    left: 0;
    height: 28px;
    width: 28px;
    background-color: $acro-green;
    border-radius: 50%;
    color: #fff;
    font-size: 19px;
    line-height: 30px;
    text-align: center;

    @media (max-width: $screen-xs-max) {
      top: 0;
      height: 27px;
      width: 27px;
      line-height: 27px;
    }
  }

  &:after {
    @extend %fa-icon;
    @extend .far;
    content: fa-content($fa-var-angle-down);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 40px;
    color: $acro-gray-dark;
    text-align: center;
    transition: background-color .2s ease-in-out;
  }

  &.diagnosis-result__title--warning {
    &:before {
      background-color: $brand-warning;
      content: '!';
      font-family: $font-family-sans-serif;
      font-size: 25px;
      font-weight: 700;
    }
  }

  &.diagnosis-result__title--danger {
    &:before {
      background-color: $brand-danger;
      content: fa-content($fa-var-times);
      line-height: 28px;
    }
  }

  &.diagnosis-result__title--active {
    &:after {
      content: fa-content($fa-var-angle-up);
    }
  }

  &:hover, &:focus {
    &:after {
      background-color: $gray-lighter;
    }
  }
}

// Result charts.
.result-chart {
  max-width: 400px;
  margin: 0 auto;
}

/**
 * Digital Commerce Diagnostic page.
 *
 * This page is a separate lander for downloading the diagnostic PDF without completing the assessment.
 */

.digital-commerce-diagnostic {
  h1.h2, h2 {
    @media (min-width: $screen-md) {
      font-size: 49px !important;
    }
  }

  h5 {
    @media (min-width: $screen-md) {
      font-size: 25px;
    }
  }

  .diagnostic-example-image {
    margin-top: 60px;
    margin-bottom: -95px;

    @media (min-width: $screen-md) {
      margin-top: 80px;
      margin-bottom: -110px;
    }
  }

  .button-ctas .field-item {
    width: 100%;
    text-align: center;
  }

  .button-cta-link.btn--primary {
    width: 100%;
    max-width: 440px;
    padding: 17px;
    margin: 0 auto;
    font-size: 21px;
    border: 1px solid $acro-blue;
    background-color: $acro-blue;

    &:focus,
    &:hover {
      border: 1px solid $commerce-blue-dark;
      background-color: $commerce-blue-dark;
    }
  }


  // HubSpot form styling overrides.
  .hbspt-form {
    form {
      max-width: 440px;
    }

    label {
      font-weight: normal;
    }

    .hs-input {
      font-size: 18px;
    }

    .hs-button {
      width: 100%;
      padding: 20px;
      font-size: 21px;
    }
  }
}
