/**
 * Paragraph - Rich media banner & individual banners.
 */

// General layout and effects.
.banner-group {
  position: relative;
  // Remove default bootstrap margin.
  margin-left: -15px;
  margin-right: -15px
}

.banner-wrapper {
  position: relative;
  display: flex;
  min-height: 215px; // This value overridden if Slick Slider or Full Screen setting checked.
  width: 100%;
  border-bottom: 1px solid #fff;
  overflow: hidden;

  @media (max-width: $screen-sm-max) {
    min-height: auto;
  }

  .banner {
    display: flex;
    width: 100%;
    height: auto;
    align-items: flex-start; //optional
    justify-content: center; //optional

    .banner__background {
      position: absolute;
      z-index: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: center;

      // Parallax effect.
      &.fx--parallax {
        overflow: auto;
        background-position-y: top;
        background-attachment: fixed;

        @media (max-width: $screen-md-max) {
          background-attachment: scroll;
        }
      }

      // Follow mouse hover effect.
      &.fx--follow-mouse {
        left: -40px;
        right: -40px;
        top: -40px;
        bottom: -40px;
        width: calc(100% + 40px);
      }
    }

    .banner__content {
      position: relative;
      z-index: 1;
      width: 100%;
      max-width: 1250px;
      margin: 0;
      padding: 80px 80px 100px;
      // Stop pointer events so follow mouse effect works when hovering over content.
      pointer-events: none;

      // But enable pointer events for specific elements.
      a,
      .paragraphs-item-embed-code {
        pointer-events: all;
      }

      @media (max-width: $screen-sm-max) {
        padding: 40px;
      }
    }
  }
}

// Full screen banner scroll down button.
.banner-group--full-screen {
  .banner .banner__content {
    padding-bottom: 100px;
  }
}

@keyframes banner-group__scroll-down {
  from {
    bottom: 30px;
    opacity: 0.5;
  }
  to {
    bottom: 25px;
    opacity: 0.8;
  }
}

// Slick Slider UI dark theme overrides.
.page-section--dark-theme {
  .slick-prev {
    background: url(../gfx/arrow-left_white.svg) left center no-repeat;

    &:hover,
    &:focus {
      background: url(../gfx/arrow-left_white.svg) left center no-repeat;
    }
  }

  .slick-next {
    background: url(../gfx/arrow-right_white.svg) right center no-repeat;

    &:hover,
    &:focus {
      background: url(../gfx/arrow-right_white.svg) right center no-repeat;
    }
  }

  ul.slick-dots {
    li {
      button:before {
        color: #fff;
      }
    }
  }
}

// Scroll down arrow.
.banner-group__scroll-down {
  display: inline-block;
  position: absolute;
  bottom: 30px;
  left: 50%;
  width: 40px;
  height: 60px;
  transform: translateX(-50%) rotate(90deg);
  background: url(../gfx/arrow-right_dark.svg) center center no-repeat;
  background-size: 20px 40px;
  opacity: 0.3;
  outline: none;

  &:hover, &:focus {
    animation: banner-group__scroll-down 600ms ease infinite alternate;
  }
}

.page-section--dark-theme {
  .banner-group__scroll-down {
    background: url(../gfx/arrow-right_white.svg) center center no-repeat;
  }
}

// Individual banner default theme.
.banner__content {
  h2, h3, h4 {
    font-family: $font-family-sans-serif;
    font-weight: 300;
  }

  p {
    @media (min-width:$screen-md) {
      font-size: 18px;
    }
  }

  ul, ol {
    @media (min-width:$screen-md) {
      font-size: 18px;
    }
  }
}

// Indiviadual banner dark theme.
.page-section--dark-theme {
  .banner__content {

    h1, h2, h3, h4, h5, h6 {
      color: #fff;
      text-shadow: .02em .02em 2px rgba(0,0,0, .5);
    }

    p, form {
      color: #fff;
    }

    ul, ol {
      color: #fff;

      li:before {
        color: $acro-orange !important;
      }
    }
  }
}

// Slide classes.
.banner--hero {
  h1, .h1 {
    text-shadow: 0.05em 0.08em 1px rgba(38, 40, 47, .9);
    font-size: 100px;
    font-weight: 600;
    letter-spacing: 0.03em;
    font-style: italic;

    @media (max-width: $screen-md-max) {
      font-size: 70px;
    }
    @media (max-width: $screen-sm-max) {
      font-size: 55px;
    }
    @media (max-width: $screen-xs-max) {
      font-size: 40px;
    }
  }
}

.banner.banner--content-full-width {
  .banner__content {
    max-width: 100%;
  }
}

.banner.banner--no-padding {
  .banner__content {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.banner.banner--no-padding-top {
  .banner__content {
    padding-top: 0;
  }
}

.banner.banner--no-padding-bottom {
  .banner__content {
    padding-bottom: 0;
  }
}

.banner.banner--half-padding {
  .banner__content {
    @media (min-width: $screen-md) {
      padding-top: 40px;
      padding-bottom: 50px;
    }
  }
}

.banner.banner--half-padding-top {
  .banner__content {
    @media (min-width: $screen-md) {
      padding-top: 40px;
    }
  }
}

.banner.banner--half-padding-bottom {
  .banner__content {
    @media (min-width: $screen-md) {
      padding-bottom: 50px;
    }
  }
}

// Adjust banner and image styles when banner is in a modal.
.modal {
  .banner-wrapper {
    border-bottom: none;
  }

  .banner__content img {
    max-height: 85vh;
    width: auto !important;
  }
}
