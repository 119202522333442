/*------------------------------
*	 About
------------------------------*/

.about-page-top-content {
  position: relative;
  padding: 0;

  @media (min-width: $screen-md-min) {
    padding: 0 605px 0 0;
  }
}

.about-page-diagram {
  position: relative;
  top: 0;
  right: 0;
  margin: 36px 0;
  padding: 0;
  text-align: center;
  z-index: 2;

  @media (min-width: $screen-md-min) {
    position: absolute;
    right: -15px;
    margin: 0;
    text-align: left;
  }
  @media (min-width: $screen-lg-min) {
    top: -20px;
  }
  img {
    width: auto;
    max-width: 100%;
    height: auto;
  }
}

.about-page-quotes {
  margin: 0 auto;
  padding: 0;
  max-width: 1467px;
  font-size: 0;
  text-align: center;
}
.about-page-quotes-left {
  position: relative;
  right: 0;
  display: block;
  margin: 0 auto;
  width: 100%;
  vertical-align: top;

  @media (min-width: $screen-md-min) {
    display: inline-block;
    right: -4px;
    margin: 0;
    width: 50%;
  }
}
.about-page-quotes-right {
  position: relative;
  left: 0;
  display: block;
  margin: 0 auto;
  width: 100%;
  vertical-align: top;

  @media (min-width: $screen-md-min) {
    display: inline-block;
    left: -4px;
    margin: 0;
    width: 50%;
  }
}

// Who We Work With.
.page-section--who-we-work-with {
  .banner-wrapper .banner .banner__content {
    padding-top: 0;
    padding-bottom: 30px;
  }
}
.who-we-work-with-slide {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0 40px;

  > div:first-child {
    width: 100%;
    text-align: center;

    @media (min-width: $screen-md-min) {
      width: 40%;
    }
  }
  > div:last-child {
    padding: 0;
    width: 100%;
    text-align: center;

    @media (min-width: $screen-md-min) {
      padding: 30px 0 0 60px;
      width: 60%;
      text-align: left;
    }
    .btn {
      font-weight: bold;
    }
  }
}

// Our Values - Images.
.about-page-our-values-images {
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  text-align: center;
  font-size: 0;

  @media (min-width: $screen-md-min) {
    max-width: none;
  }
  img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
  }
}
.about-page-our-values-images__row1 {
  display: inline-block;
}
.about-page-our-values-images__row2 {
  display: none;

  @media (min-width: $screen-sm-min) {
    display: inline-block;
  }
}
.about-page-our-values-images__row3 {
  display: none;

  @media (min-width: $screen-md-min) {
    display: inline-block;
  }
}

// Meet Our Experts.
.page-section--about-meet-our-experts {
  .button-cta-link.btn--primary {
    background: $acro-gray-dark;
    border-color: $acro-gray-dark;

    &:hover,
    &:focus {
      background: $acro-orange;
      border-color: $acro-orange;
    }
  }
}
