@import '_variables.scss';

// CUSTOM FUNCTIONS
@function em($px, $base: $font-size-base) {
	@return ($px / $base) * 1em;
}

// Custom 5 column layout
.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xs-15 {
  width: 20%;
  float: left;
}
@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 992px) {
  .col-md-15 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
}

// Margins and Padding
.marg-both {
	margin-top: 50px;
	margin-bottom: 50px;

	@media (max-width: $screen-md-max) { margin-top: 40px; margin-bottom: 40px; }
	@media (max-width: $screen-sm-max) { margin-top: 35px; margin-bottom: 35px; }
	@media (max-width: $screen-xs-max) { margin-top: 30px; margin-bottom: 30px; }
	}

.pad-both {
	padding-top: 50px;
	padding-bottom: 50px;

	@media (max-width: $screen-md-max) { padding-top: 40px; padding-bottom: 40px; }
	@media (max-width: $screen-sm-max) { padding-top: 35px; padding-bottom: 35px; }
	@media (max-width: $screen-xs-max) { padding-top: 30px; padding-bottom: 30px; }
	}

.pad-top {
	padding-top: 50px;

	@media (max-width: $screen-md-max) { padding-top: 40px; }
	@media (max-width: $screen-sm-max) { padding-top: 35px; }
	@media (max-width: $screen-xs-max) { padding-top: 30px; }
	}

.pad-top-small {
  padding-top: 25px;

  @media (max-width: $screen-md-max) { padding-top: 20px; }
  @media (max-width: $screen-sm-max) { padding-top: 17px; }
  @media (max-width: $screen-xs-max) { padding-top: 15px; }
  }


.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] { margin-left: 0 !important; }

// Set the breakpoints so grids adjust at tablet size
$grid-float-breakpoint:     $screen-sm-max;

// Media query variables.
$tablet: "(min-width: #{$screen-sm-min})";
$normal: "(min-width: #{$screen-md-min})";
$wide: "(min-width: #{$screen-lg-min})";

html body.admin-menu {
  margin-top: 28px !important;
}

// Drop shadows.
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
     -moz-box-shadow: $shadow;
          box-shadow: $shadow;
}

.modal-body { overflow: scroll !important; }

html {
  &.overlay-open .navbar-fixed-top {
    z-index: 400;
  }
  &.js {
    // Collapsible fieldsets.
    fieldset.collapsed {
     height: auto;
    }
    // Throbbers.
    input.form-autocomplete {
      background-image: none;
    }
  }
}

body {
  // Fix horizontal scrolling on iOS devices.
  // http://drupal.org/node/1870076
  position: relative;
  &.admin-expanded.admin-vertical.admin-nw .navbar,
  &.admin-expanded.admin-vertical.admin-sw .navbar {
    margin-left: 260px;
  }
  // Add padding to body if various toolbar or navbars are fixed on top.
  &.navbar-is-fixed-top {
    padding-top: 64px !important;
  }
  &.navbar-is-fixed-bottom {
    padding-bottom: 64px !important;
  }
  &.toolbar {
    padding-top: 30px !important;
    .navbar-fixed-top {
      top: 30px;
    }
    &.navbar-is-fixed-top {
      padding-top: 94px !important;
    }
  }
  &.toolbar-drawer {
    padding-top: 64px !important;
    .navbar-fixed-top {
      top: 64px;
    }
    &.navbar-is-fixed-top {
      padding-top: 128px !important;
    }
  }
  // Admin_menu toolbar.
  &.admin-menu {
    .navbar-fixed-top {
      top: 29px;
    }
    &.navbar-is-fixed-top {
      padding-top: 93px !important;
    }
  }
  div#admin-toolbar {
    z-index: 1600;
  }
  // Override box-shadow styles on all "admin" menus.
  #toolbar, #admin-menu, #admin-toolbar {
    box-shadow: none;
  }
  // Override #admin-menu style.
  #admin-menu {
    margin: 0;
    padding: 0;
    position: fixed;
    z-index: 1600;
    .dropdown li {
      line-height: normal;
    }
  }
}

// Default navbar.
.navbar.container {
  @media #{$tablet} {
    max-width: (($container-sm - 30px));
  }
  @media #{$normal} {
    max-width: (($container-md - 30px));
  }
  @media #{$wide} {
    max-width: (($container-lg - 30px));
  }
  margin-top: 20px;
  > .container {
    margin: 0;
    padding: 0;
    width: auto;
  }
}

// Adjust z-index of core components.
#overlay-container,
.overlay-modal-background,
.overlay-element {
  z-index: 1500;
}
#toolbar {
  z-index: 1600;
}
// Adjust z-index of bootstrap modals
.modal {
  z-index: 1620;
}
.modal-dialog {
  z-index: 1630;
}
.modal-backdrop {
  z-index: 1610;
}
.footer {
  margin-top: 45px;
  padding-top: 35px;
  padding-bottom: 36px;
  border-top: 1px solid #E5E5E5;
}

// Element invisible fix
.element-invisible {
  margin: 0;
  padding: 0;
  width: 1px;
}

// Branding.
.navbar .logo {
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  @media #{$tablet} {
    margin-right: 0;
    padding-left: 0;
  }
}

// Navigation.
ul.secondary {
  float: left;
  @media #{$tablet} {
    float: right;
  }
}

// Page header.
.page-header {
  margin-top: 0;
}

// Blocks
.block {
  &:first-child h2.block-title {
    margin-top: 0;
  }
}

// Paragraphs.
p:last-child {
  //margin-bottom: 0;
}

// Help region.
.region-help {
  > .glyphicon {
    //font-size: @font-size-large;
    float: left;
    margin: -0.05em 0.5em 0 0;
  }
  .block {
    overflow: hidden;
  }
}

// Search form.
form#search-block-form {
  margin: 0;
}
.navbar #block-search-form {
  float: none;
  margin: 5px 0 5px 5px;
  @media #{$normal} {
    float: right;
  }
}
.navbar-search .control-group {
  margin-bottom:0px;
}

// Action Links
ul.action-links {
  //margin: @padding-base-horizontal 0;
  li {
    display: inline;
    margin: 0;
    //padding: 0 @padding-base-vertical 0 0;
  }
  .glyphicon {
    padding-right: 0.5em;
  }
}

// Form elements.
input, textarea, select, .uneditable-input {
  max-width: 100%;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
}
input.error {
  //color: @state-danger-text;
  //border-color: @state-danger-border;
}
fieldset legend.panel-heading {
  float: left;
  line-height: 1em;
  margin: 0;
}
fieldset .panel-body {
  clear: both;
}
fieldset .panel-heading a.panel-title {
  color: inherit;
  display: block;
  margin: -10px -15px;
  padding: 10px 15px;
  &:hover {
    text-decoration: none;
  }
}
.form-actions{
  clear: both;
}
.resizable-textarea textarea {
  // border-radius: @border-radius-base @border-radius-base 0 0;
}
.radio, .checkbox {
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.help-block, .control-group .help-inline {
  //color: @gray-light;
  font-size: 12px;
  margin: 5px 0 10px;
  padding: 0;
}
.panel-heading {
  display: block;
}
a.tabledrag-handle .handle {
  height: auto;
  width: auto;
}


// Error containers.
.error {
  //color: @state-danger-text;
}
div.error {
  background-image: none;
}
div.error,
table tr.error {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  border-width: 2px;
}
.control-group.error {
  background: none;
  label, .control-label {
    color: $state-danger-text;
    font-weight: 600;
  }
  input, textarea, select, .uneditable-input {
    color: $input-color;
    border: 1px solid $input-border;
  }
  .help-block, .help-inline {
    color: $text-color;
  }
}

// Lists
.list-inline {
  > li.first {
    padding-left: 0;
  }
}
.nav-tabs {
  margin-bottom: 10px;
}
ul li.collapsed,
ul li.expanded,
ul li.leaf {
  list-style: none;
  list-style-image: none;
}
.tabs--secondary {
  margin: 0 0 10px;
}

// Submitted
.submitted {
  margin-bottom: 1em;
  font-style: italic;
  font-weight: normal;
  color: #777;
}

// Password strength indicator.
.password-strength {
  width: 17em;
  float: right;  /* LTR */
  margin-top: 1.4em;
}
.password-strength-title {
  display: inline;
}
.password-strength-text {
  float: right; /* LTR */
  font-weight: bold;
}
.password-indicator {
  background-color: darken($input-color-placeholder, 2%);
  height: 0.3em;
  width: 100%;
  div {
    height: 100%;
    width: 0;
    background-color: $input-color-placeholder;
  }
}
input.password-confirm,
input.password-field {
  width: 16em;
  margin-bottom: 0.4em;
}
div.password-confirm {
  float: right;  /* LTR */
  margin-top: 1.5em;
  visibility: hidden;
  width: 17em;
}
div.form-item div.password-suggestions {
  padding: 0.2em 0.5em;
  margin: 0.7em 0;
  width: 38.5em;
  border: 1px solid #B4B4B4;
}
div.password-suggestions ul {
  margin-bottom: 0;
}
.confirm-parent,
.password-parent {
  clear: left; /* LTR */
  margin: 0;
  width: 36.3em;
}

// Progress bar.
.progress-wrapper .progress {
  margin-bottom: 10px;
}

// Views AJAX pager.
.pagination ul > li > a {
  &.progress-disabled {
    float: left;
  }
}

// Autocomplete and throbber
.form-autocomplete {
  .glyphicon {
    color: $gray-light;
    font-size: 120%;
    &.glyphicon-spin {
      color: $brand-primary;
    }
  }
  .input-group-addon {
    background-color: rgb(255, 255, 255);
  }
}

// AJAX "Throbber".
.ajax-progress {
  .glyphicon {
    font-size: 90%;
    margin: 0 -.25em 0 0.5em;
  }
}

.glyphicon-spin {
  display: inline-block;
  -moz-animation: spin 1s infinite linear;
  -o-animation: spin 1s infinite linear;
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}
a .glyphicon-spin {
  display: inline-block;
  text-decoration: none;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.glyphicon-refresh {
  -webkit-transform-origin: 50% 45%;
  -moz-transform-origin: 50% 45%;
  -ms-transform-origin: 50% 45%;
  -o-transform-origin: 50% 45%;
  transform-origin: 50% 45%;
}

/**
 * Missing Bootstrap 2 tab styling.
 * @see http://stackoverflow.com/questions/18432577/stacked-tabs-in-bootstrap-3
 * @see http://bootply.com/74926
 */
.tabbable {
  margin-bottom: 20px;
}
.tabs-below, .tabs-left, .tabs-right {
  > .nav-tabs {
    border-bottom: 0;
    .summary {
      color: $nav-disabled-link-color;
      font-size: $font-size-small;
    }
  }
}
.tab-pane,
.tab-pane > .panel-heading {
  display: none;
}
.tab-content > .active {
  display: block;
}

// Below.
.tabs-below {
  > .nav-tabs {
    border-top: 1px solid $nav-tabs-border-color;
    > li {
      margin-top: -1px;
      margin-bottom: 0;
      > a {
        border-radius: 0 0 $border-radius-base $border-radius-base;
        &:hover,
        &:focus {
          border-top-color: $nav-tabs-border-color;
          border-bottom-color: transparent;
        }
      }
    }
    > .active {
      > a,
      > a:hover,
      > a:focus {
        border-color: transparent $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-border-color;
      }
    }
  }
}

// Left and right tabs.
.tabs-left,
.tabs-right {
  > .nav-tabs {
    padding-bottom: 20px;
    width: 220px;
    > li {
      float: none;
      &:focus {
        outline: 0;
      }
      > a {
        margin-right: 0;
        margin-bottom: 3px;
        &:focus {
          outline: 0;
        }
      }
    }
  }
  > .tab-content {
    border-radius: 0 $border-radius-base $border-radius-base $border-radius-base;
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
    border: 1px solid $nav-tabs-border-color;
    overflow: hidden;
    padding: 10px 15px;
  }
}

// Left tabs.
.tabs-left {
  > .nav-tabs {
    float: left;
    margin-right: -1px;
    > li > a {
      border-radius: $border-radius-base 0 0 $border-radius-base;
      &:hover,
      &:focus {
        border-color: $nav-tabs-link-hover-border-color $nav-tabs-border-color $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color;
      }
    }
    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
      border-color: $nav-tabs-border-color transparent $nav-tabs-border-color $nav-tabs-border-color;
      box-shadow: -1px 1px 1px rgba(0,0,0,.05);
    }
  }
}

// Right tabs.
.tabs-right {
  > .nav-tabs {
    float: right;
    margin-left: -1px;
    > li > a {
      border-radius: 0 $border-radius-base $border-radius-base 0;
      &:hover,
      &:focus {
        border-color: $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color $nav-tabs-border-color;
        box-shadow: 1px 1px 1px rgba(0,0,0,.05);
      }
    }
    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
      border-color: $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-border-color transparent;
    }
  }
}

// Checkbox cell fix.
th.checkbox,
td.checkbox,
th.radio,
td.radio {
  display: table-cell;
}

// Views UI fixes.
.views-display-settings {
  .label {
    font-size: 100%;
    color:$gray;
  }
  .footer {
    padding:0;
    margin:4px 0 0 0;
  }
}

// Radio and checkbox in table fixes
table {
  .radio input[type="radio"],
  .checkbox input[type="checkbox"] {
    max-width: inherit;
  }
}

// Exposed filters
.form-horizontal .form-group label {
  position: relative;
  min-height: 1px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px;
  padding-left:  ($grid-gutter-width / 2);
  padding-right: ($grid-gutter-width / 2);
  text-align: right;

  @media (min-width: $screen-sm-min) {
    float: left;
    width: percentage((2 / $grid-columns));
  }
}

// Treat all links inside alert as .alert-link
.alert a {
  font-weight: $alert-link-font-weight;
}
.alert-success a {
  color: darken($alert-success-text, 10%);
}
.alert-info a {
  color: darken($alert-info-text, 10%);
}
.alert-warning a {
  color: darken($alert-warning-text, 10%);
}
.alert-danger a {
  color: darken($alert-danger-text, 10%);
}

//a {
//  outline: none !important;
//
//  &:focus {
//    outline: none !important;
//  }
//}

// Tooltips.
.tooltip {
  font-size: 14px;
  max-width: 350px;

  &.left {
    margin-right: 5px;
  }
  &.right {
    margin-left: 5px;
  }
}

.tooltip-inner {
  max-width: 275px;
  padding: 8px 8px;
}
