/**
 * Page - Our Partners.
 *
 */

// Partners information.
.page-partners {
  .page-section--partners {
    padding-top: 60px;

    .page-section__content-wrapper > .paragraphs-items > .field > .field-items > .field-item {
      border-bottom: 1px solid $gray-light;
      margin-bottom: 60px;
      padding-bottom: 30px;

      &:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 20px;
      }
    }

    .text-section-secondary {
      table {
        display: none;
        margin-bottom: 0;

        @media (min-width: $screen-md) {
          display: table;
          height: 285px;
        }
        @media (min-width: $screen-lg) {
          display: table;
          height: 330px;
        }
      }
    }
  }
}
