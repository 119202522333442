/*------------------------------
*	 Loading Spinner
------------------------------*/

.loader-wrapper {
  text-align: center;
}

.loader {
  position: relative;
  display: inline-block;
  margin: 60px auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border-top: 10px solid rgba($gray-light, 0.5);
  border-right: 10px solid rgba($gray-light, 0.5);
  border-bottom: 10px solid rgba($gray-light, 0.5);
  border-left: 10px solid $acro-gray-mid;
  font-size: 10px;
  text-indent: -9999em;
  transform: translateZ(0);
  animation: loader 1.25s infinite linear;
  box-sizing: content-box;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    height: 100px;
    width: 100px;
    background: url(../gfx/logo_icon.svg) 50% 42% no-repeat;
    background-size: 56px 42px;
    animation: loaderIcon 1.25s infinite linear;
  }

  &:after {
    border-radius: 50%;
    width: 100px;
    height: 100px;
  }
}

@-webkit-keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loaderIcon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes loaderIcon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

/*------------------------------
*	 Modals
------------------------------*/

.modal {
  color: $gray;
  font-family: $font-family-sans-serif;
  font-size: 16px;
  background: rgba(0,0,0,0.5);

  .modal-dialog {
    margin: 0 auto;
    padding: 15px;

    &.modal-dialog-centered {
      display: flex;
      align-items: center;
      min-height: calc(100% - (30px * 2));
    }
  }

  .modal-content {
    width: 100%;
  }

  .modal-body {
    padding: 40px 50px;
    overflow: visible !important;

    @media(max-width: $screen-xs-max) {
      padding: 40px 20px 20px;
    }
  }

  .modal-intro {
    margin-bottom: 20px;
    font-size: 18px;
  }

  h2 {
    font-size: 30px;
    line-height: 1.2;
    margin: 0 0 15px 0;
  }
}

// Modal close buttons.
.modal {
  button.close {
    position: relative;
    z-index: 1631;
    font-size: 40px;
    margin: 5px 15px;

    // Hide fullscreen modal button by default.
    &.close--fullscreen {
      display: none;
      visibility: hidden;
    }
  }

  // Fullscreen modals.
  &.modal--modal-full,
  &.modal--modal-full-alt {
    button.close {
      // Hide default close button.
      &.close--content {
        display: none;
        visibility: hidden;
      }

      // Show fullscreen close button.
      &.close--fullscreen {
        display: block;
        visibility: visible;
      }
    }
  }
}

// Modal full screen.
.modal.modal--modal-full {
  background: rgba(#fff, 1);

  .modal-dialog {
    width: 100%;
    max-width: 1600px;
  }

  .modal-content {
    border: none;
    box-shadow: none;
  }
}

// Modal full screen alternate.
.modal.modal--modal-full-alt {
  background: rgba(0, 0, 0, 0.8);

  button.close {
    color: #fff;
    text-shadow: 0 1px 0 #000;
    opacity: 1;

    &:hover, &:focus {
      opacity: 0.7;
    }
  }

  .modal-dialog {
    width: 100%;
    max-width: 1600px;
  }

  .modal-content {
    border: none;
    background-color: transparent;
    box-shadow: none;
  }

  .modal-body {
    padding: 0;
  }
}
