/**
 * Paragraph - Accordion.
 */

// Default theme (on white background).
.accordion-item {
  margin: 0 0 -1px;
  background-color: #fff;
  border: 1px solid $gray-lighter;
}

.accordion__title {
  margin: 0;
  font-family: $font-family-sans-serif;
  color: $acro-orange;

  a {
    position: relative;
    display: block;
    padding: 15px 50px 15px 15px;
    font-size: 18px;
    color: $acro-orange;

    &:hover {
      color: $link-hover-color;
    }

    &:after {
      @extend %fa-icon;
      @extend .far;
      content: fa-content($fa-var-angle-down);
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 22px;
      color: $acro-gray-dark;
    }

    &.collapsed:after {
      content: fa-content($fa-var-angle-right);
    }

    @media (min-width:$screen-sm) {
      padding: 22px 75px 22px 28px;
      font-size: 20px;

      &:after {
        top: 20px;
        right: 30px;
        font-size: 26px;
      }
    }
  }
}

.accordion__content {
  margin: 0;
  padding: 0 15px 10px;

  @media (min-width:$screen-sm) {
    padding: 0 28px 10px;
  }
}

// On light background (except white).
.page-section--light-theme:not(.bg-color--white) {
  .accordion-item {
    border: none;
    margin: 0 0 15px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    transition: margin .15s ease,box-shadow .15s ease;

    &:hover, &:focus {
      box-shadow: 0 6px 10px 0 rgba(0,0,0,0.3);
    }
  }
}

// On dark background.
.page-section--dark-theme {
  .accordion-item {
    background-color: $acro-gray-dark;
    border: 1px solid $gray;
  }

  .accordion__title {
    a {
      background: #000;

      &.collapsed {
        background: none;
      }

      &:hover,
      &:focus {
        color: $acro-orange;
      }

      &:hover {
        background: #000;
      }

      &:after {
        color: #fff;
      }
    }
  }

  .accordion__content {
    background: #000;
  }
}
