/**
 *	General Site Styles.
**/

html {
  background: #fff;

  // CK Editor overrides.
  &.cke_panel_container {
    background: #fff;
  }
}

html.js,
html.no-js {
  background: $acro-gray-dark;
}

body {
  height: 100%;
  background: $acro-gray-dark;
  color: $acro-gray;
  font-size: 16px;

  // CK Editor overrides.
  &.cke_editable,
  &.cke_ltr {
    padding: 20px;
    background: #fff;
  }

  ul.cke_panel_list {
    li.cke_panel_listItem {
      background: none;
    }
  }
}

html.maintenance-page,
body.maintenance-page {
  height: 100%;
  background: #fff;
}

div.maintenance-page img {
  padding-top: 50px;
}

#site-wrapper {
  // Note: Top padding altered via jQuery based on header height.
  min-width: 320px;
  padding-top: 139px;
  background: #fff;
  overflow: hidden;

  &.site-wrapper--with-top-bar {
    padding-top: 177px;
  }

  @media (max-width: $screen-sm-max) {
    padding-top: 61px;
  }
}

// Alter site wrapper for landing pages since header height is different.
.page-use-minimal-nav #site-wrapper {
  padding-top: 61px;

  &.site-wrapper--with-top-bar {
    padding-top: 99px;
  }
}

.clear-both {
  clear: both;
}

.clear-break {
  clear: both;
  font-size: 0;
  line-height: 0;
  height: 0.001%;
}

textarea {
  resize: none;
}

p {
  margin: 0 0 20px 0;
  padding: 0;
}

// Lists.

ul {
  margin: 5px 0 10px 15px;
  padding: 0 0 20px 0;
  list-style: none;

  ul, ol {
    padding: 0;
  }

  li {
    position: relative;
    margin: 0;
    padding: 0 0 10px 15px;
    background: url(../gfx/bullet_orange.png) 0 6px no-repeat;
    list-style: none;
    list-style-image: none;
    list-style-type: none;

    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

ol {
  margin: 5px 0 10px 15px;
  padding: 0 0 20px 0;

  ul, ol {
    padding: 0;
  }

  li {
    position: relative;
    margin: 0 0 0 13px;
    padding: 0 0 10px 5px;
    list-style-type: decimal;
    background: none;

    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

// List modifier - 2 col.
ul.ul--2-col,
ol.ol--2-col {
  display: flex;
  flex-wrap: wrap;

  > li {
    flex: 1 0 100%;
  }

  @media (min-width: $screen-sm) {
    > li {
      flex: 1 0 50%;
      padding-right: 20px;
    }
  }
}

// List modifier - 3 col.
ul.ul--3-col,
ol.ol--3-col {
  display: flex;
  flex-wrap: wrap;

  > li {
    flex: 1 0 100%;
  }

  @media (min-width: $screen-md) {
    > li {
      flex: 1 0 33%;
      padding-right: 20px;
    }
  }
}

// List modifier - Checkmarks within circle.
ul.ul--checkmark-circle {
  padding-top: 10px;
  padding-bottom: 0;

  @media (max-width: $screen-xs-max) {
    margin-left: 0;
  }

  > li {
    padding-left: 38px;
    padding-bottom: 30px;
    background: none;

    @media (min-width: $screen-sm) {
      padding-left: 45px;
    }

    &:before {
      position: absolute;
      top: -3px !important;
      left: 0;
      height: 26px;
      width: 26px;
      content: '' !important;
      background: url(../gfx/icons/icon-checkmark-circle.svg) center no-repeat;
    }
  }
}

// List modifier - Checkmarks within square.
ul.ul--checkmark-square {
  margin-left: 0;
  margin-bottom: 0;

  > li {
    padding-left: 45px;
    padding-bottom: 20px;
    background: none;

    &:before {
      position: absolute;
      top: 2px !important;
      height: 30px;
      width: 30px;
      border: 2px solid $acro-gray-mid;
      border-radius: 3px;
      content: '✓' !important;
      font-size: 28px !important;
      line-height: .9 !important;
      color: $acro-green !important;
      text-align: center;
    }

    &.li--unchecked {
      &:before {
        content: '' !important;
      }
    }
  }
}

// List modifier - Arrow within circle.
ul.ul--arrow-circle {
  padding-top: 10px;
  padding-bottom: 0;

  @media (max-width: $screen-xs-max) {
    margin-left: 0;
  }

  > li {
    padding-left: 38px;
    padding-bottom: 20px;
    background: none;

    @media (min-width: $screen-sm) {
      padding-left: 45px;
    }

    &:before {
      @extend %fa-icon;
      @extend .far;
      content: fa-content($fa-var-chevron-circle-right);
      position: absolute;
      top: -3px !important;
      left: 0;
      height: 26px;
      width: 26px;
      font-size: 26px !important;
    }
  }
}

// NOTE: See paragraphs/_icon-list.scss for the Icon List paragraph styling.

// Links.
a {
  color: $link-color;
  text-decoration: none;
  outline: none;
  word-wrap: break-word;
  transition: color, .2s linear, background .2s linear, opacity linear .15s;
}

a:hover, a:focus {
  color: $link-hover-color;
  text-decoration: none;
  outline: none;
}

a:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

// Shadow Link Box.
// Wrap any element in a link with this class to use this effect.
a.shadow-link-box {
  display: block;
  padding: 28px 20px;
  color: $text-color;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2);
  transition: color .15s ease, box-shadow .15s ease;

  .fake-link {
    color: $link-color;
  }

  &:hover, &:focus {
    box-shadow: 0 6px 10px 0 rgba(0,0,0,0.3);

    .fake-link {
      color: $link-hover-color;
    }
  }
}

// Alignment.
.align-left {
  float: left;
  margin: 0 20px 0 0;
}

.align-right {
  float: right;
  margin: 0 0 0 20px;
}

.align-center {
  margin: 0 auto;
  display: block;
}

// Headings.
h1, h1#page-title {
  margin: 10px 0 20px 0;
  padding: 0;
  font: normal 65px/1.2em $font-family-serif;
  color: $acro-orange;

  &.h1--small {
    font-size: 45px;
  }

  @media (max-width: $screen-sm-max) {
    font-size: 45px;
  }
  @media (max-width: $screen-xs-max) {
    font-size: 35px;

    &.h1--small {
      font-size: 35px;
    }
  }
}

h1#page-title {
  margin: 0 0 50px 0;
}

// Page - Paragraph template page title.
.node-type-page-paragraphs h1#page-title {
  margin: 40px 0;
}

// To make another heading appear like an H1, but not be an H1.
.h1 {
  margin-top: 10px !important;
  margin-bottom: 20px !important;
  font: normal 65px/1.2em $font-family-serif !important;
  color: $acro-orange;

  &.h1--small,
  &.h1--sm {
    font-size: 45px !important;
  }

  @media (max-width: $screen-sm-max) {
    font-size: 45px !important;
  }
  @media (max-width: $screen-xs-max) {
    font-size: 35px !important;

    &.h1--small {
      font-size: 35px !important;
    }
  }
}

h2 {
  margin: 10px 0 20px 0;
  padding: 0;
  font: normal 37px/1.2em $font-family-serif;
  color: $acro-gray-dark;

  @media (max-width: $screen-xs-max) {
    font-size: 25px;
  }
}

// To make another heading appear like an H2, but not be an H2.
.h2 {
  margin-top: 10px !important;
  margin-bottom: 20px !important;
  padding: 0;
  font: normal 37px/1.2em $font-family-serif !important;
  color: $acro-gray-dark;

  @media (max-width: $screen-xs-max) {
    font-size: 25px !important;
  }
}

h3 {
  margin: 5px 0 20px 0;
  padding: 0;
  font: normal 30px/1.2em $font-family-serif;
  color: $acro-orange;

  @media (max-width: $screen-xs-max) {
    font-size: 23px;
  }
}

// To make another heading appear like an H3, but not be an H3.
.h3 {
  margin-top: 5px !important;
  margin-bottom: 20px !important;
  padding: 0;
  font: normal 30px/1.2em $font-family-serif !important;
  color: $acro-orange;

  @media (max-width: $screen-xs-max) {
    font-size: 23px !important;
  }
}

h4 {
  margin: 5px 0 20px 0;
  padding: 0;
  font: normal 25px/1.2em $font-family-serif;
  color: $acro-gray-dark;

  @media (max-width: $screen-xs-max) {
    font-size: 20px;
  }
}

// To make another heading appear like an H4, but not be an H4.
.h4 {
  margin-top: 5px !important;
  margin-bottom: 20px !important;
  padding: 0;
  font: normal 25px/1.2em $font-family-serif !important;
  color: $acro-gray-dark;

  @media (max-width: $screen-xs-max) {
    font-size: 20px !important;
  }
}

h5 {
  margin: 0 0 20px 0;
  padding: 0;
  font: 500 20px/1.2em $font-family-sans-serif;
  color: $acro-orange;

  @media (max-width: $screen-xs-max) {
    font-size: 18px;
  }
}

// To make another heading appear like an H5, but not be an H5.
.h5 {
  margin-top: 0 !important;
  margin-bottom: 20px !important;
  padding: 0;
  font: 500 20px/1.2em $font-family-sans-serif !important;
  color: $acro-orange;

  @media (min-width: $screen-sm) {
    font-size: 18px !important;
  }
}

h6 {
  margin: 0 0 20px 0;
  padding: 0;
  font: 500 16px/1.2em $font-family-sans-serif;
  color: $acro-gray-dark;
}

.display-text {
  margin: 0 0 0 0;
  padding: 0;
  font: 200 50px/1.12em $font-family-sans-serif;
  color: $acro-gray-dark;

  @media (min-width: $screen-md) {
    font-size: 85px;
  }
  @media (min-width: $screen-lg) {
    font-size: 98px;
  }
}

.display-text-small,
.display-text--sm,
.display-text--small {
  margin: 0 0 0 0;
  padding: 0;
  font: 200 40px/1.12em $font-family-sans-serif;
  color: $acro-gray-dark;

  @media (min-width: $screen-md) {
    font-size: 55px;
  }
  @media (min-width: $screen-lg) {
    font-size: 69px;
  }
}

.eyebrow-text,
.eyebrow {
  margin: 0;
  padding: 0;
  font: 500 20px/1.2em $font-family-sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  color: $acro-gray-dark;
}
.lead {
  font-size: 19px;
  font-weight: 300;

  @media (min-width: $screen-sm) {
    font-size: 21px;
  }
}

.lead--large,
.lead--lg {
  font-size: 21px;
  font-weight: 300;

  @media (min-width: $screen-sm) {
    font-size: 25px;
  }
}

span.prov {
  text-transform: uppercase;
}

.marker,
.form-required {
  color: $acro-orange;
}

.region-content .field-name-field-page-head-background-image {
  display: none;
}

#cboxWrapper {
  background: none !important;
}

// Buttons.
.btn {
  border-color: transparent;
  font-size: 16px;
  transition: all 0.15s linear;
  white-space: normal;
}

.btn-primary {
  background-color: $acro-orange;

  &:hover,
  &:focus {
    background-color: #141414;
    border-color: transparent;
  }
}

.form-submit {
  position: relative;
  display: inline-block;
  margin: 5px 0 15px 0;
  padding: 11px 19px;
  font: bold 18px/18px $font-family-sans-serif;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  border: none;
  background: $acro-orange;
  box-sizing: content-box;
  border-radius: 5px;
  behavior: url(/pie/PIE.htc);

  &:hover, &:focus {
    color: #fff;
    background: #000;
    transition: color linear .15s, background-color linear .15s;
  }
}

.page-search .form-submit {
  padding: 8px 19px;
  border: 1px solid transparent;
  font: bold 15px/15px $font-family-sans-serif;
}

// Images.
img {
  max-width: 100%;
  width: auto;
  height: auto !important;
}

// Transition an image link from grayscale to color.
.grayscale-to-color {
  opacity: .8;
  filter: grayscale(1);
  transition: filter .4s ease, opacity .4s ease;

  &:hover,
  &:focus {
    opacity: 1;
    filter: grayscale(0);
  }
}

// Tables.
table {
  margin: 0 0 27px 0;
  padding: 0;
  border: none;
}

table thead,
table tbody {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
}

table tr {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
}

table tr.even,
table tr.odd {
  background-color: transparent;
  border-bottom: none;
}

table tr th,
table tr td {
  padding: 13px 11px;
  margin: 0;
  text-align: center;
}

table tr th {
  font-weight: bold;
}

#user-content blockquote,
.content blockquote,
.cke_editable blockquote {
  clear: both;
  display: block;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 30px;
  font-family: $font-family-serif;
  font-size: 20px;
  font-weight: 300;
  color: $acro-smoke;
  border-left: 1px solid $acro-anchor;

  @media (max-width: $screen-xs-max) {
    padding: 2px 0 2px 20px;
  }
  @media (min-width: $screen-sm) {
    font-size: 26px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}

#user-content .author,
.content .author,
.cke_editable .author {
  font: bold 12px/18px Arial, Helvetica, sans-serif;
  text-align: right;
  color: $acro-orange;
}

/*------------------------------
*	 Standard Page Styles
------------------------------*/

#page-body-cont {
  clear: both;
  margin: 0 auto;
  padding: 60px 0 0 0;
  max-width: 1100px;
}
  body.front #page-body-cont { padding-top: 0; background: none; }

  #page-body-content {
    padding: 0 0 70px 0;
    min-height: 402px;
  }
  .page-about-old #page-body-content { padding-bottom: 13px; }

  @media (max-width: 1135px) {
    .page-blog #page-body-cont {
      padding: 103px 15px 0;
    }
  }

/*------------------------------
*	 Site Content
------------------------------*/

#site-main-cont {
  float: right;
  width: 73%;
}

#site-main-cont.wide {
  float: none;
  width: auto;
}

#site-main-inner-content {
  min-height: 200px;
}

#site-main-inner-content #search-form .form-submit {
  padding: 4px 24px 3px;
}

article.column h2,
article.column h2 a {
  font-size: 1.17em;
  line-height: 1.14em;
  font-weight: bold;
  text-decoration: none;
}

article.column p.article_date,
article.column p.article_author {
  font-style: italic;
  color: #999;
}

article.column p.article_quote {
  font-weight: bold;
}

article.page {
  border-bottom: 1px solid #c7c7c7;
  margin: 0 0 14px 0;
  padding: 0 0 4px 0;
}

#site-content {
  clear: both;
  margin: 0 0 20px 0;
}

#site-wrapper.page-content #site-content {
  margin-top: 10px;
}

#site-content .article_header {
  color: $gray;
  font-weight: normal;
}

#right-wrapper {
  float: left;
}

#left-image {
  float: left;
  padding: 20px 20px 0 0;
}

#right-body {
  float: right;
  max-width: 290px;
  padding: 13px 0 0 0px;
  color: black;
}

#right-body p {
  color: $gray;
  font-size: 23px;
  line-height: 32px;
  font-family: $font-family-sans-serif;
}

.acro-orange {
  color: $acro-orange;
}

.ajax-progress-throbber {
  display: none;
}

// Grow effect.
.grow {
  transition: transform 150ms ease-in-out;

  &:hover,
  &:focus {
    transform: scale(1.05, 1.05);
  }
}
