/**
 * Mixins.
 */

// Background color options.
@mixin bg-color-options {
  &.bg-color--white {
    background-color: #fff;
  }
  &.bg-color--salt {
    background-color: $acro-salt;
  }
  &.bg-color--puff {
    background-color: $acro-puff;
  }
  &.bg-color--smoke {
    background-color: $acro-smoke;
  }
  &.bg-color--anchor {
    background-color: $acro-anchor;
  }
  &.bg-color--diesel {
    background-color: $acro-diesel;
  }
  &.bg-color--cadmium {
    background-color: $acro-cadmium;
  }
  &.bg-color--lapis {
    background-color: $acro-lapis;
  }
  &.bg-color--granny {
    background-color: $acro-granny;
  }
  &.bg-color--barney {
    background-color: $acro-barney;
  }
}
