/*------------------------------
*	 Magnific Popup
------------------------------*/

.mfp-with-fade .mfp-content,
.mfp-with-fade .mfp-arrow, .mfp-with-fade.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
.mfp-with-fade.mfp-ready .mfp-content,
.mfp-with-fade.mfp-ready .mfp-arrow {
  opacity: 1;
  -webkit-backface-visibility: hidden;
}
.mfp-with-fade.mfp-ready.mfp-bg {
  opacity: 0.8;
  -webkit-backface-visibility: hidden;
}
.mfp-with-fade.mfp-removing .mfp-content,
.mfp-with-fade.mfp-removing .mfp-arrow, .mfp-with-fade.mfp-removing.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
}


/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}
