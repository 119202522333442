/*------------------------------
*	 Header
------------------------------*/

// Default.
.site-header-wrapper {
  position: fixed;
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  background: #fff;
  z-index: 100;
}

// Note: Transitions used with scroll overrides below.
.site-header {
  padding: 30px 0;
  border-bottom: 1px solid $gray-lighter;
  transition: all .1s ease-in-out;

  .site-header__left {
    float: left;
    padding: 0 0 0 30px;
    transition: all .1s ease-in-out;
  }

  .site-header__right {
    float: right;
    padding: 8px 30px 0 0;
    text-align: right;
    transition: all .1s ease-in-out;
  }

  @media(max-width: $screen-md-max) {
    .site-header__left {
      padding: 0 0 0 15px;
    }

    .site-header__right {
      padding: 8px 15px 0 0;
    }
  }
}

/*------------------------------
*	 Header override mixin.
------------------------------*/
// Mixin used for general overrides for small screen sizes OR when user has scrolled down.
// NOTE: More specific styles may be included below in each section.
@mixin headerChange {

  // Header.
  .site-header-top-bar {
    display: none;
  }

  .site-header {
    padding: 0;
    margin: 0;

    .site-header__left {
      padding: 12px 15px;
      margin: 0;
    }

    .site-header__right {
      padding: 0;
      margin: 0;
    }
  }

  // Logos.
  #site-logo {
    display: none;
  }

  #site-logo-small {
    display: inline-block;
  }

  // This is mainly used for the Careers subsite header logo title.
  .region-header-beside-logo {
    padding: 2px 0 2px 25px;
    margin: 0 0 0 25px;

    .logo-title {
      font-size: 30px;
    }

    @media (max-width: $screen-xs-max) {
      padding: 0 0 0 20px;
      margin: 0 0 0 20px;

      .logo-title {
        font-size: 25px;
      }
    }
    @media (max-width: 600px) {
      // Position this absolute in order to break out of Bootstrap columns.
      position: absolute;
      top: 17px;
      left: 157px;
      padding: 0 0 0 10px;
      margin: 0;
    }
    @media (max-width: 360px) {
      .logo-title {
        font-size: 20px;
        font-weight: 300;
      }
    }
  }

  // Primary menu.
  #pri-nav-cont ul.menu {
    li {
      display: none;
    }

    // This covers all potential contact links.
    li#li-contact,
    li#li-contact-us,
    li#li-connect {
      display: inline-block;
      margin: 0;

      a {
        border: 1px solid $brand-success;
      }

      @media (max-width: 540px) {
        display: none;
      }
    }
  }

  // Mobile menu toggle.
  @keyframes fadeIn {
    0%   {opacity: 0;}
    30%  {opacity: 0;}
    100% {opacity: 1;}
  }

  #mobile-nav-toggle {
    display: inline-block;
    animation-name: fadeIn;
    animation-duration: .75s;

    @media (max-width: $screen-md-max) {
      animation: none;
    }
  }
}

// Use the mixin above for small screen size, landing pages and class applied via jQuery.
.site-header-wrapper--narrow,
.page-use-minimal-nav .site-header-wrapper {
  @include headerChange;
}

@media(max-width: $screen-sm-max) {
  .site-header-wrapper {
    @include headerChange;
  }
}

/*------------------------------
*	 Top bar.
------------------------------*/

.site-header-top-bar {
  display: block;
  height: auto;
  background-color: $acro-gray-dark;
  color: #fff;
  padding: 8px 25px;

  // Hide at mobile to free up screen space.
  @media (max-width: $screen-xs-max) {
    display: none;
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 1.6;
  }

  a {
    color: $gray-light;
    transition: all .15s ease-in-out;

    &:hover, &:focus {
      color: #fff;
    }

    strong {
      font-weight: normal;
      color: $commerce-blue;
      transition: all .15s ease-in-out;
    }

    &:hover strong, &:focus strong {
      color: $commerce-blue-light;
    }
  }
}

/*------------------------------
*	 Header Navigation
------------------------------*/

.site-header-top-bar .region-header-navigation {
  ul.menu {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: right;

    li {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 0 20px;
      padding: 0;
      background: none;
      font-size: 14px;
      font-family: $font-family-sans-serif;
      line-height: 22px;
      letter-spacing: 1px;

      a {
        color: #fff;

        &:hover, &:focus {
          color: $acro-orange;
        }
      }

      @media screen and (max-width: $screen-sm-max) {
        margin: 0 0 0 15px;
      }
    }

    li#li-acronet a {
      &:before {
        top: -2px;
        margin: 0 6px 0 0;
        padding: 1px 0 0;
        width: 22px;
        height: 22px;
        background: #fff;
        border-radius: 50%;
        vertical-align: middle;
        color: $acro-gray-dark;
        @extend %fa-icon;
        @extend .fas;
        content: fa-content($fa-var-unlock-alt);
        line-height: 22px;
        text-align: center;
        transition: color .15s linear, background .15s linear;
      }

      &:hover, &:focus {
        &:before {
          color: #fff;
          background: $acro-orange;
        }
      }
    }
  }
}

/*------------------------------
*	 Logo
------------------------------*/

#site-logo {
  display: inline-block;
  width: 100%;
  max-width: 210px;
  opacity: 1;
  transition: opacity .2s ease-in-out;

  &:hover, &:focus {
    opacity: 0.8;
  }

  @media (max-width: $screen-sm-max) {
    max-width: 200px;
  }
}

#site-logo-small {
  display: none;
  width: 100%;
  max-width: 130px;
  opacity: 1;
  transition: opacity .2s ease-in-out;

  &:hover, &:focus {
    opacity: 0.8;
  }
}

// This is mainly used for the Careers subsite header logo title.
.region-header-beside-logo {
  display: inline-block;
  vertical-align: middle;
  padding: 5px 0 5px 45px;
  margin: 0 0 0 50px;
  border-left: 1px solid #ccc;
  color: #999;

  p:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .logo-title {
    font-family: $font-family-sans-serif;
    font-size: 60px;
    font-weight: 100;
    line-height: 1;
  }

  @media (max-width: 1400px) {
    padding: 5px 0 5px 30px;
    margin: 0 0 0 35px;

    .logo-title {
      font-size: 45px;
    }
  }

  @media (max-width: $screen-sm-max) {
    padding: 2px 0 2px 25px;
    margin: 0 0 0 25px;

    .logo-title {
      font-size: 30px;
    }
  }
  @media (max-width: $screen-xs-max) {
    padding: 0 0 0 20px;
    margin: 0 0 0 20px;

    .logo-title {
      font-size: 25px;
    }
  }
  @media (max-width: 600px) {
    // Position this absolute in order to break out of Bootstrap columns.
    position: absolute;
    top: 17px;
    left: 157px;
    padding: 0 0 0 10px;
    margin: 0;
  }
}

/*------------------------------
*	 Primary Navigation
------------------------------*/

#pri-nav-cont {
  display: inline-block;
  vertical-align: middle;

  ul.menu {
    float: right;
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;

    // First level.
    li {
      position: relative;
      float: left;
      display: block;
      margin: 0 8px 0 0;
      padding: 0;
      background: none;

      .caret {
        display: inline-block;
        width: 12px;
        height: 0;
        margin: 0;
        padding: 0;

        &:after {
          @extend %fa-icon;
          @extend .fas;
          content: fa-content($fa-var-caret-down);
          color: $acro-orange;
        }
      }
    }

    li > a {
      display: block;
      height: 60px;
      margin: 0;
      padding: 20px 17px 50px;
      font-family: $font-family-base;
      color: $acro-gray;
      text-decoration: none;
      text-transform: uppercase;
      transition: color 0.2s linear;

      @media (max-width: 1300px) {
        padding: 20px 10px 50px;
      }
      @media (max-width: $screen-md-max) {
        padding: 20px 5px 50px;
      }

      &.active,
      &.active-trail > a,
      &:hover,
      &:focus {
        color: $acro-orange;
      }

      &.nolink {
        &.active,
        &.active-trail > a,
        &:hover,
        &:focus {
          color: $acro-gray;
          cursor: default;
        }
      }
    }

    // This covers all potential contact links.
    li#li-contact,
    li#li-contact-us,
    li#li-connect {
      margin-right: 0;

      > a {
        height: 60px;
        background: $brand-success;
        border: 1px solid #fff;
        margin-left: 8px;
        padding: 20px 21px;
        color: #fff;
        font-weight: 500;
        transition: color linear .15s, background linear .15s, border linear .15s;

        @media (max-width: 1260px) {
          padding: 20px 15px;
        }

        &:hover, &:focus {
          background: darken($acro-orange, 15%);
          border: 1px solid darken($acro-orange, 15%);
        }
      }
    }

    // Hide all menu items...
    @media (max-width: $screen-md-max) {
      li {
        display: none;
      }
    }

    // ...except make contact link visible longer.
    @media (max-width: $screen-md-max) and (min-width: 540px) {
      li#li-contact,
      li#li-contact-us,
      li#li-connect {
        display: inline-block;
      }
    }
  }

  // Second level.
  ul.menu {
    // Hover state to show secondary (dropdown) navigation.
    > li.dropdown:hover, > li.dropdown:focus {
      > ul.dropdown-menu {
        opacity: 1;
        display: block !important;
        pointer-events: auto;
      }
    }

    > li > ul.dropdown-menu {
      padding: 0 0 7px 0;
      margin: 0 0 0 5px;
      position: absolute;
      top: 100%;
      left: calc(50% - 8px); // Reduce 8px to account for parent's icon.
      transform: translateX(-50%);
      width: 240px;
      background-color: #000;
      z-index: 89;
      pointer-events: none;
      opacity: 0;
      transition: opacity .2s linear;

      &:before {
        content: '';
        position: absolute;
        top: -15px;
        left: calc(50% - 10px);
        width: 0;
        height: 0;
        border-bottom: 15px solid;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        color: #000;
      }

      > li {
        width: 100%;
        margin: 0;
        padding: 0;

        > a {
          height: auto;
          padding: 7px 15px;
          width: 100%;
          color: #fff;
          text-transform: none;

          &:hover,
          &:focus,
          &.active {
            color: $acro-orange;
          }
        }

        &:first-child a {
          padding-top: 13px;
        }

        &:last-child a {
          padding-bottom: 13px;

          // Except if there is a dropdown.
          &.dropdown-toggle {
            padding-bottom: 7px;
          }
        }
      }

      .dropdown-toggle {
        width: calc(100% - 45px);
      }
    }
  }

  // Third, fourth... levels.
  ul.menu > li > ul.dropdown-menu ul.dropdown-menu {
    display: none;
    visibility: hidden;
    opacity: 1 !important;
    padding: 0;
    margin: 0 0 7px 10px;

    > li {
      width: 100%;
      margin: 0;
      padding: 0;

      > a {
        position: relative;
        height: auto;
        padding: 3px 15px 3px 21px;
        width: 100%;
        color: #949fa5;
        font-size: 15px;
        text-transform: none;

        &:before {
          position: absolute;
          top: 3px;
          left: 10px;
          content: '•';
        }

        &:hover,
        &:focus,
        &.active {
          color: $acro-orange;
        }
      }
    }
  }
}


// Primary menu 3rd level toggle (desktop and mobile).
.dropdown-toggle-button {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 36px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: $link-color;

  &:hover,
  &:focus {
    outline: none;
    color: $link-hover-color;
  }

  &:after {
    @extend %fa-icon;
    @extend .far;
    content: fa-content($fa-var-chevron-down);
  }

  &.dropdown-toggle-button--open:after {
    content: fa-content($fa-var-chevron-up);
  }
}

#pri-nav-cont {
  .first > .dropdown-toggle-button {
    top: 8px;
  }
}

#pri-nav-cont-mobile {
  .dropdown-toggle-button {
    top: 0;
  }
}

/*------------------------------
*	 Primary Navigation > Mobile hamburger menu
------------------------------*/

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

.hamburger {
  padding: 17px 15px 12px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter, color;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: $acro-gray;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  vertical-align: middle;
  outline: none;

  &:hover,
  &:focus {
    color: $acro-orange;
    opacity: 0.7;
  }

  @media (min-width: $screen-lg) {
    display: none;
  }
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: $acro-gray;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -10px;
}
.hamburger-inner::after {
  bottom: -10px;
}

// Spin
.hamburger--spin  {
  .hamburger-inner {
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::before {
      transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
    }

    &::after {
      transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.is-active {
    .hamburger-inner {
      transform: rotate(225deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}

/*------------------------------
*	 Primary Navigation > Mobile
------------------------------*/

// Mobile primary nav.
#pri-nav-cont-mobile  {
  display: none;
  position: fixed;
  top: 0;
  right: -20px; // To hide scrollbar.
  bottom: 0;
  z-index: 99;
  width: calc(100% + 20px);
  padding-top: 88px;
  background-color: #fff;
  border-top: 1px solid $gray-lighter;
  box-shadow: 0 0 9px 0 rgba(0,0,0,0.2);
  overflow-y: auto;

  @media (min-width: $screen-sm) {
    max-width: 500px;
  }

  @media (min-width: $screen-md) {
    display: none;
  }

  > .region {
    display: inline-block;
    width: 100%;
    padding: 20px 40px 0 20px;

    @media (min-width: $screen-sm) {
      padding: 20px 50px 0 30px;
    }

    &:last-of-type {
      padding-bottom: 30px;
    }

    ul.menu {
      display: block;
      margin: 0;
      padding: 0;
      list-style: none;

      // First level.
      > li {
        margin: 0;
        padding: 0;
        background: none;
        border-bottom: 1px solid $gray-light;

        > a {
          display: inline-block;
          width: 100%;
          margin: 10px 0;
          color: $acro-gray;
          font-size: 30px;
          font-weight: 300;
          text-transform: uppercase;

          &:hover,
          &:focus,
          &.active {
            color: $acro-orange;
          }

          &.nolink {
            &:hover,
            &:focus {
              color: $acro-gray;
              cursor: default;
            }
          }
        }

        &:first-of-type > a {
          margin-top: 0;
        }
      }
    }

    // Second level.
    ul.menu > li > ul.dropdown-menu {
      margin: 0 0 10px 2px;
      padding: 0;

      > li {
        margin: 0;
        padding: 0;
        background: none;

        a {
          position: relative;
          display: inline-block;
          width: 100%;
          margin: 0 0 10px;
          font-size: 20px;
          font-weight: 300;

          &:hover,
          &:focus {
            color: $link-hover-color;
          }

          &.active {
            color: #000;
          }

          &.nolink {
            &:hover,
            &:focus {
              color: $acro-gray;
              cursor: default;
            }
          }

          &.nolink {
            &:hover,
            &:focus {
              color: $acro-orange;
              cursor: default;
            }
          }
        }

        .dropdown-toggle {
          width: calc(100% - 45px);
        }
      }
    }

    // Third, fourth... levels.
    ul.menu > li > ul.dropdown-menu ul.dropdown-menu {
      display: none;
      visibility: hidden;
      margin: 0 0 10px 20px;
      padding: 0;

      li {
        margin: 0;
        padding: 0;
        background: none;

        a {
          position: relative;
          margin: 0 0 5px;
          font-size: 16px;

          &:before {
            position: absolute;
            content: '•';
            top: 0;
            left: -8px;
          }

          &.active {
            color: #000;
          }
        }

        &:last-of-type a {
          margin: 0 0 10px;
        }
      }
    }
  }
}

/*------------------------------
*	 Dropdown Promo.
------------------------------*/
.region-dropdown-promo > .block {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
}
