/**
 * Paragraph - Drupal webform.
 *
 * This is specific to the Drupal Webform paragraph component. The default Webform styling is in webforms.scss.
 */

.paragraphs-item-drupal-webform {
  .node-webform > h2 {
    display: none;
    visibility: hidden;
  }
}
