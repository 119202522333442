/**
 * Landing pages.
 *
 * Override styles for some specific landing pages.
 */

/**
 * Homepage.
 */

.front {
  .page-section--who-we-are {
    @media (min-width: $screen-md) {
      padding: 100px 0 100px;
    }

    > .content > .container {
      position: relative;

      @media (min-width: $screen-md) {
        &:after {
          position: absolute;
          top: -40px;
          right: -205px;
          width: 500px;
          height: 450px;
          content: '';
          background: url("/sites/default/files/homepage-banner-isometric.ecommerce.png") center center no-repeat;
          background-size: contain;
        }
      }
      @media (min-width: $screen-lg) {
        &:after {
          top: -69px;
          right: -170px;
          width: 575px;
          height: 515px;
        }
      }
      @media (min-width: 1350px) {
        &:after {
          right: 0;
        }
      }
    }
  }
}

/**
 * Insights page.
 */

.page-insights,
.page-insights-2020 {
  .page-section--how-we-work {
    @media (min-width: $screen-md) {
      padding: 100px 0 100px;
    }

    > .content > .container {
      position: relative;

      @media (min-width: $screen-md) {
        &:after {
          position: absolute;
          top: 0;
          right: -130px;
          width: 478px;
          height: 450px;
          content: '';
          background: url("/sites/default/files/insights-banner-isometric.ecommerce.png") center center no-repeat;
          background-size: contain;
        }
      }
      @media (min-width: $screen-lg) {
        &:after {
          top: -54px;
          right: -43px;
          width: 549px;
          height: 517px;
        }
      }
    }
  }
}

// Acro 'A' Light.
.page-section--acro-a-light {
  position: relative;
  background: #f5f7f7;
  background: linear-gradient(to bottom, #f5f7f7 0%, #ffffff 75%, #ffffff 100%);

  // A icon.
  &::before {
    content: '';
    display: none;
    position: absolute;
    top: -40px;
    left: 50%;
    margin: 0 0 0 -1098px;
    padding: 0;
    width: 770px;
    height: 579px;
    background: url('../gfx/bg-acro-a-light.png') 0 0 no-repeat;
    pointer-events: none;
    z-index: 0;

    @media (min-width: $screen-lg-min) {
      display: block;
    }
  }
  > .content {
    position: relative;
    z-index: 2;
  }
}

// Insights Step Section.
.page-section--insights-icon-step {
  .text-section {
    padding-top: 20px;
  }
  .text-section-main--with-right {
    padding-left: 90px;

    @media (min-width: $screen-sm-min) {
      padding-left: 185px;
    }
  }
  // Increase default padding between Bootstrap columns.
  @media screen and (min-width: $screen-md) {
    .text-section-secondary--right {
      padding-left: 60px;
    }
  }
}

.insights-icon-step__icon {
  position: absolute;
  top: 0;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  padding-top: 0;
  width: 50px;
  height: 50px;
  font-size: 25px;
  line-height: 1;
  text-align: center;
  color: $acro-gray-dark;
  border-radius: 50%;
  border: 2px solid $acro-orange;

  @media (min-width: $screen-sm-min) {
    left: 20px;
    width: 122px;
    height: 122px;
    font-size: 60px;
  }

  // Icon.
  i {
    margin: 0;
    padding: 0;
  }
}
.cke_editable .insights-icon-step__icon {
  position: relative;
  top: 0;
  left: 0;
}

// Content with form section.
// This only affects sections with "content-with-form" class.
.content-with-form {
  .text-section-secondary {
    > div {
      > .paragraphs-items > .field > .field-items > .field-item {
        // Make first paragraph a header.
        &:first-of-type {
          position: relative;
          padding: 15px 15px 0;
          margin-bottom: 30px;
          background-color: $acro-orange;
          color: #fff;

          h2, h3, h4 {
            color: #fff;
          }

          &:after {
            position: absolute;
            top: 100%;
            left: calc(50% - 20px);
            height: 0;
            width: 0;
            border: 20px solid rgba(0, 0, 0, 0);
            border-top-color: $acro-orange;
            content: " ";
          }
        }
      }

      // Account for extra padding when a bg color is selected.
      &.text-section-secondary-content-padding {
        > .paragraphs-items > .field > .field-items > .field-item {
          &:first-of-type {
            margin: -15px -15px 35px;
          }
        }
      }
    }
  }
}

/**
 * Landing page.
 *
 * Ebook Preview.
 * www.acrocommerce.com/ebook/engineered-ecommerce-preview
 */
.ebook-preview {

  .page-section--preview .page-section__content-wrapper {
    background-color: $gray-lightest;
    padding: 0;

    // Add padding around all paragraphs except the first.
    .paragraphs-items > .field > .field-items > .field-item {
      padding-left: 15px;
      padding-right: 15px;

      @media (min-width: $screen-sm) {
        padding-left: 30px;
        padding-right: 30px;
      }

      &:first-of-type {
        padding: 1px;
      }
    }
  }

  .preview-container {
    position: relative;
    padding: 12px 25px 0;
    margin: 0 auto;
    border: 1px solid $gray-lighter;
    border-top: none;
    background: #fff;

    @media (min-width: $screen-sm) {
      padding: 12px 30px 0;
    }
    @media (min-width: $screen-md) {
      padding: 12px 30px 0;
    }

    // Faded bottom.
    &:after {
      content: '';
      position: absolute;
      left: -2px;
      bottom: -1px;
      z-index: 2;
      width: calc(100% + 4px);
      height: 250px;
      background: linear-gradient(to bottom, rgba($gray-lightest ,0) 0%, rgba($gray-lightest ,.75) 75%, rgba($gray-lightest,1) 100%);
    }
  }

  // HubSpot form default overrides.
  .hs-form {
    max-width: 625px;
    margin-bottom: 25px;

    .hs-form-field {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      margin: 0 0 10px;

      @media (min-width: $screen-md) {
        margin: 0 5px;
        width: calc(100% - 275px);
      }

      label {
        display: none;

        &.hs-error-msg {
          display: block;
        }
      }

      input {
        width: 100%;
        min-height: 60px;
        font-size: 18px;
        text-align: center;

        @media (min-width: $screen-md) {
          font-size: 20px;
          text-align: left;
        }
      }
    }

    .hs-submit {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      margin: 0;

      @media (min-width: $screen-md) {
        margin: 0 5px;
        width: auto;
      }
    }

    .hs-button {
      display: flex;
      position: relative;
      width: 100%;
      min-height: 60px;
      margin: 0;
      padding: 15px 30px;
      border: 1px solid $acro-orange;
      border-radius: 0;
      outline: none;
      background-color: $acro-orange;
      font-size: 20px;
      line-height: 1.15em;
      text-align: center;
      justify-content: center;
      color: #fff;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border 0.15s ease-in-out;

      @media (min-width: $screen-sm) {
        font-size: 22px;
      }

      &:hover,
      &:focus {
        background-color: $link-hover-color;
        border: 1px solid $link-hover-color;
      }
    }
  }
}

/**
 * Landing page.
 *
 * Basic landing page layout with banner, text section and a styled form.
 * www.acrocommerce.com/ubercart-is-dead
 * www.acrocommerce.com/harness-capabilities-of-digital-commerce-platforms-b
 */

// Updated.
.basic-lander-with-styled-form {
  .page-section--banner {
    // Only show get started button for mobile.
    .button-ctas {
      @media (min-width: $screen-md) {
        display: none;
      }
    }
  }

  // Secondary content (form at tablet/desktop). This only affects sections
  // labelled "content with form".
  .page-section--content-with-form {
    .text-section-secondary {
      > div > .paragraphs-items {
        background-color: $gray-lightest;
      }

      > div > .paragraphs-items > .field > .field-items > .field-item {
        padding: 30px 20px 20px;

        @media (min-width: $screen-md) {
          padding: 30px 20px 0;
        }

        &:first-of-type {
          position: relative;
          padding-top: 15px;
          background-color: $acro-orange;
          color: #fff;

          h3 {
            color: #fff;
          }

          &:after {
            position: absolute;
            top: 100%;
            left: 20px;
            height: 0;
            width: 0;
            border: 20px solid rgba(0, 0, 0, 0);
            border-top-color: $acro-orange;
            content: " ";
          }
        }
      }
    }

    // HubSpot form default overrides.
    .hbspt-form .hs-button {
      width: 100%;
      padding: 15px 20px;
      font-weight: bold;
      text-transform: uppercase;

      @media (min-width: $screen-md) {
        margin-bottom: 40px;
      }
    }

    // Drupal form default overrides.
    .webform-component {
      @media screen and (min-width: $screen-sm) {
        width: 100%;
        float: none;
      }
    }

    .form-actions {
      margin-top: 0 !important;
      padding: 0 5px;
    }

    input.webform-submit {
      width: 100%;
      padding: 15px 20px;
      font-weight: bold;
      box-sizing: border-box;

      @media (min-width: $screen-md) {
        margin-bottom: 40px;
      }
    }
  }
}

/**
 * Landing page.
 *
 * End the Stigma of Free Consultation page.
 * www.acrocommerce.com/end-the-stigma-of-free-consultation
 */
.hbspt-form form[data-form-id='c0466151-e278-4b94-acb7-2a97405a42ba'] {
  // Make radio option list 2 columns.
  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      flex: 0 0 100%;

      @media (min-width: 500px) {
        flex: 0 0 50%;
      }
    }
  }
}

/**
 * Landing page.
 *
 * Retail Platform Limitations page.
 * www.acrocommerce.com/retail-platform-limitations-lander-steph (andrew, jace)
 */
.retail-platform-limitations-lander {
  .page-section--questions {
    display: none; // Revealed with jQuery.
    margin-top: -1px;
  }
}

/**
 * Landing page.
 *
 * Original Ubercart is Dead page.
 * www.acrocommerce.com/ubercart-is-dead
 */
.ubercart-lander {
  // Override banner section.
  .page-section--banner {
    margin-bottom: 0;
    background-position: center bottom;

    .banner-wrapper {
      border-bottom: none;
    }

    // Only show get started button for mobile.
    .button-ctas {
      @media (min-width: $screen-md) {
        display: none;
      }
    }
  }

  // Override content section background color.
  .page-section--content {
    padding-top: 0;
    background-color: #212328 !important;

    @media (min-width: 1460px) {
      .container {
        width: 1400px;
      }
    }
  }

  // Main content.
  .text-section-main {
    padding: 0;
    background-color: #fff;

    ul > li:before {
      color: $acro-orange;
    }

    div > .paragraphs-items > .field > .field-items > .field-item {
      padding: 20px 20px 0;

      @media (min-width: $screen-md) {
        padding: 30px 50px 10px;
      }

      // Form at mobile.
      &:nth-of-type(2),
      &:nth-of-type(3) {
        background-color: $gray-lightest;

        @media (min-width: $screen-md) {
          display: none;
          visibility: hidden;
          background-color: transparent;
        }
      }
      &:nth-of-type(3) {
        padding-bottom: 40px;
      }

      // Content areas.
      &:nth-of-type(1) {
        @media (min-width: $screen-md) {
          padding-bottom: 0;
        }
      }
      &:nth-of-type(4) {
        @media (min-width: $screen-md) {
          padding-top: 0;
        }
      }

      // Feature list.
      .feature-list {
        display: flex;
        margin-bottom: 5px;
      }
      .feature-list__icon {
        flex: 0 0 85px;

        i {
          height: 67px;
          width: 67px;
          border: 1px solid $gray-light;
          border-radius: 50%;
          text-align: center;
          color: $acro-orange;
          font-size: 0;
          line-height: 65px;

          &:before {
            margin-left: 2px;
            font-size: 35px;
          }
        }
      }

      // FAQ.
      &:nth-last-of-type(1) {
        padding-top: 0;
        padding-bottom: 30px;
        background-color: $gray-lightest;
      }
      &:nth-last-of-type(2) {
        background-color: $gray-lightest;
      }
    }
  }

  // Secondary content (form at tablet/desktop).
  .text-section-secondary {
    // Hide for mobile.
    display: none;
    visibility: hidden;
    margin-top: 40px;
    padding: 0;
    opacity: 1;
    transition: opacity 1s ease-out, margin 1s ease-out;

    @media (min-width: $screen-md) {
      display: block;
      visibility: visible;
      margin-top: 0;
      opacity: .5;
    }

    &.fade-up {
      @media (min-width: $screen-md) {
        margin-top: -25px;
        opacity: 1;
      }
    }

    > div > .paragraphs-items {
      background-color: $gray-lightest;
    }

    > div > .paragraphs-items > .field > .field-items > .field-item {
      padding: 50px 30px 15px;

      &:first-of-type {
        position: relative;
        padding-top: 25px;
        background-color: $acro-orange;
        color: #fff;

        h2 {
          color: #fff;
        }

        &:after {
          position: absolute;
          top: 100%;
          left: 20px;
          height: 0;
          width: 0;
          border: 20px solid rgba(0, 0, 0, 0);
          border-top-color: $acro-orange;
          content: " ";
        }
      }
    }
  }

  // HubSpot form default overrides.
  .hbspt-form .hs-button {
    width: 100%;
    padding: 15px 20px;
    font-weight: bold;
    text-transform: uppercase;

    @media (min-width: $screen-md) {
      margin-bottom: 40px;
    }
  }
}

/**
 * Landing page.
 *
 * Gartner landing page version C.
 * www.acrocommerce.com/harness-capabilities-of-digital-commerce-platforms-c
 */
.gartner-lander {
  @media (min-width: 1460px) {
    .page-section--content .container {
      width: 1400px;
    }
  }

  // Text section.
  .text-section-main {
    padding: 0;
    background-color: #fff;

    ul > li:before {
      color: $acro-orange;
    }

    div > .paragraphs-items > .field > .field-items > .field-item {
      padding: 20px 20px 0;

      @media (min-width: $screen-md) {
        padding: 30px 50px 10px;
      }

      // Form at mobile.
      &:nth-of-type(1),
      &:nth-of-type(2) {
        background-color: $gray-lightest;

        @media (min-width: $screen-md) {
          display: none;
          visibility: hidden;
          background-color: transparent;
        }
      }
    }
  }

  // Secondary content (form at tablet/desktop).
  .text-section-secondary {
    // Hide for mobile.
    display: none;
    visibility: hidden;
    margin-top: 40px;
    padding: 0;
    opacity: 1;
    transition: opacity 1s ease-out, margin 1s ease-out;

    @media (min-width: $screen-md) {
      display: block;
      visibility: visible;
      margin-top: 0;
      opacity: .5;
    }

    &.fade-up {
      @media (min-width: $screen-md) {
        margin-top: -25px;
        opacity: 1;
      }
    }

    > div > .paragraphs-items {
      background-color: $gray-lightest;
    }

    > div > .paragraphs-items > .field > .field-items > .field-item {
      padding: 50px 30px 15px;

      &:first-of-type {
        position: relative;
        padding-top: 25px;
        background-color: $acro-orange;
        color: #fff;

        h2, h3 {
          color: #fff;
        }

        &:after {
          position: absolute;
          top: 100%;
          left: 20px;
          height: 0;
          width: 0;
          border: 20px solid rgba(0, 0, 0, 0);
          border-top-color: $acro-orange;
          content: " ";
        }
      }

      &:last-of-type {
        padding-top: 0;
      }
    }
  }

  // HubSpot form default overrides.
  .hbspt-form .hs-button {
    width: 100%;
    padding: 15px 20px;
    font-weight: bold;
    text-transform: uppercase;

    @media (min-width: $screen-md) {
      margin-bottom: 40px;
    }
  }
}

/**
 * Landing page.
 *
 * Gartner landing page version A.
 * www.acrocommerce.com/harness-capabilities-of-digital-commerce-platforms
 */
.gartner-report-preview {
  $report-body: #424242;
  $report-heading: #212121;
  $report-green: #2C3E49;
  $report-gray: #5c6970;
  $report-gray-light: #aebbc4;

  .page-section--preview .page-section__content-wrapper {
    background-color: $gray-lightest;
    padding: 0;

    // Add padding around all paragraphs except the first.
    .paragraphs-items > .field > .field-items > .field-item {
      padding-left: 15px;
      padding-right: 15px;

      @media (min-width: $screen-sm) {
        padding-left: 30px;
        padding-right: 30px;
      }

        &:first-of-type {
        padding: 1px;
      }
    }
  }

  .small {
    font-size: 12px;
  }

  // Report preview.
  .report-header {
    padding: 27px 25px 29px;
    margin: 0 auto;
    border: 1px solid $gray-lighter;
    border-bottom: none;
    background: $report-green;
    color: #fff;

    span {
      display: block;
      padding-top: 4px;
    }

    @media (min-width: $screen-xs) {
      span {
        float: right;
      }
    }
    @media (min-width: $screen-sm) {
      padding: 27px 35px 29px;
    }
    @media (min-width: $screen-md) {
      padding: 27px 70px 29px;
    }
  }

  .report-container {
    position: relative;
    padding: 12px 25px 0;
    margin: 0 auto;
    border: 1px solid $gray-lighter;
    border-top: none;
    background: #fff;
    color: $report-body;

    @media (min-width: $screen-sm) {
      padding: 12px 30px 0;
    }
    @media (min-width: $screen-md) {
      padding: 12px 30px 0;
    }

    // Faded bottom.
    &:after {
      content: '';
      position: absolute;
      left: -2px;
      bottom: -1px;
      z-index: 2;
      width: calc(100% + 4px);
      height: 250px;
      background: linear-gradient(to bottom, rgba($gray-lightest ,0) 0%, rgba($gray-lightest ,.75) 75%, rgba($gray-lightest,1) 100%);
    }

    h1 {
      margin-bottom: 28px;
      font-family: $font-family-sans-serif;
      font-size: 28px;
      color: $report-heading;

      @media (min-width: $screen-md) {
        font-size: 38px;
      }
    }

    h2 {
      margin-bottom: 15px;
      font-family: $font-family-sans-serif;
      font-size: 22px;
      font-weight: 500;
      color: $report-heading;

      @media (min-width: $screen-md) {
        font-size: 28px;
      }
    }

    h3 {
      margin-bottom: 19px;
      font-family: $font-family-sans-serif;
      font-size: 19px;
      color: $report-heading;

      @media (min-width: $screen-md) {
        font-size: 21px;
      }
    }

    ul {
      margin: 5px 0 0 0;
      padding-bottom: 13px;

      li {
        padding: 0 0 18px 18px;
        background: none;

        &:before {
          content: '\25A0';
          position: absolute;
          top: 0;
          left: 0;
          color: $report-gray-light;
        }
      }
    }
  }

  .report-inner__right {
    @media (min-width: $screen-md) {
      padding-top: 20px;
    }

    img {
      display: block;
      width: 40px;
      border-radius: 50%;
      background: #03afff;
      margin-bottom: 8px;
    }

    span {
      display: block;
      font-weight: 500;

      &:last-of-type {
        font-size: 14px;
        line-height: 19px;
      }
    }
  }

  .report-info {
    margin-bottom: 8px;

    span {
      position: relative;
      display: block;

      &:after {
        display: none;
        content: '-';
        position: relative;
        padding: 0 0 0 5px;
      }

      &:last-of-type:after {
        display: none !important;
      }

      @media (min-width: $screen-sm) {
        display: inline-block;

        &:after {
          display: inline-block;
        }
      }
    }
  }

  .report-author {
    margin-bottom: 8px;
  }

  .report-hr {
    margin-top: 0;
    margin-bottom: 2px;
  }

  .report-intro {
    padding: 7px 0 20px;
    font-size: 17px;
    font-weight: 300;
    color: $report-gray;

    @media (min-width: $screen-md) {
      font-size: 20px;
    }
  }

  // HubSpot form default overrides.
  .hs-form {
    max-width: 625px;
    margin-bottom: 25px;

    .hs-form-field {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      margin: 0 0 10px;

      @media (min-width: $screen-md) {
        margin: 0 5px;
        width: calc(100% - 275px);
      }

      label {
        display: none;

        &.hs-error-msg {
          display: block;
        }
      }

      input {
        width: 100%;
        min-height: 60px;
        font-size: 18px;
        text-align: center;

        @media (min-width: $screen-md) {
          font-size: 20px;
          text-align: left;
        }
      }
    }

    .hs-submit {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      margin: 0;

      @media (min-width: $screen-md) {
        margin: 0 5px;
        width: auto;
      }
    }

    .hs-button {
      display: flex;
      position: relative;
      width: 100%;
      min-height: 60px;
      margin: 0;
      padding: 15px 30px;
      border: 1px solid $acro-orange;
      border-radius: 0;
      outline: none;
      background-color: $acro-orange;
      font-size: 20px;
      line-height: 1.15em;
      text-align: center;
      justify-content: center;
      color: #fff;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border 0.15s ease-in-out;

      @media (min-width: $screen-sm) {
        font-size: 22px;
      }

      &:hover,
      &:focus {
        background-color: $link-hover-color;
        border: 1px solid $link-hover-color;
      }
    }
  }

  // Drupal form default overrides.
  .node-webform {
    max-width: 625px;
  }

  .webform-client-form {
    margin-bottom: 25px;

    .form-item {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      margin: 0 0 10px;
      float: none;

      @media (min-width: $screen-md) {
        width: calc(100% - 275px);
      }

      input {
        width: 100%;
        min-height: 60px;
        font-size: 18px;
        text-align: center;

        @media (min-width: $screen-md) {
          font-size: 20px;
          text-align: left;
        }
      }
    }

    .form-actions{
      display: flex;
      vertical-align: top;
      width: 100%;
      margin: 0;

      @media (min-width: $screen-md) {
        display: inline-flex;
        margin: 0 5px;
        max-width: 260px;
      }
    }

    input.webform-submit {
      display: inline-block;
      position: relative;
      width: 100%;
      min-height: 60px;
      margin: 0;
      padding: 0 15px;
      border: none;
      border-radius: 0;
      outline: none;
      background-color: $acro-orange;
      font-size: 20px;
      line-height: 1.15em;
      text-align: center;
      text-transform: none;
      justify-content: center;
      color: #fff;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border 0.15s ease-in-out;

      @media (min-width: $screen-sm) {
        font-size: 22px;
      }

      &:hover,
      &:focus {
        background-color: $link-hover-color;
        border: none;
      }
    }
  }
}

/**
 * Landing page.
 *
 * Receive a commerce analysis.
 * www.acrocommerce.com/commerce-analysis
 */
.page-commerce-analysis {
  // HubSpot form default overrides.
  #hsForm_912ca142-a2d4-477b-942b-07bf1a3e0833 {
    max-width: 450px;
    margin-bottom: 25px;

    @media (min-width: $screen-md) {
      max-width: 800px;
    }

    .hs-form-field {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      margin: 0 0 10px;

      @media (min-width: $screen-md) {
        margin: 0 5px;
        width: calc(100% - 275px);
      }

      label {
        display: none;

        &.hs-error-msg {
          display: block;
        }
      }

      input {
        width: 100%;
        min-height: 60px;
        font-size: 18px;
        text-align: center;

        @media (min-width: $screen-md) {
          font-size: 20px;
          text-align: left;
        }
      }
    }

    .hs-submit {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      margin: 0;

      @media (min-width: $screen-md) {
        margin: 0 5px;
        width: auto;
      }
    }

    .hs-button {
      display: flex;
      position: relative;
      width: 100%;
      min-height: 60px;
      margin: 0;
      padding: 15px 30px;
      border: 1px solid $acro-orange;
      border-radius: 0;
      outline: none;
      background-color: $acro-orange;
      font-size: 20px;
      line-height: 1.15em;
      text-align: center;
      justify-content: center;
      color: #fff;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border 0.15s ease-in-out;

      @media (min-width: $screen-sm) {
        font-size: 22px;
      }

      &:hover,
      &:focus {
        background-color: $link-hover-color;
        border: 1px solid $link-hover-color;
      }
    }
  }
}

/**
 * Landing page.
 *
 * Biggest Limitation page.
 * www.acrocommerce.com/dont-restrict-your-digital-success
 */

.biggest-limitation-lander {
  /**
   * General form overrides. More specific overrides in each section below.
   */
  .node-webform {
    max-width: unset;
  }

  .messages {
    display: none;
    visibility: hidden;
  }

  .form-actions {
    display: none;

    .webform-submit {
      display: none;
      visibility: hidden;
    }
  }

  .disabled {
    opacity: .5;
    cursor: not-allowed;

    .form-item {
      pointer-events: none;
    }
  }

  .fake-submit {
    max-width: 200px;
    margin: 10px auto 0;
  }

  /**
   * Banner.
   */
  .banner-group__scroll-down {
    display: none;
    visibility: hidden;
  }

  .page-section--banner {
    // Form styling overrides.
    .node-webform {
      padding-top: 15px;
      border-top: 1px solid $acro-gray;
    }

    .webform-component {
      padding: 0;

      @media screen and (min-width: $screen-sm) {
        width: 100%;
        float: none;
      }
    }

    .description {
      margin-bottom: 12px;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: $acro-orange;

      @media screen and (min-width: $screen-sm) {
        margin-bottom: 30px;
      }
    }

    // Radios.
    .form-radios {
      display: flex;
      flex-wrap: wrap;
      transition: opacity .15s ease-out;

      @media screen and (min-width: $screen-sm) {
        flex-wrap: nowrap;
      }
    }

    .form-type-radio {
      position: relative;
      flex: 1 1 100%;
      margin: .4em 0;

      @media screen and (min-width: $screen-sm) {
        flex: 1 1;
        margin: .4em;
      }

      // Hide the browser's default radio.
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .option {
        display: inline-block;
        height: 100%;
        padding: 10px 15px 10px 45px;
        margin-bottom: 2px;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2);
        border-radius: 5px;
        border: 2px solid $acro-gray;
        background-color: rgba(#000, .75);
        color: $gray-light;
        font-size: 15px;
        cursor: pointer;
        transition: border .1s ease-out, background-color .1s ease-out, color .1s ease-out;

        @media screen and (min-width: $screen-sm) {
          padding: 25px 10px 15px;
          text-align: center;
        }
        @media screen and (min-width: $screen-md) {
          font-size: 16px;
        }

        &:before {
          @extend %fa-icon;
          @extend .fas;
          content: "";
          position: absolute;
          top: calc(50% - 14px);
          left: 8px;
          height: 28px;
          width: 28px;
          border-radius: 50%;
          border: 2px solid $acro-gray;
          background-color: #000;
          color: $acro-gray-dark;
          text-align: center;
          font-size: 18px;
          line-height: 27px;
          transition: border .1s ease-out, background-color .1s ease-out, color .1s ease-out;

          @media screen and (min-width: $screen-sm) {
            top: -14px;
            left: calc(50% - 14px);
          }
        }
      }

      &:hover input ~ .option,
      &:focus input ~ .option,
      .option.active {
        border: 2px solid #fff;
        background-color: rgba(#000, .75);
        color: #fff;

        &:before {
          content: fa-content($fa-var-check);
          border-color: #fff;
          background-color: #fff;
          color: $text-color;
        }
      }
    }
  }

  /**
   * Answers and final question sections.
   */
  .answer,
  .page-section--want-to-talk {
    display: none;

    // Form styling overrides.
    .webform-component {
      display: none; // Reveal with jQuery.
      max-width: 800px;
      margin: 40px auto 15px;
      padding: 0;
      text-align: center;

      @media screen and (min-width: $screen-sm) {
        width: 100%;
        float: none;
      }

      .description {
        margin-top: 20px;
      }

      > label {
        margin-top: 10px;
        margin-bottom: 20px;
        padding: 0;
        font-size: 37px;
        font-weight: 300;
        color: $acro-gray-dark;

        @media (max-width: $screen-xs-max) {
          font-size: 25px;
        }

        .form-required {
          display: none;
        }
      }
    }

    // Radios and checkboxes.
    .form-radios,
    .form-checkboxes {
      text-align: left;

      &.inline {
        @media screen and (min-width: $screen-sm) {
          display: flex;

          .form-type-radio,
          .form-type-checkbox {
            flex: 1 0;

            &:nth-of-type(odd) {
              margin-right: .4em;
            }
            &:nth-of-type(even) {
              margin-left: .4em;
            }

            .option:before {
              top: 16px;
            }
          }
        }
      }
    }

    .form-type-radio,
    .form-type-checkbox {
      position: relative;

      // Hide the browser's default radio and checkbox.
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .option {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 10px 15px 10px 45px;
        margin-bottom: 2px;
        border-radius: 5px;
        border: 2px solid $gray-light;
        background-color: #fff;
        font-size: 15px;
        cursor: pointer;
        transition: border .1s ease-out, background-color .1s ease-out, color .1s ease-out;

        @media screen and (min-width: $screen-sm) {
          padding: 18px 15px 18px 60px;
          font-size: 17px;
        }

        &:before {
          @extend %fa-icon;
          @extend .fas;
          content: "";
          position: absolute;
          top: calc(50% - 15px);
          left: 8px;
          height: 28px;
          width: 28px;
          border-radius: 50%;
          border: 2px solid $gray-light;
          background-color: #fff;
          color: #fff;
          text-align: center;
          font-size: 18px;
          line-height: 27px;
          transition: border .1s ease-out, background .1s ease-out;

          @media screen and (min-width: $screen-sm) {
            top: calc(50% - 15px);
            left: 18px;
          }
        }
      }

      &:hover input ~ .option,
      &:focus input ~ .option,
      .option.active {
        border: 2px solid $acro-gray-dark;
        color: $acro-gray-dark;

        &:before {
          content: fa-content($fa-var-check);
          border-color: $acro-gray-dark;
          background-color: $acro-gray-dark;
        }
      }
    }
  }

  /**
   * Want to talk sections.
   */
  .page-section--want-to-talk---yes,
  .page-section--want-to-talk---no {
    display: none;

    // HubSpot form overrides.
    .hs-form label {
      font-weight: normal;
    }

    // This fixes a HubSpot bug where dependent fields don't appear if their container is initially hidden.
    .hs-dependent-field {
      display: block !important;
    }

    .hs-input {
      border-radius: 5px;
      border: 2px solid $gray-light;

      &.error {
        border: 2px solid $brand-danger;
      }
    }

    .hs-fieldtype-radio {
      ul {
        padding-bottom: 0;
      }

      li {
        display: inline-block;
        margin-right: 15px;
        margin-left: 15px;

        // Hide the browser's default radio.
        input[type='radio'] {
          position: absolute;
          opacity: 0;
          height: 0;
          width: 0;

          ~ span {
            position: relative;
            padding-left: 30px;
            cursor: pointer;

            &:before {
              @extend %fa-icon;
              @extend .fas;
              content: "";
              position: absolute;
              top: calc(50% - 13px);
              left: 0;
              height: 24px;
              width: 24px;
              border-radius: 50%;
              border: 2px solid $text-color;
              background-color: #fff;
              color: #fff;
              text-align: center;
              font-size: 15px;
              line-height: 22px;
              transition: border .1s ease-out, background .1s ease-out;
            }
          }

          &:checked ~ span:before {
            content: fa-content($fa-var-check);
            border-color: $acro-gray-dark;
            background-color: $acro-gray-dark;
          }
        }
      }
    }
  }

  /**
   * Resources section.
   */
  .page-section--resources {
    display: none;
  }
}

/**
 * Landing page.
 *
 * Lander with floating section pages.
 * Uses "lander-with-floating-section" page class
 */
.lander-with-floating-section {
  .page-section--banner {
    // Only show scroll to form button for mobile.
    .button-ctas {
      @media (min-width: $screen-md) {
        display: none;
      }
    }
  }

  // Infographic.
  .free-trial-infographic {
    .text-section {
      background-color: $acro-gray-dark;

      @media (min-width: $screen-md) {
        padding-left: 15px;
        padding-bottom: 0;
      }

      ul {
        margin-bottom: 0;
      }
    }

    .text-section-main {
      padding-top: 25px;
      color: #fff;

      @media (max-width: $screen-sm-max) {
        padding-left: 30px;
        padding-right: 30px;
      }

      @media (min-width: $screen-md) {
        padding-right: 0;
      }

      hr {
        margin-bottom: 30px;
        border-top: 1px solid $acro-gray;
      }
    }

    .text-section-secondary {
      @media (max-width: $screen-sm-max) {
        border-top: 15px solid #fff;
      }

      @media (min-width: $screen-md) {
        position: relative;
        right: -15px;
        margin-top: -15px;
      }

      hr {
        margin-bottom: 30px;
      }
    }

    .infographic-image-1 {
      position: absolute;
      display: none;
      visibility: hidden;

      @media (min-width: $screen-md) {
        display: block;
        visibility: visible;
        top: -145px;
        left: -15px;
        width: 180px;
      }

      @media (min-width: $screen-lg) {
        top: -200px;
        left: -45px;
        width: 245px;
      }

      @media (min-width: 1360px) {
        top: -245px;
        left: -120px;
        width: 300px;
      }
    }

    .infographic-image-2 {
      position: absolute;
      display: none;
      visibility: hidden;

      @media (min-width: $screen-md) {
        display: block;
        visibility: visible;
        top: -140px;
        right: 15px;
        width: 145px;
      }

      @media (min-width: $screen-lg) {
        top: -160px;
        right: -10px;
        width: 175px;
      }
    }

    h3 {
      margin-bottom: 10px;
      color: #fff;
      font-size: 16px;
      font-weight: 600;

      span {
        display: inline-block;
        margin-top: 13px;
        font-family: $font-family-sans-serif;
        color: $acro-orange;
        font-size: 24px;
        font-weight: 500;
      }
    }
    .text-section-secondary {
      h3 {
        color: $acro-gray-dark;

        span {
          color: $acro-orange;
        }
      }
    }
  }

  /**
   * Floating section.
   *
   * Section with this class is the designated "floating section" and floats on
   * the right side of the page. Only used on larger screen.
   *
   * Also see script-unique-components.js for JS.
   */
  @media (min-width: $screen-md) {

    .floating-section-wrapper {
      position: absolute;
      z-index: 1;
      top: 40px;
      right: calc(50% - #{$container-md} / 2);
      width: 312px;
      height: calc(100% - 70px);
      padding: 0;

      @media (min-width: $screen-lg) {
        right: calc(50% - #{$container-lg} / 2);
        width: 330px;
      }
    }

    .floating-section-wrapper__inner {
      will-change: min-height;
    }

    .inner-wrapper-sticky {
      transform: translate(0, 0);
      transform: translate3d(0, 0, 0);
      will-change: position, transform;
    }

    .is-affixed .inner-wrapper-sticky {
      padding-bottom: 90px;
    }

    .floating-section {
      display: none;
      width: 100%;
      margin-bottom: 20px;

      .container {
        width: 100%;
      }
    }

    /**
     * All other sections.
     */

    // Banner.
    .banner .banner__content--with-floating-section {
      padding-right: 415px;

      @media (min-width: $screen-lg) {
        padding-right: 455px;
      }
    }
  }

  // Floating section form.
  .floating-section {
    .page-section__content-wrapper {
      > .paragraphs-items > .field > .field-items > .field-item:first-of-type {
        position: relative;
        padding: 15px 15px 5px;
        margin: 0 -200px 40px;
        background-color: $acro-blue;
        color: #fff;

        @media (min-width: $screen-md) {
          padding: 20px 15px 10px;
          margin: 0 -15px 40px;
        }

        h3 {
          margin-bottom: 10px;
          font-family: $font-family-sans-serif;
          font-size: 19px;
          font-weight: 500;
          color: #fff !important;
        }

        &:after {
          position: absolute;
          top: 100%;
          left: calc(50% - 20px);
          height: 0;
          width: 0;
          border: 20px solid rgba(0,0,0,0);
          border-top-color: $acro-blue;
          content: " ";
        }
      }
    }

    .hbspt-form .hs-button {
      width: 100%;
      padding: 15px 20px;
      font-weight: 500;
    }
  }
}
