/**
 * Paragraph - Responsive Image CTA.
 */

.responsive-image-cta {
  display: block;
  text-align: center;

  img {
    opacity: 0.95;
    transition: opacity 0.2s ease-in-out, filter 0.2s ease-in-out;
  }

  &:hover, &:focus {
    img {
      opacity: 1;
      filter: saturate(2);
    }
  }

  // Desktop image.
  @media (min-width: $screen-md-min) {
    .responsive-image-cta__desktop {
      display: block;
    }
    .responsive-image-cta__tablet,
    .responsive-image-cta__mobile {
      display: none;
    }
  }

  // Tablet image.
  @media (max-width: $screen-sm-max) {
    .responsive-image-cta__tablet {
      display: block;
    }
    .responsive-image-cta__desktop,
    .responsive-image-cta__mobile {
      display: none;
    }
  }

  // Mobile image.
  @media (max-width: $screen-xs-min) {
    .responsive-image-cta__mobile {
      display: block;
    }
    .responsive-image-cta__desktop,
    .responsive-image-cta__tablet {
      display: none;
    }
  }
}
