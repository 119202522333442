/**
 * Paragraph - Page sections (with or without Sticky Table of Contents).
 */

// Page sections container.
.page-sections {
  position: relative;
  z-index: 1;
}

// Padding for the table of contents (added via jQuery) in page-paragraphs.js.
.toc-left-padding {
  padding-left: 60px !important;

  // Banners require different padding because of the full width background image.
  &.paragraphs-item-rich-media-banner {
    padding-left: 0 !important;

    .banner__content {
      padding-left: 60px !important;
    }
  }

  // Image strap require different padding because of the full width background image.
  &.image-strap-wrapper {
    padding-left: 0 !important;
    margin: 0;

    .image-strap__content-wrapper--left {
      padding-left: 60px !important;
    }

    @media (max-width: $screen-sm-max) {
      .image-strap__content-wrapper {
        padding-left: 60px !important;
      }
    }
  }
}

// Individual page section.
.page-section {
  padding: 40px 0;
  margin-bottom: 1px;
  background-position: center;

  > .content--wide {
    // Only used when the "Restrict background" checkbox is checked.
    width: 100%;
    max-width: 1450px;
    margin-left: auto;
    margin-right: auto;
    background-position: center;

    // Background colors.
    @include bg-color-options;
  }

  // Page section modifier - Wider content area.
  &.page-section--wide-content {
    > .content > .container {
      @media (min-width: 1350px) {
        width: 1340px;
      }
    }
  }

  // Page section modifier - Alter padding.
  &.page-section--padding-lg {
    @media (min-width: $screen-md-min) {
      padding: 60px 0;
    }
  }
  &.page-section--padding-xl {
    @media (min-width: $screen-md-min) {
      padding: 80px 0;
    }
  }
  &.page-section--no-top-padding {
    padding-top: 0;
  }
  &.page-section--no-bottom-padding {
    padding-bottom: 0;
  }
  &.page-section--no-bottom-margin {
    margin-bottom: 0;
  }
  &.page-section--half-padding {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  &.page-section--half-top-padding {
    padding-top: 20px;
  }
  &.page-section--half-bottom-padding {
    padding-bottom: 20px;
  }
  &.page-section--strap-cta-lg {
    .strap-cta__title {
      font-size: 36px;

      @media (min-width: $screen-md-min) {
        font-size: 65px;
      }
    }
  }

  // Background colors.
  @include bg-color-options;

  // Non-theme background colors.
  &.page-section--blue-gradient {
    background-color: $acro-blue;
    background: linear-gradient(-45deg, rgba(#004a8f,1) 0%, rgba($acro-blue,1) 100%);
  }
}

// Hide section titles and anchors.
.page-section__title,
.page-section__anchor,
.page-sub-section__title,
.page-sub-section__anchor {
  margin: 0;

  span {
    display: none;
    visibility: hidden;
  }
}

// Section content overrides for dark and orange backgrounds.
.page-section.page-section--dark-theme {
  h1, h2, h4, h6 {
    color: #fff;
  }

  p {
    color: #fff;
  }

  ul, ol {
    color: #fff;

    li:before {
      color: $acro-orange !important;
    }
  }

  blockquote {
    color: #fff;
  }
}


/**
 * Sticky Table of Contents.
 */

// All levels of nav.
nav[data-toggle='toc'] .nav {
  li {
    background: none;
  }

  > li > a {
    display: block;
  }

  > .active > ul {
    display: block;
  }

  .nav {
    // Hide by default, but at >768px, show it.
    display: none;
  }
}

// Sticky nav - Closed.
.page-sections-sticky-nav {
  position: fixed;
  top: 325px;
  left: -76px;
  display: block;
  margin: 0;
  padding: 0;
  width: 200px;
  color: #fff;
  background: #000;
  border-radius: 0 0 8px 8px;
  z-index: 2;
  transform: rotate(-90deg);
  transition: transform .1s ease-in-out;

  @media (max-width: $screen-xs-max) {
    top: 240px;
  }

  // Line break.
  &:before {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    right: 49px;
    width: 1px;
    height: 100%;
    background: #333;
  }

  .page-sections-sticky-nav__title {
    padding: 14px 47px 13px 16px;

    &:after {
      top: 11px;
      right: 15px;
      transform: rotate(90deg);
    }
  }

  > nav {
    display: none;
  }
}

// Sticky nav - Active state.
.page-sections-sticky-nav.is-active {
  position: fixed;
  top: 250px;
  left: 0;
  display: block;
  margin: 0;
  padding: 0 0 29px 0;
  width: 250px;
  color: #fff;
  background: #000;
  border-radius: 0 8px 8px 0;
  z-index: 2;
  transform: none;
  transition: none;

  &.page-sections-sticky-nav--scrolled {
    top: 150px;
  }

  @media (max-width: $screen-xs-max) {
    top: 160px;

    &.page-sections-sticky-nav--scrolled {
      top: 110px;
    }
  }

  // Line break.
  &:before {
    display: none;
  }

  a {
    color: #fff;
    text-decoration: none;
  }
  > nav {
    display: block;
    max-height: calc(100vh - 400px);
    overflow-y: auto;

    @media (max-width: $screen-md-max) {
      max-height: calc(100vh - 350px);
    }

    @media (max-width: $screen-xs-max) {
      max-height: calc(100vh - 280px);
    }
  }

  > nav > ul.nav {
    margin: 0;
    padding: 0;
    list-style: none;

    // First level.
    > li {
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      pointer-events: all;

      > a {
        position: relative;
        display: block;
        margin: 0;
        padding: 7px 39px 7px 15px;
        font-size: 15px;
        color: #fff;
        font-weight: 600;
        text-decoration: none;
        background: #1b1b1b;
        border: none;
        border-bottom: 1px solid #333;

        &:hover,
        &:focus {
          color: $acro-orange;
        }
        // Arrow icon.
        &:after {
          @extend %fa-icon;
          @extend .fas;
          content: fa-content($fa-var-caret-right);
          position: absolute;
          top: 9px;
          right: 17px;
          font-size: 18px;
          color: $acro-orange;
        }
      }
      // Active items.
      &.active {
        pointer-events: none;

        > a {
          color: $acro-orange;

          // Arrow icon.
          &:after {
            content: fa-content($fa-var-caret-down);
          }
        }
      }
    }
    // Second level.
    > li > ul.nav {
      margin: 0;
      padding: 5px 0 5px 0;
      background: none;
      border: none;
      background: #292929;

      > li {
        padding: 0 0 0 14px;
        pointer-events: all;

        > a {
          position: relative;
          display: block;
          margin: 0;
          padding: 4px 30px;
          font-size: 14px;
          color: $gray-light;
          font-weight: normal;
          text-decoration: none;
          background: #292929;
          border: none;

          &:hover,
          &:focus {
            color: #fff;
          }
          // Arrow icon.
          &:after {
            @extend %fa-icon;
            @extend .far;
            content: fa-content($fa-var-angle-right);
            position: absolute;
            top: 5px;
            left: 17px;
            font-size: 14px;
            color: $gray-light;
          }
        }
        // Active items.
        &.active {
          pointer-events: none;

          > a {
            color: #fff;

            &:hover,
            &:focus {
              pointer-events: none;
            }

            // Arrow icon.
            &:after {
              content: fa-content($fa-var-angle-down);
            }
          }
        }
      }
    }
  }
}

// Sticky nav title.
.page-sections-sticky-nav__title {
  display: block;
  margin: 0;
  padding: 17px 47px 11px 16px;
  font-size: 22px;
  line-height: 1.0;
  color: #fff;
  text-transform: uppercase;
  transition: color .2s ease-in-out;

  // Menu Icon.
  &:after {
    @extend %fa-icon;
    @extend .far;
    content: fa-content($fa-var-bars);
    position: absolute;
    top: 12px;
    right: 16px;
    color: #fff;
    font-size: 26px;
    line-height: 1.0;
    transition: color .2s ease-in-out;
  }

  &:hover,
  &:focus {
    color: #afafaf;

    // Menu Icon.
    &:after {
      color: #afafaf;
    }
  }
}

// Sticky nav title - Active state.
.is-active .page-sections-sticky-nav__title {
  transition: color .2s ease-in-out;

  // Close Icon.
  &:after {
    @extend %fa-icon;
    @extend .far;
    content: fa-content($fa-var-times);
    position: absolute;
    top: 12px;
    right: 16px;
    color: #fff;
    font-size: 26px;
    line-height: 1.0;
    transition: color .2s ease-in-out;
  }

  &:hover,
  &:focus {
    color: #afafaf;

    // Close Icon.
    &:after {
      color: #afafaf;
    }
  }
}

// Sticky help form CTA - Closed.
.page-sections-sticky-form-cta {
  position: fixed;
  top: 350px;
  right: -101px;
  display: block;
  margin: 0;
  padding: 0;
  width: 250px;
  color: #fff;
  background: $acro-orange;
  border-radius: 8px 8px 0 0;
  z-index: 2;
  transform: rotate(-90deg);
  transition: transform .1s ease-in-out;

  @media (max-width: $screen-xs-max) {
    display: none;
  }

  // Line break.
  &:before {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    right: 49px;
    width: 1px;
    height: 100%;
    background: darken($acro-orange, 10%);
  }

  .page-sections-sticky-form-cta__title {
    position: relative;
    display: block;
    margin: 0;
    padding: 14px 47px 13px 16px;
    font-size: 22px;
    line-height: 1.0;
    color: #fff;
    text-transform: uppercase;
    transition: color .2s ease-in-out;

    &:after {
      @extend %fa-icon;
      @extend .fas;
      content: fa-content($fa-var-question-circle);
      position: absolute;
      top: 11px;
      right: 15px;
      color: #fff;
      font-size: 26px;
      line-height: 1.0;
      transition: color .2s ease-in-out;
      transform: rotate(90deg);
    }

    &:hover,
    &:focus {
      color: #FFEBD9;

      // Close Icon.
      &:after {
        color: #FFEBD9;
      }
    }
  }

  .page-sections-sticky-form-cta__content,
  .page-sections-sticky-form-cta__message,
  .page-sections-sticky-form-cta__button {
    display: none;
  }
}

// Sticky help form CTA - Active.
.page-sections-sticky-form-cta.is-active {
  position: fixed;
  top: 250px;
  right: 0;
  display: block;
  margin: 0;
  padding: 0 0 29px 0;
  width: 250px;
  color: #fff;
  background: $acro-orange;
  border-radius: 8px 0 0 8px;
  z-index: 2;
  transform: none;
  transition: none;
  text-decoration: none;
  outline: none;

  &.page-sections-sticky-form-cta--scrolled {
    top: 150px;
  }

  // Line break.
  &:before {
    display: none;
  }

  .page-sections-sticky-form-cta__title {
    padding: 17px 47px 11px 16px;

    // Close Icon.
    &:after {
      @extend %fa-icon;
      @extend .far;
      content: fa-content($fa-var-times);
      top: 12px;
      right: 16px;
      font-size: 26px;
    }
  }

  .page-sections-sticky-form-cta__content {
    display: block;
    padding: 8px 15px 18px;
    background: #f7f7f7;
    border-left: 1px solid #ddd;
    color: #333;
    cursor: default;
  }

  .page-sections-sticky-form-cta__message {
    display: block;
    height: 110px;
    margin-top: 6px;
    padding: 6px 10px;
    background: #fff;
    border: 1px solid $gray-light;
    color: #999;
    font-size: 13px;
    cursor: text;
    transition: color .2s ease-in-out;

    &:focus,
    &:hover {
      color: $gray;
    }
  }

  .page-sections-sticky-form-cta__button {
    display: block;
    margin-top: 10px;
    padding: 10px 20px;
    text-align: center;
    font-family: $font-family-sans-serif;
    font-size: 18px;
    line-height: 20px;
    background: #141414;
    color: #fff;
    text-transform: uppercase;
    border: 0;
    mos-appearance: none;
    transition: all 0.15s linear;
    cursor: pointer;

    &:focus,
    &:hover {
      background-color: $acro-orange;
    }
  }
}

// Sticky nav title - Active state.
.is-active .page-sections-sticky-nav__title {
  transition: color .2s ease-in-out;

  // Close Icon.
  &:after {
    @extend %fa-icon;
    @extend .far;
    content: fa-content($fa-var-times);
    position: absolute;
    top: 12px;
    right: 16px;
    color: #fff;
    font-size: 26px;
    line-height: 1.0;
    transition: color .2s ease-in-out;
  }

  &:hover,
  &:focus {
    color: #afafaf;

    // Close Icon.
    &:after {
      color: #afafaf;
    }
  }
}

// Content lists,
.content .field-type-text-with-summary .field-item > ul,
.content .content-tabs__pane > ul,
.content table ul {
  > li {
    background: none;

    // Arrow icon.
    &:before {
      @extend %fa-icon;
      @extend .far;
      content: fa-content($fa-var-angle-right);
      position: absolute;
      top: 1px;
      left: 0;
      color: $acro-orange;
      font-size: 16px;
      line-height: 1.0;
    }

    ul {
      li {
        background: none;

        // Arrow icon.
        &:before {
          @extend %fa-icon;
          @extend .far;
          content: fa-content($fa-var-angle-right);
          position: absolute;
          top: 1px;
          left: 0;
          color: $acro-orange;
          font-size: 16px;
          line-height: 1.0;
        }
      }
    }
  }
}
