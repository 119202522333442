/**
 * Paragraph - Breadcrumb.
 */

// Default theme (on white background).
.breadcrumb {
  display: none;
  padding: 10px 0;
  margin: 0;
  background-color: transparent;

  @media (min-width: $screen-sm) {
    display: block;
  }

  > li + li:before {
    padding: 0 2px;
    color: $text-color;
  }
}

.breadcrumb__item {
  margin: 0;
  padding: 0;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: .02em;

  a {
    color: $text-color;

    &:hover,
    &:focus {
      color: #000;
    }
  }
}


// Dark theme.
.page-section--dark-theme {
  .breadcrumb {
    > li + li:before {
      color: rgba(#fff, .85) !important;
    }
  }

  .breadcrumb__item {
    a {
      color: #fff;

      &:hover,
      &:focus {
        color: rgba(#fff, .85);
      }
    }
  }
}
