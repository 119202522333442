/*------------------------------
*	 Contact Us
------------------------------*/

.page-contact-us {
  // Sidebar
  .contact-card {
    margin-top: 40px;
    padding: 20px 30px 30px;
    background: url(../gfx/contact-card-bg.jpg) no-repeat center;
    background-size: cover;
    color: #fff;

    @media(min-width: 768px) {
      margin-top: 0;
    }

    @media(min-width: 992px) {
      padding: 35px 40px 40px;
      font-size: 18px;
    }

    h2 {
      margin: 0;
      font-size: 30px;
      color: $acro-orange;
    }

    p {
      margin: 0 0 20px 0;
    }

    a {
      &:hover,
      &:focus {
        color: #fff;
      }
    }

    .label {
      background-color: rgba(255,255,255,0.2);
      color: #fff;
      min-width: 100px;
      display: inline-block;
      vertical-align: middle;
      padding: 4px 8px;
      margin: 0 8px 0 0;
      border-radius: 0;
    }

    hr {
      width: 40px;
      margin: 15px 0 12px;
      text-align: left;
    }

    .btn:hover,
    .btn:focus {
      color: $acro-orange;
      background-color: #fff;
    }
  }
}
