/**
 * Paragraph - Strap CTAs.
 */

// Vertical layout.
.strap-cta--vertical {
  text-align: center;

  .strap-cta__icon-wrapper {
    margin-bottom: 20px;
  }

  // Icon.
  .strap-cta__icon {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;

    .strap-cta__icon-background {
      display: inline-block;
      height: 80px;
      width: 80px;
      border: 2px solid $acro-orange;
      border-radius: 50%;
      text-align: center;
      line-height: 76px;
      font-size: 42px;
    }
  }

  // Title.
  .strap-cta__title {
    margin: 0 0 20px 0;
    padding: 0;
    font: normal 37px/1.2em $font-family-serif;

    @media (max-width: $screen-sm-max) {
      font-size: 18px;
      line-height: 1.2em;
    }
  }

  // Message.
  .strap-cta__message {
    font-size: 18px;
  }
}


// Horizontal layout.
.strap-cta--horizontal {

  // Icon and content wrapper.
  .strap-cta__wrapper {
    display: flex;
    width: 100%;
    align-items: center;

    @media (max-width: $screen-xs-max) {
      display: block;
      text-align: center;
    }

    .strap-cta__icon-wrapper {
      flex: 0 0 120px;

      @media (max-width: $screen-xs-max) {
        display: block;
        margin-bottom: 20px;
      }
    }
  }

  // Content wrapper.
  .strap-cta__content-wrapper {
    display: flex;
    width: 100%;
    align-items: center;

    @media (max-width: $screen-sm-max) {
      display: block;
    }

    .strap-cta__button-wrapper {
      flex: 0 0 315px;
      padding-left: 25px;

      @media (max-width: $screen-sm-max) {
        display: block;
        padding-left: 0;
      }

      // Default button cta paragraph override styles.
      @media (max-width: $screen-sm-max) {
        .paragraphs-item-button-ctas .field-items {
          justify-content: flex-start;
        }
      }
      @media (max-width: $screen-xs-max) {
        .paragraphs-item-button-ctas .field-items {
          justify-content: center;
        }
      }
      @media (min-width: $screen-md) {
        .paragraphs-item-button-ctas .field-items > .field-item {
          display: block;
          width: 100%;
          margin-left: 0;
          margin-right: 0;

          .button-cta-link.btn {
            justify-content: center;
          }
        }
      }
    }
  }

  // Icon.
  .strap-cta__icon {
    margin: 5px;

    img {
      max-width: 90px;

      @media (max-width: $screen-xs-max) {
        max-width: 60px;
      }
    }

    .strap-cta__icon-background {
      display: inline-block;
      height: 80px;
      width: 80px;
      border: 2px solid $acro-orange;
      border-radius: 50%;
      text-align: center;
      line-height: 76px;
      font-size: 42px;

      @media (max-width: $screen-xs-max) {
        height: 60px;
        width: 60px;
        line-height: 56px;
        font-size: 34px;
      }
    }
  }

  // Title.
  .strap-cta__title {
    padding: 4px 0 2px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;

    @media (max-width: $screen-xs-max) {
      margin-bottom: 20px;
    }
  }

  // Message.
  .strap-cta__message {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.1em;

    @media (min-width: $screen-sm) {
      p:last-of-type {
        margin-bottom:0;
      }
    }

    @media (max-width: $screen-sm-max) {
      font-size: 18px;
      line-height: 1.2em;
    }
  }
}


// Dark theme.
.strap-cta--dark {

  // Icon.
  .strap-cta__icon-background {
    color: #fff;
  }

  // Title.
  .strap-cta__title {
    color: $acro-orange;
  }

  // Message.
  .strap-cta__message {
    color: #fff;
  }
}


// Light theme.
.strap-cta--light {

  // Title.
  .strap-cta__title {
    color: $acro-gray-dark;
  }
}
