/**
 * Paragraph - Image Block CTAs.
 */

// General layout.
.image-block-cta-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .image-block-cta-wrapper {
    flex: 0 0 330px;
    width: 100%;
    margin: 15px 15px 25px;

    @media (max-width: 400px) {
      flex: 0 0 100%;
      margin: 15px 0 25px;
    }
  }

  // Note: If auto-hide is enabled then we don't want items to wrap. We then use jQuery to reveal the items we want.
  // jQuery is used instead of css media queries because we need use width of the container, not the screen.
  // See page-paragraphs.js

  &.image-block-cta-group--auto-hide {
    .image-block-cta-wrapper.hidden-block {
      display: none;
      visibility: hidden;
    }
  }

  .image-block-cta {
    display: block;
    width: 100%;
    height: 100%;
    border: 1px solid $gray-lighter;
    border-bottom: 6px solid $gray-lighter;
    background-color: #fff;
    box-shadow: none;
    transition: box-shadow 0.2s ease-in-out, color 0.2s ease-in-out, border 0.2s ease-in-out;

    // Featured image.
    .image-block-cta__featured-image {
      position: relative;
      background-color: $acro-gray-dark;

      img {
        width: 100%;
        opacity: 1;
        filter: grayscale(0);
        transition: opacity 0.2s ease-in-out, filter 0.2s ease-in-out;
      }
    }

    // Flag.
    .image-block-cta__flag {
      position: absolute;
      top: 0;
      left: 15px;
      padding: 8px 11px 7px;
      background: rgba($commerce-blue, 0.9);
      color: #fff;
      font-size: 15px;
      font-weight: 500;
      transition: padding 0.2s ease;
    }

    // Content section.
    .image-block-cta__content {
      width: 100%;
      padding: 15px 25px 20px;

      &.image-block-cta__content--centered {
        text-align: center;
      }
    }

    // Title.
    .image-block-cta__title {
      padding: 0 0 10px;
      font-family: $font-family-serif;
      font-size: 24px;
      line-height: 1.2em;
      color: $acro-gray;
      transition: color 0.2s ease-in-out;

      @media (max-width: 400px) {
        font-size: 18px;
      }
    }

    // Message.
    .image-block-cta__message {
      padding: 5px 0 10px;
      color: $text-color;
      font-size: 14px;

      p:last-of-type {
        margin-bottom: 10px;
      }
    }

    // Action label.
    .image-block-cta__action-label {
      padding: 0;
      font-size: 15px;
      font-weight: 500;

      i {
        padding-left: 2px;
      }
    }

    &:hover,
    &:focus {
      border-bottom: 6px solid $acro-orange;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);

      .image-block-cta__featured-image {
        img {
          opacity: 0.5;
          filter: grayscale(.5);
        }
      }

      .image-block-cta__flag {
        padding-top: 12px;
      }

      .image-block-cta__title {
        color: $acro-gray-dark;
      }
    }
  }
}
