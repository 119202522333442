/**
 * Resources view.
 *
 * Note: Resources page layout in pages/resources.scss.
 * Note: Resources facet block styling in blocks/resources-facets.scss.
 */

// Layout.
.view-resources {
  // Uses flex to change the div order.
  display: flex;
  flex-wrap: wrap;

  // Sorting options.
  .view-filters {
    order: 1;
    flex: 0 0 100%;
  }

  // Displaying indicator.
  .view-header,
  .view-empty {
    order: 2;
    flex: 0 0 100%;
  }

  // Content items.
  .view-content {
    order: 3;
    flex: 0 0 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 649px) {
      justify-content: center;
    }

    @media (min-width: $screen-lg) {
      justify-content: flex-start;
    }

    .views-row {
      flex: 0 0 360px;
      width: 100%;
      margin: 15px 0 25px;

      @media (max-width: 440px) {
        flex: 0 0 100%;
      }

      @media (min-width: 650px) {
        flex: 0 0 47%;
      }

      @media (min-width: $screen-lg) {
        flex: 0 0 30%;
        margin-right: 3%;
      }

      @media (min-width: 1820px) {
        flex: 0 0 22%;
      }

      @media (min-width: 2700px) {
        flex: 0 0 17%;
      }

      @media (min-width: 3680px) {
        flex: 0 0 360px;
        margin-right: 30px;
      }
    }
  }

  // Pager.
  .pager {
    order: 4;
    flex: 0 0 97%;

    @media (max-width: $screen-lg-min) {
      flex: 0 0 100%;
    }

    .pager__item {
      background: none;
      padding: 0;
    }
  }
}

// Filters.
.view-resources {
  .view-filters {
    border-bottom: 1px solid $gray-light;
    padding-bottom: 13px;

    @media (max-width: $screen-xs-max) {
      text-align: center;
    }

    // Search
    form {
      float: right;
      display: inline-block;
      width: 100%;
      max-width: 320px;
      vertical-align: bottom;

      @media (max-width: $screen-xs-max) {
        float: none;
      }
    }

    .views-exposed-form .views-exposed-widget {
      float: none;
      padding: 0;
      text-align: left;
      display: inline-block;
      vertical-align: bottom;

      label {
        padding-bottom: 6px;
        font-family: $font-family-sans-serif;
        font-weight: 600;
        font-size: 15px;
        text-transform: uppercase;
        color: $acro-gray-dark;
      }

      // Search input.
      .form-text {
        height: 40px;
        width: 100%;
        max-width: 220px;
        padding: 0 11px;
        border: 1px solid $gray-light;
        line-height: 40px;
        font-size: 15px;
        color: $acro-gray;
        outline: none;

        @media (max-width: 400px) {
          max-width: 200px;
        }
      }

      // Search button.
      .form-submit {
        margin-left: -5px;
        padding: 11px 15px;
        border-radius: 0;
        font-size: 15px;

        &:hover, &:focus {
          background: $link-hover-color;
        }
      }
    }

    // Keyword result.
    // These elements added via jQuery in page-resources.js.
    .keyword-result {
      display: inline-block;
      float: right;
      width: 100%;
      max-width: calc(100% - 700px);
      vertical-align: bottom;
      padding-right: 25px;
      margin-right: 25px;
      border-right: 1px solid $gray-light;
      text-align: right;

      @media (max-width: 1600px) {
        max-width: calc(100% - 600px);
      }

      // Hide for smaller screen.
      @media (max-width: 1275px) {
        display: none;
      }

      .keyword-result__label {
        padding-bottom: 12px;
        display: block;
        font-family: $font-family-sans-serif;
        font-weight: 600;
        font-size: 15px;
        text-transform: uppercase;
        color: $acro-gray-dark;
      }

      .keyword-result__keyword {
        display: inline-block;
        font: normal 32px/1.2em $font-family-serif;
        color: $acro-orange;

        @media (max-width: 1600px) {
          margin-bottom: 10px;
          font-size: 23px;
        }
      }

      .keyword-result__reset {
        display: inline-block;
        padding-left: 5px;
        font-size: 16px;
        color: $acro-gray-dark;
        cursor: pointer;
        opacity: 1;
        transition: opacity .2s ease;

        &:hover, &:focus {
          opacity: .7;
        }

        i {
          position: relative;
          top: -2px;
        }
      }
    }
  }
}

// Displaying indicator.
.view-resources {
  .view-header,
  .view-empty {
    padding: 10px 0;

    @media (max-width: $screen-sm-max) {
      text-align: center;
    }
  }
}

// Content items.
.resource-item-wrapper {
  display: none; // Revealed with jQuery in page-resources.js.
  height: 100%;
}

.resource-item {
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid $gray-lighter;
  border-bottom: 6px solid $gray-lighter;
  background-color: #fff;
  box-shadow: none;
  transition: box-shadow 0.2s ease-in-out, color 0.2s ease-in-out, border 0.2s ease-in-out;

  // Featured image.
  .resource-item__featured-image {
    position: relative;
    background-color: $acro-gray-dark;

    img {
      width: 100%;
      opacity: 1;
      filter: grayscale(0);
      transition: opacity 0.2s ease-in-out, filter 0.2s ease-in-out;
    }
  }

  // Sticky flag.
  .resource-item__sticky-flag {
    position: absolute;
    top: 0;
    right: 15px;
    padding: 8px 11px 7px;
    background: rgba($acro-orange, 1);
    color: #fff;
    font-size: 15px;
    transition: padding 0.2s ease;
  }

  // Title.
  .resource-item__title {
    padding: 15px 25px 10px;
    font-family: $font-family-serif;
    font-size: 21px;
    line-height: 1.2em;
    color: $acro-gray;
    transition: color 0.2s ease-in-out;

    @media (max-width: 400px) {
      font-size: 18px;
    }
  }

  // Description.
  .resource-item__description {
    padding: 0 25px 12px;
    font-size: 15px;
    line-height: 1.2em;
    color: $text-color;
    transition: color 0.2s ease-in-out;
  }

  // Action label.
  .resource-item__action-label {
    padding: 0 25px 20px;
    font-size: 15px;
    font-weight: 500;

    .resource-item__action-label-icon-before {
      padding-right: 6px;
      margin-right: 2px;
      border-right: 1px solid $gray-light;
      font-size: 17px;
      font-weight: normal;
      color: $acro-gray-dark;
    }

    .resource-item__action-label-icon-after {
      padding-left: 2px;
      font-size: 13px;
      font-weight: normal;
    }
  }

  &:hover,
  &:focus {
    border-bottom: 6px solid $acro-orange;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);

    .resource-item__featured-image {
      img {
        opacity: 0.5;
        filter: grayscale(.5);
      }
    }

    .resource-item__flag,
    .resource-item__sticky-flag {
      padding-top: 12px;
    }

    .resource-item__title {
      color: $acro-gray-dark;
    }
  }
}
