/**
 * Resources facet blocks.
 *
 * Note: Resources page layout in pages/resources.scss.
 * Note: Resources view styling in views/resources-view.scss.
 */

.block-facetapi {
  margin-bottom: 30px;
  display: block !important;

  // Block titles.
  h2 {
    padding-bottom: 8px;
    margin-bottom: 14px;
    border-bottom: 1px solid $gray-light;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 15px;
    text-transform: uppercase;
  }

  // Facet list.
  .item-list ul li {
    margin: 0 0 5px 25px;
    background: none;
    font-weight: 500;
    color: $acro-gray-dark;

    a {
      color: $acro-gray-dark;
      padding: 0 2px;
      font-weight: normal;
      display: block;
      width: 100%;
      outline: none;

      @media (max-width: $screen-sm-max) {
        font-size: 14px;
      }
    }

    a.facetapi-inactive {
      transition: border .2s ease;
      border-bottom: 1px solid transparent;

      &:before {
        @extend %fa-icon;
        @extend .fas;
        content: fa-content($fa-var-check);
        display: inline-block;
        position: absolute;
        top: 2px;
        left: -25px;
        height: 14px;
        width: 14px;
        border: 1px solid $acro-gray-dark;
        border-radius: 2px;
        background: #fff;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #fff;
        box-sizing: content-box;
        transition: border .2s ease, color .2s ease;

        @media (max-width: $screen-sm-max) {
          top: 1px;
        }
      }

      &:hover, &:focus {
        border-bottom: 1px solid $acro-orange;

        &:before {
          border: 1px solid $acro-orange;
          color: $acro-orange;
        }
      }
    }

    a.facetapi-active {
      position: absolute;
      top: 2px;
      left: -25px;
      color: #fff;

      &:before {
        @extend %fa-icon;
        @extend .fas;
        content: fa-content($fa-var-check);
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        height: 14px;
        width: 14px;
        border: 1px solid $acro-orange;
        border-radius: 2px;
        background: $acro-orange;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #fff;
        box-sizing: content-box;
        transition: all .2s ease;
      }

      &:hover, &:focus {
        &:before {
          border: 1px solid $link-hover-color;
          background: $link-hover-color;
          content: fa-content($fa-var-times);
        }
      }
    }
  }

  // Facet result counter.
  .facet-counter {
    display: none;
    visibility: hidden;
  }
}
