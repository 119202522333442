/*------------------------------
*	 Sticky footer.
------------------------------*/
.site-footer-sticky {
  position: fixed; // Backup in case sticky not supported.
  position: sticky;
  bottom: -1px;
  z-index: 1;
  width: 100%;
  background: #eef0f1;
  border-top: 1px solid $gray-lighter;
  transition: all .1s ease-in-out;

  .site-footer-sticky__right {
    text-align: right;
  }

  // Remove sticky for small displays.
  @media (max-width: $screen-sm-max) {
    position: relative;
    bottom: 0;
  }
  @media (max-width: $screen-xs-max) {
    .site-footer-sticky__left,
    .site-footer-sticky__right {
      text-align: center;
    }
  }
}

// Overrides when unstuck (at bottom of page).
.site-footer-sticky--unstuck {
  position: relative;
  bottom: 0;
  padding-bottom: 0;
  background: $acro-gray-dark;
  border-top: 1px solid $acro-gray;

  .site-footer-sticky__left ul.menu li a {
    color: #fff;

    &:hover, &:focus {
      background: #000;
      color: #fff;
    }
  }

  .site-footer-social-media {
    padding-right: 0;

    ul li a {
      color: #fff;

      &:hover, &:focus {
        background-color: #000;
        color: $acro-orange;
      }
    }
  }
}

// Sticky footer left menu
.site-footer-sticky__left {
  ul.menu {
    display: inline-block;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      vertical-align: middle;
      padding: 0;
      margin: 0;
      background: none;

      a {
        position: relative;
        display: inline-block;
        height: 60px;
        padding: 0 15px;
        font-size: 15px;
        font-weight: 500;
        line-height: 60px;
        color: $acro-gray;
        background: transparent;
        transition: background .2s linear, color .2s linear;

        @media (max-width: $screen-xs-max) {
          padding: 0 8px;
        }

        &:before {
          @extend %fa-icon;
          @extend .fas;
          top: 1px;
          margin-right: 8px;
          font-size: 18px;
          color: $acro-orange;
          content: '';
        }

        &:hover, &:focus {
          background: #fff;
          color: $acro-gray-dark;
        }
      }

      // Icons.
      &#li-acronet a:before {
        content: fa-content($fa-var-unlock-alt);
      }
      &#li-blog a:before {
        content: fa-content($fa-var-bookmark);
      }
      &#li-careers a:before {
        content: fa-content($fa-var-plus-circle);
      }
      &#li-visit-acromedia-com a:before,
      &#li-visit-acrocommerce-com a:before {
        content: fa-content($fa-var-external-link);
      }
    }
  }
}

// Social menu.
.site-footer-social-media {
  display: inline-block;
  padding-right: 90px;
  transition: all .1s ease-in-out;
  text-align: center;

  // Remove padding if no chat activated.
  &.site-footer-social-media--no-chat-active {
    padding-right: 0;
  }

  @media (max-width: $screen-sm-max) {
    padding-right: 0;
  }

  ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;

    li {
      float: none;
      display: inline-block;
      margin: 10px 0 0;
      padding: 0;
      background: none;
      vertical-align: middle;

      @media (max-width: $screen-xs-max) {
        margin: 0 0 10px;
      }

      a {
        display: inline-block;
        width: 36px;
        height: 36px;
        background: transparent;
        border-radius: 50%;
        text-align: center;
        color: #949fa5;
        transition: background 0.2s linear, color 0.2s linear;

        i {
          font-size: 21px;
          line-height: 35px;
        }

        &:hover, &:focus {
          background-color: #fff;
          color: $acro-orange;
        }
      }
    }
  }
}

// Careers site has no live chat, so we don't need the initial padding.
.domain--careers-acromedia-com,
.domain--careers-acrocommerce-com {
  .site-footer-social-media {
    padding-right: 0;
  }
}


/*------------------------------
*	 Footer main.
------------------------------*/
.site-footer {
  position: relative;
  clear: both;
  display: block;
  overflow: hidden;
  background: $acro-gray-dark;
  border-top: 1px solid $acro-gray;
}

// Logo.
.site-footer__logo {
  padding: 23px 0;
  border-bottom: 1px solid $acro-gray;

  @media (max-width: $screen-sm-max) {
    text-align: center;
  }
  @media (max-width: $screen-xs-max) {
    border-bottom: none;
  }

  #site-logo {
    max-width: 175px;
  }
}

// Contact & primary menu defaults.
.site-footer__contact,
.site-footer__menu {
  margin: 35px 0;
  font-size: 14px;
  color: #fff;
  letter-spacing: .04em;

  a {
    color: #949fa5;
    letter-spacing: .04em;

    &:hover, &:focus {
      color: $acro-orange;
    }
  }
}

// Contact.
.site-footer__contact {

  p {
    margin-bottom: 22px;
  }

  @media (max-width: $screen-xs-max) {
    margin: 0 0 5px;
    text-align: center;
  }

  .contact-title {
    font-size: 16px;
    font-weight: 600;

    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }

  .contact-location,
  .contact-phone,
  .contact-email {
    display: inline-block;
    margin-bottom: 4px;
    font-weight: 500;

    &:before {
      @extend %fa-icon;
      @extend .fas;
      top: 1px;
      margin-right: 8px;
      font-size: 15px;
      color: $acro-orange;
      content: '';
    }
  }

  // Icons.
  .contact-location:before {
    content: fa-content($fa-var-map-marker-alt);
  }
  .contact-phone:before {
    content: fa-content($fa-var-comment);
  }
  .contact-email:before {
    content: fa-content($fa-var-envelope);
  }
}

// Primary menu.
.site-footer__menu {
  margin: 37px 0 27px;

  // Hide the contact link.
  #li-contact {
    display: none;
    visibility: hidden;
  }

  // General styles.
  ul li {
    position: relative;
    display: block;
    vertical-align: top;
    margin: 0;
    padding: 0;
    background: none;
  }

  // First level.
  ul.menu {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;

    > li {
      flex-basis: 50%;

      @media (min-width: $screen-md) {
        flex-basis: 33%;
      }

      > a {
        display: block;
        padding: 2px 0;
        margin: 0 0 22px;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
      }
    }

    > li.dropdown > a {
      margin-bottom: 7px;
    }
  }

  // Second level.
  ul.menu > li > ul.dropdown-menu {
    display: block;
    margin:  0 0 15px;
    padding: 0;
    list-style: none;

    > li {

      > a {
        display: block;
        padding-bottom: 5px;
      }
    }
  }

  // Third, fourth... level.
  ul.menu > li > ul.dropdown-menu ul.dropdown-menu {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;

    > li {

      > a {
        display: block;
        position: relative;
        padding: 0 0 5px 15px;

        &:before {
          position: absolute;
          top: 0;
          left: 5px;
          content: '•';
        }
      }

      &:last-of-type a {
        margin-bottom: 2px;
      }
    }
  }

  // Active.
  ul li a {
    &.active,
    &.active-trail > a,
    &:hover,
    &:focus {
      color: $acro-orange;
    }
  }
}

// Copyright.
.site-footer__copy {
  padding: 20px 0 60px;
  border-top: 1px solid $acro-gray;
  font-size: 13px;
  color: #949fa5;
  letter-spacing: .01em;

  @media (max-width: $screen-sm-max) {
    text-align: center;
  }

  a {
    color: #fff;
    font-weight: 500;

    &:hover, &:focus {
      color: $acro-orange;
    }
  }

  span {
    display: inline-block;
  }

  .copy-splitter {
    padding: 0 8px;
  }
}
