/**
 * Case Studies.
 *
 * NOTE: This is for the case study content type.
 * All new case studies are built with the Page - Components content type.
 */

/**
 * Case Study - Details Page
 */

#case-study-header-wrapper {
  padding-bottom: 45px;
  position: relative;
  text-align: center;
  background: url('../gfx/case-study-bar.jpg') center bottom no-repeat;
}
#case-study-header-cont {
  position: relative;
  margin: 0 auto;
  padding: 0;
  background-color: $acro-gray-dark;
  text-align: center;
}

#case-study-header-content {
  margin-top: 40px;
}

  #case-study-header-graphic {
    margin: 0 auto;
    position: relative;
    max-width: 1600px;
    min-height: 670px;
    background-position: center top;
    background-size: cover !important;
  }

h1#case-study-header-tagline {
  margin: 0 0 21px 0;
  padding: 0;
  font: normal 48px/50px $font-family-serif;
  color: $acro-gray-dark;
}
p#case-study-header-lead-in {
  margin: 0;
  padding: 0;
  font: 300 28px/40px $font-family-sans-serif;
}

// intro section (client logo & case study features)
.case-study-header-features {
  margin-bottom: 75px;
  padding: 40px 0;
  border-bottom: 1px solid $gray-light;
  ul {
    padding: 0;
    margin: 0 auto;
    display: table;
    list-style: none;
    li {
      padding-left: 75px;
      padding-right: 30px;
      position: relative;
      height: 64px;
      min-width: 220px;
      font: normal 20px/28px $font-family-sans-serif;
      vertical-align: middle;
      display: table-cell;
      background: none;

      i {
        position: absolute;
        top: 50%;
        margin-top: -32px;
        left: 0;
        width: 64px;
        height: 64px;
        display: inline-block;
        background-repeat: none;
      }

      &.cs-feature-responsive i { background: url('../gfx/cs-icon-responsive.svg'); }
      &.cs-feature-drupal-integration i { background: url('../gfx/cs-icon-monitor.svg'); }
      &.cs-feature-ecommerce i { background: url('../gfx/cs-icon-cart.svg'); }
      &.cs-feature-custom-drupal-module i { background: url('../gfx/cs-icon-drupal.svg'); }
      &.cs-feature-multi-language i { background: url('../gfx/cs-icon-speech.svg'); }
      &.cs-feature-ongoing-support i { background: url('../gfx/cs-icon-tools.svg'); }
    }
  }
}

// pane section styling
#case-study-body-content {
  .case-study-layout {
    margin-bottom: 40px;

    p:last-child { margin-bottom: 0; }

    // standard layout
    &.layout-standard {
      h2 {
        color: $acro-gray-dark;
      }
      p {
        font-size: 18px;
        line-height: 34px;
      }

      ul li {
        font-size: 18px;
        background: url(../gfx/bullet_content.png) 0 11px no-repeat;
      }

      ol li {
        font-size: 18px
      }
    }


    &.layout-testimonial {
      .cs-test-wrap {
        background-color: $acro-gray-dark;
        display: table;
        min-height: 400px;

        .cs-test-img {
          width: 725px;
          display: table-cell;
          background-size: cover;
        }

        .cs-test-content {
          display: table-cell;
          text-align: center;
          vertical-align: middle;
          blockquote {
            padding: 53px 60px;
            background: none;
            margin: 0;
            color: #fff;
            font: italic 32px/40px $font-family-serif;
            border: none;
            .cs-author {
              margin-top: 21px;
              color: $acro-orange;
              font: normal 17px/23px $font-family-serif;
              display: inline-block;
            }
          }
        }

      }
    }
  }
}

// Url.
.case-study-site-url {
  width: 100%;
  padding: 0 0 70px;

  a {
    display: block;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 15px 20px;
    border: 1px solid $gray-light;
    background-color: #fafafa;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: $acro-gray;
    text-transform: uppercase;
    transition: color 0.15s linear, background 0.15s linear;

    i {
      position: relative;
      top: 2px;
      padding-right: 5px;
      transition: color 0.15s linear, background 0.15s linear;
    }

    &:hover, &:focus {
      color: $acro-orange;
      background: #fff;

      i {
        color: $acro-gray;
      }
    }
  }
}

// stats section
.case-study-stats {
  background-color: $acro-gray-dark;
  padding: 42px 50px;
  width: 100%;
  min-height: 280px;
  color: #fff;
  display: block;

  .pane-title {
    font-size: 30px;
    margin-bottom: 42px;
    color: $acro-orange;
  }

  ul {
    padding: 0;
    margin: 0 auto;
    display: table;
    list-style: none;
    max-width: 90%;

    li {
      padding-left: 75px;
      padding-right: 42px;
      position: relative;
      height: 64px;
      max-width: 280px;
      font: normal 20px/28px $font-family-sans-serif;
      vertical-align: middle;
      display: table-cell;
      background: none;

      strong {
        font-weight: normal;
        color: $acro-orange;
        }

      i {
        position: absolute;
        top: 50%;
        margin-top: -32px;
        left: 0;
        width: 64px;
        height: 64px;
        display: inline-block;
        background-repeat: none;
        &.cs-ico-bar { background: url('../gfx/cs-icon-bar.svg'); }
        &.cs-ico-pie { background: url('../gfx/cs-icon-pie.svg'); }
        &.cs-ico-badge { background: url('../gfx/cs-icon-badge.svg'); }
      }
    }
  }
}

.footer-contact-form {
  background-color: #F0F2F2;
  padding: 40px;
  margin-bottom: 60px;
}

/*------------------------------
*	 RESPONSIVENESS
------------------------------*/

/* 1200 and below */
@media (max-width: $screen-md-max) {
  #case-study-header-graphic {
    min-height: 475px;
  }

  #case-study-body-content .case-study-layout.layout-testimonial .cs-test-wrap .cs-test-img {
  	width: 600px;
  }

}

/* 991px and below */
@media (max-width: $screen-sm-max) {
  /* -- case study -- */
  h1#case-study-header-tagline {
    font-size: 32px;
    line-height: 36px;
  }

  p#case-study-header-lead-in {
    margin: 0;
    padding: 0;
    font-size: 17px;
    line-height: 24px;
  }

  .cs-client-logo {
    margin-bottom: 30px;
    text-align: center;
  }

  #case-study-body-content .case-study-layout.layout-testimonial .cs-test-wrap .cs-test-img {
  	width: 375px;
  }
}

/* 767px and below -- Down To Phone */
@media (max-width: $screen-xs-max) {
  /* -- work / case study listing -- */
  #case-study-header-graphic { min-height: 275px; }

  .cs-client-logo { margin: 0; padding: 0 35px; }

  .case-study-header-features ul { display: none; }

  #case-study-body-content .case-study-layout.layout-testimonial .cs-test-wrap {
	  display: block;
	  .cs-test-img,
	  .cs-test-content {
	  	display: inline-block;
  		width: 100%;
		}
		.cs-test-img { height: 215px; }
		.cs-test-content blockquote {
			padding: 33px 30px;
			font-size: 26px;
			line-height: 34px;
		}
  }

  .case-study-stats {
  	padding: 30px;

  	.pane-title { margin-bottom: 20px; }

	  ul li {
	  	padding: 0;
	  	padding-top: 75px;
	  	text-align: center;
	    font-size: 17px;
	    line-height: 22px;
	    min-width: 100px;
	    vertical-align: top;
	    i {
		    top: 0;
		    margin: 0 0 0 -32px;
		    left: 50%;
		    width: 64px;
		    height: 64px;
		    display: inline-block;
		    }
	  }
  }
}
