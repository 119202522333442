/**
 * Paragraph - Case Studies.
 *
 * Note: This stylesheet is for the case study "Paragraph display" view mode
 * which is used by the case studies paragraph. All other case study styling
 * is in other stylesheets.
 */

// General layout.
.case-study-paragraph-group {

  // With auto-hide disabled.
  .field-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // Max width because we only want max 4 items to a row.
    max-width: 1630px;
    margin: 0 auto;

    > .field-item {
      display: block;
      flex: 0 0 100%;
      width: 100%;
      margin: 15px 0 15px;

      @media (min-width: 400px) {
        flex: 0 0 330px;
        margin: 15px;
      }
      @media (min-width: 1630px) {
        flex: 0 0 364px;
      }
    }
  }

  // With auto-hide enabled.
  &.case-study-paragraph-group--auto-hide .field-items {
    > .field-item {
      display: none;
      visibility: hidden;

      // As screen size allows, we start adding items to display.
      @media (min-width: 0) {
        &:nth-of-type(1) {
          display: block;
          visibility: visible;
        }
      }
      @media (min-width: 800px) {
        &:nth-of-type(2) {
          display: block;
          visibility: visible;
        }
      }
      @media (min-width: 1200px) {
        &:nth-of-type(3) {
          display: block;
          visibility: visible;
        }
      }
      @media (min-width: 1630px) {
        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(6),
        &:nth-of-type(7),
        &:nth-of-type(8) {
          display: block;
          visibility: visible;
        }
      }
    }
  }

  // Top section.
  .case-study-paragraph__top {
    position: relative;
  }

  .case-study-paragraph__top__content {
    position: absolute;
    z-index: 1;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    padding: 15px 25px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  .case-study-paragraph {
    display: block;
    width: 100%;
    height: 100%;
    border: 1px solid $gray-lighter;
    border-bottom: 6px solid $gray-lighter;
    background-color: #fff;
    box-shadow: none;
    text-align: center;
    transition: box-shadow 0.2s ease-in-out, border 0.2s ease-in-out;


    // Featured image.
    .case-study-paragraph__image {
      background-color: $acro-gray-dark;

      img {
        opacity: 1;
        filter: grayscale(0);
        transition: opacity 0.2s ease-in-out, filter 0.2s ease-in-out;
      }
    }

    // Title.
    .case-study-paragraph__title {
      padding: 0 0 10px;
      font-family: $font-family-serif;
      font-size: 24px;
      line-height: 1.2em;
      color: #fff;

      @media (max-width: 400px) {
        font-size: 18px;
      }
    }

    // Text.
    .case-study-paragraph__text {
      padding: 0 0 10px;
      color: #fff;
    }

    // Read more.
    .case-study-paragraph__view-more {
      font-size: 15px;
      font-weight: 500;
      color: $acro-orange;

      i {
        padding-left: 2px;
      }
    }

    // Logo.
    .case-study-paragraph__logo {
      padding: 10px;

      img {
        max-height: 100px;
      }
    }

    &:hover,
    &:focus {
      border-bottom: 6px solid $acro-orange;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);

      .case-study-paragraph__image {
        img {
          opacity: 0.2;
          filter: grayscale(.75);
        }
      }

      .case-study-paragraph__top__content {
        opacity: 1;
      }
    }
  }
}
