/**
 * HubSpot blog feed.
 */

.view-hubspot-blog-feed .view-content {
  @media (min-width: $screen-md) {
    display: flex;
  }
}

.view-hubspot-blog-feed .views-row {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  margin: 5px 0 20px;

  @media (max-width: $screen-sm-max) {
    padding-left: 0;
    padding-right: 0;
  }
}

.hubspot-blog-feed-item {
  display: table;
  height: 100%;
  min-height: 145px;
  width: 100%;
  background-color: #fff;
  border: 1px solid $gray-lighter;
  border-bottom: 6px solid $gray-lighter;

  .hubspot-blog-feed-item__icon {
    display: table-cell;
    width: 135px;
    padding: 20px 15px;
    background-color: $acro-gray-dark;
    text-align: center;
    vertical-align: top;

    @media (max-width: $screen-md-max) {
      width: 100px;
    }
    @media (max-width: $screen-sm-max) {
      width: 135px;
    }
    @media (max-width: 420px) {
      width: 100px;
    }
    @media (max-width: 380px) {
      width: 60px;
      padding: 20px 5px;
    }
  }

  .hubspot-blog-feed-item__content {
    display: table-cell;
    width: calc(100% - 160px);
    padding: 25px 10px 25px 20px;
    vertical-align: top;

    @media (max-width: $screen-md-max) {
      width: calc(100% - 105px);
    }
    @media (max-width: $screen-sm-max) {
      width: calc(100% - 160px);
    }
    @media (max-width: 420px) {
      width: calc(100% - 105px);
    }
    @media (max-width: 380px) {
      width: calc(100% - 65px);
    }

    h3 {
      font-size: 19px;
      color: $acro-gray;
    }
  }

  .hubspot-blog-feed-item__more {
    font-weight: bold;
  }
}
