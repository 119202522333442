/**
 * Drupal webforms.
 */

.node-webform {
  max-width: 800px;
  margin: 0 auto;
  pointer-events: all;

  // Messages.
  .messages {
    padding: 10px;
    font-size: 12px;
    background-image: none;

    li {
      background: url(../gfx/bullet_sml_grey.png) 0 5px no-repeat;
      line-height: 14px;

      label {
        font-weight: normal;
      }
    }
  }

  // Errors.
  // Hide default error messages in favor of inline errors.
  .messages.error {
    color: $brand-danger;
  }

  // Text links.
  a {
    color: $commerce-blue;

    &:hover,
    &:focus {
      color: $commerce-blue-dark;
    }
  }

  // Labels.
  label {
    width: 100%;
    max-width: 100%;
    margin-bottom: 6px;
    font-weight: bold;
    line-height: 1.2em;
  }

  // Required marker.
  .form-required {
    color: $brand-danger;
  }

  // Legend.
  legend {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 6px;
    font-size: 13px;
    line-height: 1.2em;
  }

  // Descriptions.
  .description {
    margin-top: 8px;
    line-height: 1.2em;
  }

  // Layout.
  .webform-component {
    width: 100%;
    margin: 0 0 20px;
    padding: 0 5px;

    @media screen and (min-width: $screen-sm) {
      width: 50%;
      float: left;
    }
  }

  .webform-component-textarea {
    width: 100%;

    @media screen and (min-width: $screen-sm) {
      width: 100%;
    }
  }

  .webform-component-file {
    width: 100%;
    padding: 10px;

    @media screen and (min-width: $screen-sm) {
      width: calc(50% - 10px);
      margin: 0 5px 20px;
    }
  }

  // Input elements - Default.
  input.form-text,
  input.form-file,
  select.form-select,
  textarea.form-textarea {
    width: 100% !important;
    max-width: 100% !important;
    min-height: 40px;
    padding: 0 10px;
    line-height: 38px;
    background: #fff;
    border: 1px solid $gray-light;
    text-decoration: none;
    font-weight: 300;
    color: $acro-gray;
    box-sizing: border-box;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #bbb;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      opacity: 1;
      color: #bbb;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: #bbb;
    }
    &:-moz-placeholder { /* Firefox 18- */
      opacity: 1;
      color: #bbb;
    }

    &.error {
      border: 1px solid $brand-danger;
    }

    &:focus {
      border-color: $commerce-blue;
      outline: 0;
      box-shadow: 0 0 6px rgba($commerce-blue, 0.2);
    }
  }

  // Input element - Radio/Checkbox.
  input[type='radio'] + label,
  input[type='checkbox'] + label {
    padding-left: 6px;
    font-weight: normal;
  }

  // Input element - Text area.
  textarea.form-textarea {
    min-height: 100px;
    padding: 10px;
    line-height: 1.2em !important;
  }

  // Input element - Select.
  select.form-select {
    padding-left: 5px;
    padding-right: 0;

    // Firefox only.
    @-moz-document url-prefix() {
      padding-top: 7px;
      padding-bottom: 8px;
    }
  }

  // Input element - Files.
  .webform-component-file {
    border: 1px solid $gray-light;

    .file {
      margin-right: 5px;
    }

    // Upload/remove button.
    input[type='submit'] {
      padding: 9px 18px;
    }
  }

  input.form-file {
    min-height: 30px;
    padding: 5px;
    line-height: 1em;
  }

  // Button.
  input[type='submit'] {
    display: inline-block;
    padding: 10px 18px;
    margin-top: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    line-height: 18px;
    background: $acro-gray-dark;
    color: #fff;
    border: 0;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all 0.15s linear;

    &:focus,
    &:hover {
      background-color: #000;
      outline: none;
    }
  }

  // Button - Submit.
  input.webform-submit {
    padding: 10px 20px;
    font-size: 18px;
    line-height: 20px;
    background: $commerce-blue;

    &:focus,
    &:hover {
      background-color: $commerce-blue-dark;
    }
  }

  // Fieldsets.
  fieldset {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 20px;
    border: 1px solid $gray-light;

    .fieldset-description {
      margin: 10px 5px 20px;
    }

    .fieldset-legend {
      display: block;
      padding: 10px;
      border: 1px solid $acro-gray-dark;
      background-color: $acro-gray-dark;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }

    // Make file field within a fieldset full width.
    .webform-component.webform-component-file {
      width: calc(100% - 10px);
    }

    // Make markup field within a fieldset full width.
    .webform-component.webform-component-markup {
      width: 100%;

      h1, h2, h3, h4, h5, h6 {
        margin-bottom: 0;
      }
    }
  }

  // Honeypot. Make sure it's hidden.
  .url-textfield {
    display: none !important;
  }
}
